import { setModal } from '@commons/common-actions'
import { ISection } from '@features/sections/sections-interface'
import React from 'react'
import { useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import { ItemTypes } from './itemTypes'

interface IMyProps {
  section: ISection
  blockType: 'primary' | 'secondary' | 'special'
}

const BlockDrop: React.FC<IMyProps> = ({ section, blockType }) => {
  let acceptedType = ''
  let inactiveText = ''
  let activeText = ''
  let styleClass = ''
  switch (blockType) {
    case 'primary':
      acceptedType = ItemTypes.BLOCK
      inactiveText = 'Arrastra aquí un nuevo bloque'
      activeText = 'Soltar el bloque aquí'
      styleClass = 'section-item section-item__new'
      break
    case 'secondary':
      acceptedType = ItemTypes.SECUNDARY_BLOCK
      inactiveText = 'Arrastra aquí un nuevo bloque'
      activeText = 'Soltar el bloque aquí'
      styleClass = 'section-item section-item__new'
      break
    case 'special':
      acceptedType = ItemTypes.SPECIAL_BLOCK
      inactiveText = 'Arrastra aquí un carrusel o alerta'
      activeText = 'Soltar el carrusel o alerta aquí'
      styleClass = 'special-item-new'
      break
    default:
      acceptedType = ''
      inactiveText = ''
      activeText = ''
      styleClass = ''
  }

  const dispatch = useDispatch()
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: acceptedType,
      drop: () => {
        dispatch(setModal({ status: true }))
        return section
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [section]
  )

  const isActive = canDrop && isOver
  let backgroundColor = '#f0f0f0'
  let borderColor = '#c4c4c4'
  let textColor = '#c4c4c4'
  if (isActive) {
    backgroundColor = '#ffffff'
    borderColor = '#00379b'
    textColor = '#00379b'
  } else if (canDrop) {
    backgroundColor = '#f0f0f0'
  }

  return (
    <div
      ref={drop}
      role="presentation"
      className={styleClass}
      style={{
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        color: textColor
      }}
    >
      {isActive ? activeText : inactiveText}
    </div>
  )
}

export default BlockDrop
