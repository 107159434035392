import React, { useState } from 'react'
import { Markup } from 'interweave'
import { RiPencilLine } from 'react-icons/ri'
import { FiMinus } from 'react-icons/fi'
import './alertSection.scss'
import AlertItem from './alertItem'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { ISection } from '@features/sections/sections-interface'
import { SectionsActions } from '@features/sections'
import CommonModal from '@commons/commonModal/commonModal'

interface IMyProps {
  section: any
  isTemplate?: boolean
  setDataModal?: (value: any) => void
  setOpenAlertModal?: (value: boolean) => void
}

const AlertSection: React.FC<IMyProps> = (props) => {
  const { section, isTemplate, setDataModal, setOpenAlertModal } = props
  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const { modalOpen } = useSelector((state: IInitialState) => state.settings)

  const [openModal, setOpenModal] = useState(false)

  const dispatch = useDispatch()

  const options = {
    title: 'eliminar',
    subtitle: `la alerta?`,
    motifText: ''
  }

  const handleConfirmDeleteAlert = () => {
    if (homeEdit) {
      const index = homeEdit.config.sections.findIndex(
        (sectionHome: ISection) => sectionHome.id === section.id
      )
      if (index !== undefined) {
        homeEdit.config.sections.splice(index, 1)
      }
      dispatch(SectionsActions.setHomeEdit(homeEdit))
    }
    setOpenModal(false)
  }

  // useEffect(() => {
  //   if (modalOpen.status) {
  //     openOnLoad()
  //     dispatch(setModal({ status: false }))
  //   }
  // }, [])

  // const openOnLoad = () => {
  //   setDataModal &&
  //     setDataModal({
  //       section
  //     })
  //   setOpenAlertModal && setOpenAlertModal(true)
  // }

  return (
    <div className="alert-section">
      {section.title && section.title.length > 0 && (
        <div className="title">
          <Markup content={section.title} />
        </div>
      )}
      {section.blocks.map((block: any) => (
        <AlertItem block={block} />
      ))}
      {isTemplate && (
        <>
          <span
            className="edit-alert"
            onClick={() => {
              setDataModal &&
                setDataModal({
                  section
                })
              setOpenAlertModal && setOpenAlertModal(true)
            }}
          >
            <RiPencilLine className="icon-edit" />
          </span>
          <span className="delete-alert" onClick={() => setOpenModal(true)}>
            <FiMinus className="icon-delete" />
          </span>
          {openModal && (
            <CommonModal
              setOpenModal={setOpenModal}
              handleConfirmModal={handleConfirmDeleteAlert}
              options={options}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AlertSection
