import { IAction } from '@commons/common-interfaces'
import { LIST_MALLS, LIST_NEW_MALLS, SET_MALL_BLOQS } from './home-actions'
import { IGeneralData } from './home-interfaces'

const initialState: IGeneralData = {
  malls: [
    {
      mallId: 0,
      code: '',
      name: '',
      logo: '',
      image: '',
      direction: '',
      active: false,
      maps: {
        mainStores: [],
        type: {
          store: {
            venue: 0,
            floors: []
          },
          parking: {
            venue: 0
          }
        }
      },
      coordinates: {
        latitude: '',
        longitude: ''
      },
      createdAt: '',
      lastUpdated: ''
    }
  ],
  newMalls: [],
  bloqs: {
    primary: [],
    secondary: [],
    carousel: [],
    alert: []
  }
}

export const HomeReducer = (
  state: IGeneralData,
  action: IAction
): IGeneralData => {
  state = state ? state : initialState

  switch (action.type) {
    case LIST_MALLS:
      return {
        ...state,
        malls: action.payload
      }
    case SET_MALL_BLOQS:
      return {
        ...state,
        bloqs: action.payload
      }
    case LIST_NEW_MALLS:
      return {
        ...state,
        newMalls: action.payload
      }
    default:
      return state
  }
}
