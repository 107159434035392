export function delTokenAccess() {
  localStorage.removeItem('backoffice_user_access')
  localStorage.removeItem('backoffice_user_refresh')
  return ['ready']
}

export function setTokenAccess(tok, refTok) {
  localStorage.setItem('backoffice_user_access', JSON.stringify(tok))
  localStorage.setItem('backoffice_user_refresh', JSON.stringify(refTok))
  return ['ready']
}
