const fonts = [
  {
    title: 'tiendas',
    icon: 'iconPlus-personal_shopper',
    plus: true
  },
  {
    title: 'restaurantes',
    icon: 'iconPlus-restaurantes',
    plus: true
  },
  {
    title: 'opinion',
    icon: 'iconPlus-opinion',
    plus: true
  },
  {
    title: 'iconPlus-servicios',
    icon: 'iconPlus-servicios',
    plus: true
  },
  {
    title: 'iconPlus-servicios-1',
    icon: 'iconPlus-servicios-1',
    plus: true
  },
  {
    title: 'arauco_foodie',
    icon: 'iconPlus-arauco-foodie',
    plus: true
  },
  {
    title: 'arauco_tag',
    icon: 'iconPlus-arauco-tag',
    plus: true
  },
  {
    title: 'info',
    icon: 'iconPlus-servicio_al_cliente-1',
    plus: true
  },
  {
    title: 'user',
    icon: 'iconPlus-user-01',
    plus: true
  },
  {
    title: 'bancos',
    icon: 'iconPlus-bancos',
    plus: true
  },
  {
    title: 'bazar_gourmet',
    icon: 'iconPlus-bazar_gourmet',
    plus: true
  },
  {
    title: 'souvenirs',
    icon: 'iconPlus-souvenirs',
    plus: true
  },
  {
    title: 'Tickets',
    icon: 'iconPlus-ph_ticket-light',
    plus: true
  },
  {
    title: 'Cajeros',
    icon: 'iconPlus-cajeros',
    plus: true
  },
  {
    title: 'Warning',
    icon: 'iconPlus-warning',
    plus: true
  },
  {
    title: 'tiendas_especializadas',
    icon: 'iconPlus-tiendas_especializadas',
    plus: true
  },
  {
    title: 'pago-online',
    icon: 'iconPlus-pago-online',
    plus: true
  },
  {
    title: 'automotriz',
    icon: 'iconPlus-automotriz',
    plus: true
  },
  {
    title: 'internet_television',
    icon: 'iconPlus-telefonia_internet_television',
    plus: true
  },
  {
    title: 'turismo',
    icon: 'iconPlus-turismo',
    plus: true
  },
  {
    title: 'lockers',
    icon: 'iconPlus-lockers',
    plus: true
  },
  {
    title: 'electrolineras',
    icon: 'iconPlus-electrolineras',
    plus: true
  },
  {
    title: 'cargadores_de_celular',
    icon: 'iconPlus-cargadores_de_celular',
    plus: true
  },
  {
    title: 'arauco-tag',
    icon: 'iconPlus-arauco-tag',
    plus: true
  },
  {
    title: 'ubicación-menu',
    icon: 'iconPlus-ubicacion-menu',
    plus: true
  },
  {
    title: 'baño_familiar',
    icon: 'iconPlus-baño_familiar',
    plus: true
  },
  {
    title: 'baño_de_hombres',
    icon: 'iconPlus-baño_de_hombres',
    plus: true
  },
  {
    title: 'Fotografia',
    icon: 'iconPlus-fotografia',
    plus: true
  },
  {
    title: 'baño_de_mujeres',
    icon: 'iconPlus-baño_de_mujeres',
    plus: true
  },
  {
    title: 'QR',
    icon: 'iconPlus-qr',
    plus: true
  },
  {
    title: 'accesorios',
    icon: 'iconPlus-accesorios_y_bijouterie',
    plus: true
  },
  {
    title: 'patente',
    icon: 'iconPlus-patente-fyc',
    plus: true
  },
  {
    title: 'salud',
    icon: 'iconPlus-salud',
    plus: true
  },
  {
    title: 'recomendador salida',
    icon: 'iconPlus-recomendador-salida',
    plus: true
  },
  {
    title: 'Taxis_privados',
    icon: 'icon-Taxis_privados',
    plus: false
  },
  {
    title: 'Silla_de_ruedas',
    icon: 'icon-Silla_de_ruedas',
    plus: false
  },
  {
    title: 'Servicio_al_cliente',
    icon: 'icon-Servicio_al_cliente',
    plus: false
  },
  {
    title: 'Salidas',
    icon: 'icon-Salidas',
    plus: false
  }
]

export default fonts
