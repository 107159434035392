import React from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import CarouselSection from './carouselSection'

interface IMyProps {
  section: any
  setDataModal?: (value: any) => void
  setOpenCarouselModal?: (value: boolean) => void
}

const CarouselTemplate: React.FC<IMyProps> = (props) => {
  const { section, setDataModal, setOpenCarouselModal } = props

  return (
    <>
      <MDBRow>
        <MDBCol size="2" />
        <MDBCol size="10">
          <div className={`h-100 templateHome_background`}>
            <div className="home-background home-background__2 home-background__carousel">
              <CarouselSection
                setDataModal={setDataModal}
                setOpenCarouselModal={setOpenCarouselModal}
                section={section}
                isTemplate={true}
              />
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default CarouselTemplate
