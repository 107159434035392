import React, { useState } from 'react'
import { Markup } from 'interweave'
import { MDBRow, MDBCol, MDBCollapse, MDBBtn } from 'mdbreact'
import { MdExpandMore, MdExpandLess } from 'react-icons/md'

import './sectionItemCollapse.scss'
import SectionItem from './sectionItem'

interface IMyProps {
  mallSelected: number
  itemData: any
  backgroundWhite: boolean
}

const SectionItemCollapse: React.FC<IMyProps> = (props) => {
  const { itemData, backgroundWhite, mallSelected } = props
  const [expandedId, setExpandedId] = useState(
    itemData.expanded ? 'basicCollapse' : ''
  )

  const textColor = `title-item__${itemData.style}`
  const backgroundIconColor = `item-icon__${itemData.style}`
  const color = String(itemData.icon).includes('Plus')

  const renderCardContent = (
    <div className="container-section">
      <MDBRow>
        <MDBCol className="grid-item-text" size="9">
          <div className={`title-item ${textColor}`}>
            <Markup content={itemData.title} />
          </div>
          <div className="description">
            <Markup content={itemData.description} />
          </div>
        </MDBCol>
        <MDBCol className="grid-item-icon" size="3">
          <div className={`item-icon ${backgroundIconColor}`}>
            <div
              className={`${color ? 'demoIconPlus' : 'demoIcon'} ${
                backgroundWhite ? 'demoIcon__black' : ''
              }`}
            >
              <i className={itemData.icon} />
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  )

  return (
    <div className="container-collapse">
      <div
        className={`section-item ${
          backgroundWhite ? 'section-item__grey' : ''
        } section-item-collapse`}
      >
        {renderCardContent}
        <MDBCollapse id="basicCollapse" isOpen={expandedId}>
          {itemData.subBlocks.map((block: any) => (
            <SectionItem
              key={`sectionItem${block.id}`}
              itemData={block}
              backgroundWhite={false}
              style={itemData.style}
              mallSelected={mallSelected}
            />
          ))}
        </MDBCollapse>
      </div>
      <div className={`card-action card-action__${itemData.style}`}>
        <MDBBtn
          className={`action-btn action-btn__${itemData.style}`}
          aria-expanded={expandedId === 'basicCollapse'}
          aria-label="show more"
          onClick={() =>
            setExpandedId(expandedId === 'basicCollapse' ? '' : 'basicCollapse')
          }
        >
          {expandedId === 'basicCollapse' ? <MdExpandLess /> : <MdExpandMore />}
        </MDBBtn>
      </div>
    </div>
  )
}

export default SectionItemCollapse
