import React from 'react'
import Logo from '@assets/images/arauco-plus.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setAlertModal } from '@commons/common-actions'
import { IInitialState } from '@store/index'
import { Markup } from 'interweave'

const AlertModal: React.FC = () => {
  const { settings } = useSelector((state: IInitialState) => state)

  const dispatch = useDispatch()
  const handleConfirmModal = () => {
    dispatch(setAlertModal({ status: false, data: { msg: '', title: '' } }))
  }
  return (
    <div className="modal_box_common modal_alert_index">
      <div
        className="modal-dialog modal-dialog-centered modal_alert_index"
        role="document"
      >
        <div className="modal-content modal_content modal_alert_index">
          <div className="modal-header m-auto modal_alert_index">
            <img src={Logo} alt="logo" />
          </div>
          <div className="modal-body modal_body text-center modal_alert_index">
            <h4>{settings.alert?.data.title}</h4>
            <Markup content={settings.alert?.data.msg} />
          </div>
          <div className="modal-footer modal_alert_index">
            <div className="button-container modal_alert_index">
              <button
                type="button"
                className="modal_confirmButton modal_alert_index"
                onClick={() => handleConfirmModal()}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertModal
