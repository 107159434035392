import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MDBCol, MDBRow } from 'mdbreact'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { IInitialState } from '@store/index'

import Logo from '@assets/images/arauco-plus.svg'

import { MallThunks } from '@features/mall'
import { toggleActivateMall } from '@features/mall/mall-thunks'

import Layout from '@commons/layout/layout'
import InputSwitch from '@commons/inputSwitch/inputSwitch'
import Loading from '@commons/loading/Loading'
import { Menu } from '@commons/menu'
import { setAlertModal } from '@commons/common-actions'
import CommonModal from '@commons/commonModal/commonModal'
import AlertModal from '@commons/alertModal/alertModal'
import NotificationsContent from './elements/notification-content'
import MallActive from '@commons/mallActive/mallActive'
import NotificationsList from './elements/notification-list'

const NotificationsPage: React.FC = ({ match }: any) => {
  const dispatch = useDispatch()
  const { mall, settings } = useSelector((state: IInitialState) => state)
  const [toggle, setToggle] = useState(false)
  const [fetchingPage, setFetchingPage] = useState(false)
  const [newNotification, setNewNotification] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const { params } = match

  const fetchGetMall = useCallback(async () => {
    setFetchingPage(true)
    await dispatch(MallThunks.getMall(match.params.id, setFetchingPage))

    setFetchingPage(false)
  }, [dispatch])

  useEffect(() => {
    fetchGetMall()
  }, [fetchGetMall])

  useEffect(() => {
    setToggle(mall.info.active)
  }, [mall.info.active])

  const handleActivateMall = () => {
    const isTrue = settings.options.status.find(
      (item) => item.finished === false
    )
    if (isTrue) {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: '',
            title:
              'No puedes activar el Mall porque no está completa toda la información '
          }
        })
      )
    } else {
      setConfirmModal(true)
    }
  }

  const handleConfirmModal = () => {
    if (!toggle) {
      const data = {
        mallId: mall.info.mallId,
        isActive: true
      }
      dispatch(toggleActivateMall(data))
    } else {
      const data = {
        mallId: mall.info.mallId,
        isActive: false
      }
      dispatch(toggleActivateMall(data))
    }
    setToggle((toggle) => !toggle)
    setConfirmModal(false)
  }

  const optionsModal = {
    title: toggle ? 'inactivar' : 'activar',
    subtitle: toggle ? ' este Mall' : ' este Mall',
    motifText: ''
  }

  return (
    <Layout>
      <div>
        {fetchingPage ? (
          <Loading />
        ) : (
          <DndProvider backend={HTML5Backend}>
            <MDBRow className="mt-5 mx-0">
              <MDBCol size="3" className="mx-0 px-0">
                <div className="container-logo">
                  <img src={Logo} className="mall-logo" alt="logo" />
                </div>
                <Menu />
              </MDBCol>
              <MDBCol size="9">
                <MallActive id={params.id} />
                {newNotification ? (
                  <div className="newMall_background p-5 minSizeNotification">
                    <NotificationsContent match={match} />
                  </div>
                ) : (
                  <NotificationsList
                    setNewNotification={setNewNotification}
                    match={match}
                  />
                )}
              </MDBCol>
            </MDBRow>
          </DndProvider>
        )}
      </div>
      {confirmModal && (
        <CommonModal
          setOpenModal={setConfirmModal}
          handleConfirmModal={handleConfirmModal}
          options={optionsModal}
        />
      )}
      {settings.alert?.status && <AlertModal />}
    </Layout>
  )
}

export default NotificationsPage
