import React from 'react';
import './Loading.scss'
import { ReactComponent as IsotipoAP } from '@assets/images/iso-arauco-plus.svg';
import LogoAP from '@assets/images/arauco-plus.svg';


interface IMyProps {

}

const Loading: React.FC<IMyProps> = (props) => {

  const fetchingEnd = false


  return (
    <div className='loading-ap'>
      <div className='wrap-backdrop-loading' />
      {!fetchingEnd && (
        <div className='icon-ap'>
          <IsotipoAP className='image' />
        </div>
      )}
      {fetchingEnd && (
        <React.Fragment>
          <img src={LogoAP} alt='' className='logo-img' />
          <div className='div-mask' />
        </React.Fragment>
      )}
    </div>
  );
}




export default Loading;
