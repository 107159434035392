import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomSwitch from '@commons/customSwitch/customSwitch'
import SectionItem from '@commons/home/sectionItem'
import { SectionsActions } from '@features/sections'
import { IBlock } from '@features/sections/sections-interface'
import { IInitialState } from '@store/index'
import {
  MDBCol,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions
} from 'mdbreact'
import fonts from '@assets/fonts/fonts'

interface IMyProps {
  dataModal?: any
  setOpenModal: (value: boolean) => void
}
const PrimaryBloqModal: React.FC<IMyProps> = ({ dataModal, setOpenModal }) => {
  const dispatch = useDispatch()
  const optionsLinks: any = process.env.REACT_APP_INTERNAL_LINKS
  const { backgroundWhite, style, itemData, section } = dataModal
  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const [data, setData] = useState(itemData)
  const [validate, setValidate] = useState(false)
  const [toggle, setToggle] = useState(false)

  const sections = homeEdit ? homeEdit.config.sections : []

  useEffect(() => {
    if (itemData) {
      let data = itemData
      data.internalLink ? setToggle(false) : setToggle(true)
      setData(data)
    }
  }, [itemData])

  useEffect(() => {}, [])

  const handleChange = (e: any, ext?: boolean) => {
    if (ext) {
      setData({ ...data, internalLink: false, [e.target.name]: e.target.value })
    } else {
      setData({ ...data, [e.target.name]: e.target.value })
    }
  }

  const handleChangeSwitch = (e: any) => {
    setToggle((prevState) => !prevState)
  }

  const handleSelect = (value: any) => {
    setData({ ...data, icon: value[0] })
  }

  const handleSelectLinks = (value: any) => {
    setData({ ...data, internalLink: true, link: value[0] })
  }

  const handleEdit = () => {
    const sectionEdit = sections.findIndex((sect) => sect.id === section.id)
    const bloqEdit = section.blocks.findIndex(
      (bloq: IBlock) => bloq.id === itemData.id
    )
    if (!data.internalLink) {
      data.link = data.link.replace('https://', '')
      data.link = data.link.replace('http://', '')
      data.link = `https://${data.link}`
    }
    if (bloqEdit !== -1) {
      section.blocks[bloqEdit] = data
    } else {
      section.blocks[0] = data
    }
    sections[sectionEdit] = section
  }

  const handleConfirmModal = (e: any) => {
    e.preventDefault()
    if (data.title !== '') {
      setValidate(false)
      handleEdit()
      if (homeEdit) {
        homeEdit.config.sections = sections
        dispatch(SectionsActions.setHomeEdit(homeEdit))
      }
      setOpenModal(false)
    } else {
      setValidate(true)
    }
  }

  return (
    <>
      <MDBRow className="modal_section_title_box">
        <MDBCol size="12">
          <h3 className="modal_section_title">
            Agregando un bloque {backgroundWhite ? 'secundario' : 'primario'}
          </h3>
        </MDBCol>
        <MDBCol size="12">
          <p className="modal_section_section">
            {/* Sección {backgroundWhite ? 'D' : 'B o C'} */}
          </p>
        </MDBCol>
      </MDBRow>

      <div className="modal-body modal_body p-0 m-0">
        <div>
          <div className="bloqs_modal_sectionItem_container">
            <div className="bloqs_modal_sectionItem_outline">
              <SectionItem
                itemData={data}
                backgroundWhite={backgroundWhite}
                style={style}
              />
            </div>
          </div>
          <MDBRow center className="modal_edit_container_inputs">
            <MDBCol size="11">
              {validate && (
                <label className="text-danger" htmlFor="Title">
                  *Debe contener el titulo como mínimo
                </label>
              )}
              <input
                type="text"
                id="Title"
                name="title"
                className="modal_edit_inputs w-100"
                value={data?.title}
                onChange={handleChange}
              />
            </MDBCol>

            <MDBCol size="11">
              <input
                type="text"
                id="Title"
                name="description"
                placeholder="descripción"
                className="modal_edit_inputs w-100"
                value={data?.description}
                onChange={handleChange}
              />
            </MDBCol>
            <MDBCol size="11">
              {validate && (
                <label className="text-danger" htmlFor="Title">
                  *Debe contener el icono como mínimo
                </label>
              )}
              <MDBSelect
                label="Selecciona un icono"
                getValue={(value) => handleSelect(value)}
                className="modal_edit_select"
              >
                <MDBSelectInput selected="Selecciona un icono" />
                <MDBSelectOptions>
                  <MDBSelectOption disabled>
                    Selecciona un icono
                  </MDBSelectOption>
                  {fonts?.map((data, index) => (
                    <MDBSelectOption key={index} value={data.icon}>
                      {data.title}

                      <div
                        className={`demoIconPlus__black ${
                          data.plus ? 'demoIconPlus' : 'demoIcon'
                        }`}
                      >
                        <i className={data.icon} />
                      </div>
                    </MDBSelectOption>
                  ))}
                </MDBSelectOptions>
              </MDBSelect>
            </MDBCol>

            <MDBCol size="7">
              {toggle ? (
                <input
                  type="url"
                  id="link"
                  name="link"
                  className="modal_edit_inputs w-100"
                  value={data?.link}
                  placeholder="ingrese el link"
                  onChange={(e) => {
                    handleChange(e, true)
                  }}
                />
              ) : (
                <MDBSelect
                  label={data.link}
                  getValue={(value) => handleSelectLinks(value)}
                  className="modal_edit_select"
                >
                  <MDBSelectInput selected="Selecciona Link" />
                  <MDBSelectOptions>
                    <MDBSelectOption disabled>Selecciona Link</MDBSelectOption>
                    {optionsLinks &&
                      JSON.parse(optionsLinks).map(
                        (data: any, index: number) => (
                          <MDBSelectOption key={index} value={data.linkUrl}>
                            {data.linkUrl}
                          </MDBSelectOption>
                        )
                      )}
                  </MDBSelectOptions>
                </MDBSelect>
              )}
            </MDBCol>
            <MDBCol size="4" className="modal_edit_switch">
              <CustomSwitch
                id="linkToggle"
                name="linkToggle"
                checked={toggle}
                onChange={handleChangeSwitch}
                optionLabels={['Externo', 'Interno']}
              />
            </MDBCol>
            <MDBCol size="11">
              <input
                type="text"
                id="idQualtrics"
                name="idQualtrics"
                className="modal_edit_inputs w-100"
                value={data?.idQualtrics}
                placeholder="idQualtrics"
                onChange={handleChange}
              />
            </MDBCol>
          </MDBRow>
        </div>
      </div>
      <MDBRow className="justify-content-center px-4 mb-2">
        <MDBCol size="6" className="pr-2">
          <button
            type="button"
            className="modal_edit_close_button"
            onClick={() => setOpenModal(false)}
          >
            Cancelar
          </button>
        </MDBCol>
        <MDBCol size="6" className="pl-2">
          <button
            type="button"
            className="modal_edit_confirm_button"
            onClick={(e) => handleConfirmModal(e)}
          >
            Guardar
          </button>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default PrimaryBloqModal
