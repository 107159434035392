import { IAction } from '@commons/common-interfaces'
import {
  SET_ALERT_MODAL,
  SET_CHECK_DATA,
  SET_OPEN_MODAL
} from './common-actions'

import { ISettings } from './common-interfaces'

const initialState: ISettings = {
  alert: { status: false, data: { msg: '', title: '' } },
  options: {
    status: [
      { title: 'Imágenes', id: 'IMAGE_UPDATE', finished: false },
      { title: 'Dirección', id: 'ADDRESS', finished: false },
      { title: 'Redes Sociales', id: 'SOCIAL_MEDIA', finished: false },
      { title: 'Preguntas Frecuentes', id: 'FAQ', finished: false },
      { title: 'Home Arauco+', id: 'SECTIONS_HOME', finished: false }
    ]
  },
  modalOpen: { status: false }
}

export const CommonsReducer = (
  state: ISettings,
  action: IAction
): ISettings => {
  state = state ? state : initialState

  switch (action.type) {
    case SET_ALERT_MODAL:
      return {
        ...state,
        alert: action.payload
      }
    case SET_CHECK_DATA:
      return {
        ...state,
        options: action.payload
      }
    case SET_OPEN_MODAL:
      return {
        ...state,
        modalOpen: action.payload
      }

    default:
      return state
  }
}
