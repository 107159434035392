import React, { useCallback, useEffect, useState } from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { Link, useHistory } from 'react-router-dom'
import { GrAddCircle } from 'react-icons/gr'
import { HiOutlineClock } from 'react-icons/hi'
import { BiBell } from 'react-icons/bi'

import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { MenuActions } from '.'
import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions
} from 'mdbreact'
import { IMallInfo } from '@features/mall/mall-interfaces'
import { HomeThunks } from '@features/home'
import { FaRegUserCircle } from 'react-icons/fa'
import { LogOut } from 'src/hooks/logout'
import './menu.scss'

export interface IOption {
  text: string
  value: string
}

const Menu: React.FC = () => {
  const menuSelected = useSelector(
    (state: IInitialState) => state.menu.selected
  )
  const { malls } = useSelector((state: IInitialState) => state.general)
  const { info } = useSelector((state: IInitialState) => state.mall)
  const [fetching, setFetching] = useState(false)

  const dispatch = useDispatch()
  let history = useHistory()
  const handleSelect = (value: any) => {
    const mallId: number = Number(value[0])

    if (!fetching) {
      history.push(`/editMall/${mallId}`)
      // dispatch(MallThunks.getMall(mallId, setFetching))
      // dispatch(MallThunks.getSocialNetworks(mallId, setFetching))
      // dispatch(MallThunks.getMallFaqs(mallId))
      // dispatch(SectionsThunks.getHomeToEdit(mallId, true, setFetching))
    }
  }
  const fetchGetMalls = useCallback(async () => {
    setFetching(true)
    await dispatch(HomeThunks.getMalls())
    setFetching(false)
  }, [dispatch])

  useEffect(() => {
    fetchGetMalls()
  }, [fetchGetMalls])

  return (
    <div className="container-menu">
      <div className="container-select-mall">
        <label htmlFor="mallSelect">Edita otro Mall:</label>
        <MDBSelect
          id="mallSelect"
          getValue={(value) => handleSelect(value)}
          className="mall-select"
        >
          <MDBSelectInput selected={info.name} />
          <MDBSelectOptions>
            <MDBSelectOption disabled>Seleccione un mall</MDBSelectOption>
            {malls?.map((mall: IMallInfo, index: number) => (
              <MDBSelectOption key={index} value={mall.mallId}>
                {mall.name}
              </MDBSelectOption>
            ))}
          </MDBSelectOptions>
        </MDBSelect>
      </div>
      <div className="horizontal-line" />
      <Link
        to="/malls"
        onClick={() => dispatch(MenuActions.updateMenuSelected('/malls'))}
      >
        <div
          className={`link-box ${
            menuSelected === '/malls' ? 'link-box__selected' : ''
          }`}
        >
          <GrAddCircle className="icon-add-mall" />
          Agregar nuevo Mall
        </div>
      </Link>
      <Link
        to={'/editMall/' + info.mallId + '/schedules'}
        onClick={() => dispatch(MenuActions.updateMenuSelected('/schedules'))}
      >
        <div
          className={`link-box ${
            menuSelected === '/schedules' ? 'link-box__selected' : ''
          }`}
        >
          <HiOutlineClock className="icon-schedules" />
          Configurar horarios
        </div>
      </Link>
      <Link
        to={'/editMall/' + info.mallId + '/notifications'}
        onClick={() =>
          dispatch(MenuActions.updateMenuSelected('/notifications'))
        }
      >
        <div
          className={`link-box ${
            menuSelected === '/schedules' ? 'link-box__selected' : ''
          }`}
        >
          <BiBell className="icon-schedules" />
          Configurar notificaciones
        </div>
      </Link>
      <div className="horizontal-line-bottom" />

      <div className="menu_logout" onClick={() => LogOut(history)}>
        <span className="menu_logout_icon_container">
          <FaRegUserCircle />
        </span>{' '}
        Cerrar sesión
      </div>
    </div>
  )
}

export default Menu
