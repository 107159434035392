import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { MenuReducer } from '@commons/menu'
import { HomeReducer } from '@features/home'
import { MallReducer } from '@features/mall'
import { IMall } from '@features/mall/mall-interfaces'
import { IGeneralData } from '@features/home/home-interfaces'
import { SectionsReducer } from '@features/sections'
import { ISectionsState } from '@features/sections/sections-interface'
import { ISettings } from '@commons/common-interfaces'
import { CommonsReducer } from '@commons/common-reducers'
import { ScheduleReducer } from '@features/schedules'
import { ISchedules } from '@features/schedules/schedule-interface'
import { NotificationsReducer } from '@features/notifications'
import { INotifications } from '@features/notifications/notifications-interface'

const middleware = [thunk]

const allReducers = combineReducers({
  menu: MenuReducer.MenuReducer,
  general: HomeReducer.HomeReducer,
  mall: MallReducer.MallReducer,
  sections: SectionsReducer.SectionsReducer,
  schedules: ScheduleReducer.ScheduleReducer,
  notifications: NotificationsReducer.NotificationsReducer,
  settings: CommonsReducer
})

export interface IInitialState {
  menu: MenuReducer.IMenuState
  mall: IMall
  general: IGeneralData
  sections: ISectionsState
  schedules: ISchedules
  notifications: INotifications
  settings: ISettings
}

const enhancers = []
const windowIfDefined = typeof window === 'undefined' ? null : (window as any)

if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__())
}

export const store = createStore(
  allReducers,
  compose(applyMiddleware(...middleware), ...enhancers)
)
