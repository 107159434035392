import React, { useState } from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import { Markup } from 'interweave'
import { RiPencilLine } from 'react-icons/ri'
import { FiMinus } from 'react-icons/fi'
import './sectionItem.scss'
import {
  IBlock,
  ISection,
  ISubBlock
} from '@features/sections/sections-interface'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { SectionsActions } from '@features/sections'
import CommonModal from '@commons/commonModal/commonModal'

interface IMyProps {
  mallSelected: number
  itemData: ISubBlock
  backgroundWhite: boolean
  style: string
  isLast: boolean
  setOpenSubModal?: (value: boolean) => void
  setDataModal?: (value: any) => void
  isModalEdit?: boolean
  bloqId?: number
  section?: ISection
}

const SubBloque: React.FC<IMyProps> = (props) => {
  const {
    itemData,
    backgroundWhite,
    style,
    isLast,
    setOpenSubModal,
    setDataModal,
    isModalEdit,
    bloqId,
    section
  } = props

  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const [openModal, setOpenModal] = useState(false)

  const dispatch = useDispatch()

  const textColor = `title-item__${style}`
  const backgroundIconColor = `item-icon__${style}`
  const sectionItemBorder = `section-item__${style}`
  const iconPlusStyle = `demoIconPlus__${style}`

  let renderIcon = (
    <div className={`demoIcon ${backgroundWhite ? 'demoIcon__black' : ''}`}>
      <i className={itemData.icon} />
    </div>
  )

  if (String(itemData.icon).includes('Plus')) {
    renderIcon = (
      <div
        className={`demoIconPlus ${
          backgroundWhite ? 'demoIconPlus__black' : ''
        } ${iconPlusStyle}`}
      >
        <i className={itemData.icon} />
      </div>
    )
  }

  let styleSpecialSection = {}

  const handleConfirmDeleteSubBlock = () => {
    if (homeEdit) {
      homeEdit.config.sections.map((sectionMap: ISection) => {
        if (sectionMap.id === section?.id) {
          sectionMap.blocks.map((blockMap: IBlock) => {
            if (blockMap.id === bloqId) {
              if (blockMap.subBlocks?.length === 1) {
                blockMap.collapsible = false
              }
              const index = blockMap.subBlocks?.findIndex(
                (subBlock: ISubBlock) => subBlock.id === itemData.id
              )
              if (index !== undefined) {
                blockMap.subBlocks?.splice(index, 1)
              }
            }
            return blockMap
          })
        }
        return sectionMap
      })
      dispatch(SectionsActions.setHomeEdit(homeEdit))
    }
    setOpenModal(false)
  }

  const options = {
    title: 'eliminar',
    subtitle: `el subbloque '${itemData.title}'?`,
    motifText: ''
  }

  const renderItem = (
    <div
      id={itemData.idQualtrics.length > 0 ? itemData.idQualtrics : ''}
      className={`sub-bloque-item ${
        backgroundWhite ? 'sub-bloque-item__default' : ''
      } ${isLast ? 'sub-bloque-item__end' : ''}
        ${style !== 'default' ? sectionItemBorder : ''}`}
      style={styleSpecialSection}
    >
      <MDBRow className="no-padding">
        <MDBCol className="grid-item-text no-padding" size="9">
          <div className={`title-item ${textColor}`}>
            <Markup content={itemData.title} />
          </div>
          <div className="description">
            <Markup content={itemData.description} />
          </div>
        </MDBCol>
        <MDBCol className="grid-item-icon no-padding" size="3">
          <div className={`item-icon ${backgroundIconColor}`}>{renderIcon}</div>
        </MDBCol>
      </MDBRow>
      {!isModalEdit && (
        <>
          <div
            className="edit"
            onClick={() => {
              setDataModal &&
                setDataModal({
                  itemData,
                  backgroundWhite,
                  style,
                  bloqId,
                  section
                })
              setOpenSubModal && setOpenSubModal(true)
            }}
          >
            <RiPencilLine className="icon-edit" />
          </div>
          <span className="delete" onClick={() => setOpenModal(true)}>
            <FiMinus className="icon-delete" />
          </span>
        </>
      )}
    </div>
  )

  return (
    <React.Fragment>
      {itemData.idQualtrics.length === 0 && renderItem}
      {itemData.idQualtrics.length > 0 && renderItem}
      {openModal && (
        <CommonModal
          setOpenModal={setOpenModal}
          handleConfirmModal={handleConfirmDeleteSubBlock}
          options={options}
        />
      )}
    </React.Fragment>
  )
}

export default SubBloque
