import React from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import AlertSection from './alertSection'

interface IMyProps {
  section: any
  setDataModal?: (value: any) => void
  setOpenAlertModal?: (value: boolean) => void
}

const AlertTemplate: React.FC<IMyProps> = (props) => {
  const { section, setDataModal, setOpenAlertModal } = props

  return (
    <>
      <MDBRow>
        <MDBCol size="2" />
        <MDBCol size="10">
          <div className={`h-100 templateHome_background`}>
            <div className="home-background home-background__2">
              <AlertSection
                setDataModal={setDataModal}
                setOpenAlertModal={setOpenAlertModal}
                section={section}
                isTemplate={true}
              />
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default AlertTemplate
