import { IAction } from '@commons/common-interfaces'

const SET_NEW_NOTIFICATION = 'SET_NEW_NOTIFICATION'

const setNewNotification = (notification: any): IAction => ({
  type: SET_NEW_NOTIFICATION,
  payload: notification
})

export { SET_NEW_NOTIFICATION, setNewNotification }
