import React, { useCallback, useEffect, useState } from 'react'
import { MDBBtn, MDBInput, MDBRow, MDBCol } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { IoMdClose } from 'react-icons/io'
import { BiMinusCircle } from 'react-icons/bi'
import Loading from '@commons/loading/Loading'
import { MallActions, MallThunks } from '@features/mall'
import { ISocialNetwork } from '../mall-interfaces'
import { setAlertModal } from '@commons/common-actions'

interface IMyProps {
  setSelectView: any
  match: any
  setOptions: any
  options: any
  selectView: any
}

const SocialNetworkPage: React.FC<IMyProps> = (props: any) => {
  const { socialNetworks, info } = useSelector(
    (state: IInitialState) => state.mall
  )
  const dispatch = useDispatch()
  const [fetching, setFetching] = useState(true)
  const mallID = props.match.params.id

  const fetchGetSocialNetwork = useCallback(() => {
    dispatch(MallThunks.getSocialNetworks(mallID, setFetching))
  }, [dispatch, mallID])

  useEffect(() => {
    fetchGetSocialNetwork()
  }, [fetchGetSocialNetwork])

  const setSocialNetwork = async () => {
    const arrWithLink = socialNetworks.filter(
      (sn: any) => sn.link && sn.link.length > 0
    )
    if (info.active) {
      if (arrWithLink.length === 0) {
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: '',
              title: 'Debes tener al menos una red social activa. '
            }
          })
        )
        return
      }
    }

    dispatch(MallThunks.setSocialNetworks(mallID, socialNetworks, setFetching))
    if (arrWithLink.length > 0) {
      const resp = await setActive()
      props.setOptions([...resp])
    }
  }

  const handleInput = (e: any) => {
    socialNetworks[
      socialNetworks.findIndex((sn: ISocialNetwork) => sn.type === e.target.id)
    ].link = e.target.value
    dispatch(MallActions.setSocialNetworks(socialNetworks))
  }

  const clearInput = (inputId: string) => {
    socialNetworks[
      socialNetworks.findIndex((sn: ISocialNetwork) => sn.type === inputId)
    ].link = ''
    dispatch(MallActions.setSocialNetworks(socialNetworks))
  }

  const setActive = () => {
    const position = props.options.findIndex(
      (value: any) => value.id === props.selectView
    )
    let newArray = props.options
    for (let i = 0; i < props.options.length; i++) {
      const element = props.options[i]
      if (element.id === props.selectView) {
        newArray[position].finished = true
      }
    }

    return newArray
  }

  return (
    <div className="container-full">
      <MDBRow className="justify-content-between container-title">
        <MDBCol size="10" className="col-title">
          <h3 className="title">Redes Sociales</h3>
        </MDBCol>
        <MDBCol size="2">
          <div
            className="btn-close"
            onClick={() => props.setSelectView('DEFAULT_VIEW')}
          >
            <IoMdClose size={32} className="icon-close" />
          </div>
        </MDBCol>
      </MDBRow>
      {fetching ? (
        <Loading />
      ) : (
        <>
          {Array.isArray(socialNetworks)
            ? socialNetworks.map((sn: ISocialNetwork) => (
                <MDBRow
                  className={`container-rrss ${
                    sn.link ? '' : 'container-rrss__null'
                  }`}
                >
                  <MDBCol size="2" className="col-rrss">
                    <img
                      className="social-media-logo"
                      src={sn.icon}
                      alt="logo red social"
                    />
                  </MDBCol>
                  <MDBCol size="8" className="col-rrss">
                    <MDBInput
                      className="input-rrss"
                      placeholder="Ingrese Link"
                      value={sn.link}
                      type="url"
                      id={sn.type}
                      onInput={handleInput}
                    />
                  </MDBCol>
                  <MDBCol size="2">
                    {sn.link && String(sn.link).length > 0 ? (
                      <BiMinusCircle
                        size={24}
                        className="icon-clear-active"
                        onClick={() => clearInput(sn.type)}
                      />
                    ) : (
                      <BiMinusCircle size={24} className="icon-clear" />
                    )}
                  </MDBCol>
                </MDBRow>
              ))
            : ''}
        </>
      )}

      <MDBRow className="container-btns">
        <MDBCol size="6" className="col-cancel">
          <MDBBtn
            rounded
            className="btn_cancelar"
            id="btn_cancelar"
            onClick={() => props.setSelectView('DEFAULT_VIEW')}
          >
            Cancelar
          </MDBBtn>
        </MDBCol>
        <MDBCol size="6" className="col-save">
          <MDBBtn
            rounded
            className="btn_save"
            id="btn_save"
            onClick={() => setSocialNetwork()}
          >
            Guardar
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </div>
  )
}

export default SocialNetworkPage
