import React, { ReactNode } from 'react'
interface IMyProps {
  setOpenModal: (value: boolean) => void
  handleConfirmModal?: any
  children: ReactNode
}

const SectionModal: React.FC<IMyProps> = (props: IMyProps) => {
  return (
    <div className="modal_box">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal_content_edit modal_section_content m-0 p-0">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default SectionModal
