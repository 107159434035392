import { IPromise } from '@commons/common-interfaces'
import RequestService from '../../core/request-service'
import { ISchedules } from './sections-interface'

export class SectionsService extends RequestService {
  static getBaseTemplateHome(langSelected: string): Promise<IPromise> {
    const lang = langSelected ? langSelected : 'es'
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/schedule/template?language=${lang}`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`
      }
    }

    return super.get(endpoint, headers)
  }

  static saveSchedule(
    mallId: number,
    value: ISchedules,
    langSelected: string
  ): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/schedule`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': String(mallId)
      }
    }
    const params = value

    return super.post(endpoint, params, headers)
  }
  static saveScheduleDefault(mallId: number, value: any): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/schedule/default`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': String(mallId)
      }
    }
    const params = value

    return super.post(endpoint, params, headers)
  }

  static deleteSchedule(mallId: number, idSchedule: string): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/schedule/${idSchedule}`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': String(mallId)
      }
    }

    return super.delete(endpoint, headers)
  }
}
