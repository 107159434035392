import { IPromise } from '@commons/common-interfaces'
import RequestService from '../../core/request-service'

export class LoginService extends RequestService {
  static Login(value: any): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/bo/login/authenticate`
    const headers: Headers | string[][] | Record<string, string> | undefined = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const params = value

    return super.post(endpoint, params, headers)
  }

  static RefreshToken(value: any): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/bo/authorize/token`
    const headers: Headers | string[][] | Record<string, string> | undefined = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    let send
    if (value) {
      send = JSON.parse(value)
    }

    const params = { refreshToken: send }

    return super.post(endpoint, params, headers)
  }
}
