import { IAction } from '@commons/common-interfaces'
import { IMall } from '@features/mall/mall-interfaces'
import { IBloq, INewMall } from './home-interfaces'
const LIST_MALLS = 'LIST_MALLS'
const SET_MALL_BLOQS = 'SET_MALL_BLOQS'
const LIST_NEW_MALLS = 'LIST_NEW_MALLS'

const setMallsList = (MallsList: Array<IMall>): IAction => ({
  type: LIST_MALLS,
  payload: MallsList
})

const setMallBloqs = (MallBloqs: IBloq): IAction => ({
  type: SET_MALL_BLOQS,
  payload: MallBloqs
})

const setNewMallsList = (NewMallsList: INewMall[]): IAction => ({
  type: LIST_NEW_MALLS,
  payload: NewMallsList
})

export {
  LIST_MALLS,
  SET_MALL_BLOQS,
  LIST_NEW_MALLS,
  setMallsList,
  setMallBloqs,
  setNewMallsList
}
