import React, { useCallback, useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { MallThunks } from '@features/mall'
import Loading from '@commons/loading/Loading'
import Logo from '@assets/images/arauco-plus.svg'
import { VscMenu } from 'react-icons/vsc'
import { AiOutlineSearch } from 'react-icons/ai'
import { IInitialState } from '@store/index'
import Section from '@commons/home/section'
import CarouselSection from '@commons/home/carouselSection'
import AlertSection from '@commons/home/alertSection'
import { setCheckData } from '@commons/common-actions'
interface IMyProps {
  paramsId?: number
  defaultTime: boolean
}
const NewHomePreview: React.FC<IMyProps> = (props) => {
  const { paramsId, defaultTime } = props
  const { info, home } = useSelector((state: IInitialState) => state.mall)
  const { settings } = useSelector((state: IInitialState) => state)
  const [fetching, setFetching] = useState(false)
  const dispatch = useDispatch()

  let sections: any = []
  if (home && home.length > 0 && home[0]) {
    sections = home[0].config.sections
  }

  useEffect(() => {
    if (sections.length >= 1) {
      const value = settings.options?.status
      value[4] = {
        title: 'Home Arauco+',
        id: 'SECTIONS_HOME',
        finished: true
      }
      dispatch(setCheckData({ status: value }))
    } else {
      const value = settings.options?.status
      value[4] = {
        title: 'Home Arauco+',
        id: 'SECTIONS_HOME',
        finished: false
      }
      dispatch(setCheckData({ status: value }))
    }
  }, [home])

  const fetchGetMallHome = useCallback(async () => {
    setFetching(true)
    if (info) {
      let mallId = info.mallId
      if (props.paramsId) {
        mallId = info.mallId ? info.mallId : props.paramsId
      }
      if (defaultTime) {
        await dispatch(MallThunks.getDefaultHomeMall(mallId, defaultTime, 'es'))
      } else {
        await dispatch(MallThunks.getDefaultHomeMall(mallId, false, 'es'))
      }
    }
    setFetching(false)
  }, [dispatch, info])

  useEffect(() => {
    fetchGetMallHome()
  }, [fetchGetMallHome])

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <MDBRow className="home-preview_Background">
          <MDBCol size="12" className="home-preview">
            <div className="home-header">
              <div className="logo-mobile">
                <div className="mall-selected">
                  Estás en:&nbsp;
                  <span className="mall-name">{info ? info.name : ''}</span>
                </div>
                <img className="logo-img" src={Logo} alt="parque arauco" />
              </div>
              <div className="icon-login">
                <div className="demoIconPlus">
                  <i className="iconPlus-user-01" />
                </div>
              </div>
              <div className="menu">
                <VscMenu className="menu-icon" />
              </div>
              <h3 className="title-header">¿Qué estas buscando?</h3>
              <div className="container-input">
                <div className="contenedor-buscador">
                  <MDBRow>
                    <MDBCol size="1" className="column">
                      <AiOutlineSearch className="icon-search" />
                    </MDBCol>
                    <MDBCol size="11">Ej: Ropa de mujer</MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
            <div className="home-body">
              {sections.map((section: any) => {
                switch (section.type) {
                  case 'directory':
                    return (
                      <Section
                        key={`section${section.id}`}
                        sectionData={section}
                        mall={info}
                      />
                    )
                  case 'alert':
                    return <AlertSection section={section} />
                  case 'carousel':
                    return <CarouselSection section={section} />
                  default:
                    return null
                }
              })}
            </div>
          </MDBCol>
        </MDBRow>
      )}
    </>
  )
}

export default NewHomePreview
