import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MDBCol, MDBRow } from 'mdbreact'
import { IInitialState } from '@store/index'
import { Link, useHistory } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'
import Loading from '@commons/loading/Loading'
import { MdModeEdit } from 'react-icons/md'
interface IMyProps {
  setIndex: (value: number) => void
  langSelected: string
}

const ScheduleTimes: React.FC<IMyProps> = ({ setIndex, langSelected }) => {
  const { info, home } = useSelector((state: IInitialState) => state.mall)
  const { times } = useSelector((state: IInitialState) => state.schedules)
  const history = useHistory()
  const [newTimes, setNewTimes]: any = useState()
  const [fetch, setFetch]: any = useState(false)

  const timesBlock = () => {
    setFetch(true)
    if (times.length > 0) {
      times.forEach((element) => {
        if (element.hour < 8 || element.hour > 21) {
          return (element.id = element.id = '')
        }
        return element.id ? element.id : (element.id = '')
      })
      let grouped = times.reduce(
        (redux: any, value: any, index: any, array) => {
          if (value.id) {
            if (redux.length) {
              // funcion para devolver agrupado los horario en base a el id
              if (redux[redux.length - 1].length >= 1) {
                // si existe ese id en el reducer lo pushea junto con el anterior
                if (redux[redux.length - 1][0].id === array[index].id) {
                  redux[redux.length - 1].push(value)
                } else {
                  // si el id no existe en el reducer pero existe crea un array dentro del reducer
                  redux.push(value.id === array[index].id && [value])
                }
              } else {
                redux.push(value.id === array[index].id && [value])
              }
            }
          } else {
            // retorna el valor que no tenga id y lo incrusta en el reducer

            redux.push(value)
          }
          return redux
        },
        []
      )
      setNewTimes(grouped)

      setFetch(false)
    }
  }
  useEffect(() => {
    timesBlock()
  }, [times])

  const handleView = (element: any) => {
    let index = home.findIndex((t) => t.id === element[0].id)
    setIndex(index)
  }

  return (
    <div className="bloqs-col mt-4">
      {fetch && <Loading />}
      <MDBRow className="h-100">
        <MDBCol size="12">
          <MDBRow className="justify-content-between container-title">
            <MDBCol size="10" className="col-title">
              <h3 className="title">Configuración Horarios</h3>
            </MDBCol>
            <MDBCol size="2">
              <Link className="btn-close" to={`/editMall/${info.mallId}`}>
                <IoMdClose size={32} className="icon-close" />
              </Link>
            </MDBCol>
          </MDBRow>
          <MDBRow center className="schedule_block">
            <MDBCol
              onClick={() => setIndex(0)}
              size="10"
              className={
                ' text-center schedule_times active_time schedule_edit_container'
              }
            >
              Home default
              <span
                className="schedule_edit"
                onClick={() =>
                  history.push({
                    pathname: `/editMall/${info.mallId}/sections/isDefault`,
                    state: {
                      startTime: 0,
                      endTime: 2359,
                      name: 'Home Default'
                    }
                  })
                }
              >
                <MdModeEdit />
              </span>
            </MDBCol>
            <span className="divisor_schedule" />
            {newTimes?.map((element: any, i: number) => {
              if (element.length >= 1) {
                return (
                  <MDBCol
                    onClick={() => handleView(element)}
                    key={i}
                    size="10"
                    className={
                      element.length > 1
                        ? ` text-center schedule_times p-${
                            element.length + 2 > 5 ? 5 : element.length + 2
                          } active_time schedule_edit_container`
                        : ` text-center schedule_times active_time schedule_edit_container`
                    }
                  >
                    {element[0].hour}:00 -{' '}
                    {element[element.length - 1].hour + 1}:00
                    <span
                      className="schedule_edit"
                      onClick={() =>
                        history.push({
                          pathname: `/editMall/${info.mallId}/sections/${element[0].id}/${langSelected}`,
                          state: {
                            startTime: element[0].hour,
                            endTime: element[element.length - 1].hour + 1,
                            name: element[0].name
                          }
                        })
                      }
                    >
                      <MdModeEdit />
                    </span>
                  </MDBCol>
                )
              } else {
                if (element) {
                  if (element?.hour > 8 && element?.hour < 21) {
                    return (
                      <MDBCol
                        key={i}
                        size="10"
                        className={
                          element?.occupied
                            ? ' text-center schedule_times active_time'
                            : 'text-center schedule_times schedule_edit_container'
                        }
                        onClick={() =>
                          history.push({
                            pathname: `/editMall/${info.mallId}/sections/new/${langSelected}`,
                            state: {
                              startTime: element.hour,
                              endTime: element.hour + 1,
                              name: element.name
                            }
                          })
                        }
                      >
                        {element.hour}:00 - {element.hour + 1}:00
                      </MDBCol>
                    )
                  }
                }
              }
            })}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  )
}

export default ScheduleTimes
