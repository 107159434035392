import { IAction } from '@commons/common-interfaces'

const SET_MALL_SCHEDULE = 'SET_MALL_SCHEDULE'

const setMallSchedule = (scheduleInfo: any): IAction => ({
  type: SET_MALL_SCHEDULE,
  payload: scheduleInfo
})

export { SET_MALL_SCHEDULE, setMallSchedule }
