import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { IInitialState } from '@store/index'
import { MallService } from '@features/mall'
import { v4 as uuidv4 } from 'uuid'
import { SectionsActions, SectionsService } from '.'
import {
  IBaseTemplateHome,
  IBlock,
  ISchedules,
  ISection,
  ISubBlock
} from './sections-interface'
import { setAlertModal } from '@commons/common-actions'
import { LoginService } from '@features/login'
import { delTokenAccess, setTokenAccess } from 'src/auth/tokenAccessMng'

type AppThunk = ThunkAction<void, IInitialState, unknown, Action<string>>
const refreshKey = localStorage.getItem('backoffice_user_refresh')

export const getHomeToEdit = (
  id: string | number,
  isDefault: boolean,
  setFetching: (value: boolean) => void,
  index: number,
  langSelected: string,
  startTime?: string,
  endTime?: string
): AppThunk => async (dispatch) => {
  try {
    setFetching(true)
    const result = await MallService.MallService.getScheduleHomeMall(
      id,
      isDefault,
      langSelected,
      startTime,
      endTime
    )

    if (result.httpCode === 200) {
      if (result.data.data.length > 0 && index !== 99999) {
        const homeAux: IBaseTemplateHome = result.data.data[index]

        homeAux.config.sections.map((section: ISection) => {
          if (section.id === undefined || section.id === '') {
            section.id = uuidv4()
          }

          section.blocks.map((block: IBlock) => {
            if (block.id === undefined || block.id === '') {
              block.id = uuidv4()
            }

            if (block.subBlocks !== undefined) {
              block.subBlocks.map((subBlock: ISubBlock) => {
                if (subBlock.id === undefined || subBlock.id === '') {
                  subBlock.id = uuidv4()
                }

                return subBlock
              })
            }

            return block
          })

          return section
        })

        dispatch(SectionsActions.setHomeEdit(homeAux))
        dispatch(
          SectionsActions.setInitialHome(JSON.parse(JSON.stringify(homeAux)))
        )
        setFetching(false)
      } else {
        const lang = langSelected ? langSelected : 'es'
        const result2 = await SectionsService.SectionsService.getBaseTemplateHome(
          lang
        )

        if (
          result2.httpCode === 200 &&
          result2.data &&
          result2.data.data &&
          result2.data.data.length > 0
        ) {
          const homeAux: IBaseTemplateHome = result2.data.data[0]

          homeAux.config.sections.map((section: ISection) => {
            if (section.id === undefined || section.id === '') {
              section.id = uuidv4()
            }

            section.blocks.map((block: IBlock) => {
              if (block.id === undefined || block.id === '') {
                block.id = uuidv4()
              }

              if (block.subBlocks !== undefined) {
                block.subBlocks.map((subBlock: ISubBlock) => {
                  if (subBlock.id === undefined || subBlock.id === '') {
                    subBlock.id = uuidv4()
                  }

                  return subBlock
                })
              }

              return block
            })

            return section
          })

          dispatch(SectionsActions.setHomeEdit(homeAux))
          dispatch(
            SectionsActions.setInitialHome(JSON.parse(JSON.stringify(homeAux)))
          )
        }

        setFetching(false)
      }
    } else {
      setFetching(false)
    }
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e)
    setFetching(false)
    // dispatch(
    //   setAlertModal({
    //     status: true,
    //     data: {
    //       msg: '',
    //       title:
    //         'No se logró obtener la configuración del home para el horario indicado. Por favor intente nuevamente.'
    //     }
    //   })
    // )
  }
}

export const saveSchedule = (
  mallId: number,
  setFetching: (value: boolean) => void,
  home: any,
  langSelected: string
): AppThunk => async (dispatch, getState) => {
  try {
    setFetching(true)
    const value: ISchedules = {
      mallId: mallId,
      schedules: home
    }
    const result: any = await SectionsService.SectionsService.saveSchedule(
      mallId,
      value,
      langSelected
    )

    if (result.data.code === 200) {
      setFetching(false)
      return
    }
    if (result.data.code === 401) {
      const resTok = await LoginService.LoginService.RefreshToken(refreshKey)

      if (resTok.httpCode == 403) {
        delTokenAccess()
        await dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: 'Su token venció',
              title: 'Por favor ingrese de vuelta'
            }
          })
        )
        setTimeout(() => {
          window.location.reload()
        }, 3000)
        return
      }
      if (resTok.httpCode === 200) {
        setTokenAccess(
          resTok.data.data.accessToken,
          resTok.data.data.refreshToken
        )
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: result.data.message,
              title: 'Su token a sido actualizado vuelva a intentarlo'
            }
          })
        )
        return
      }
      return
    }

    setFetching(false)
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: '',
          title:
            'No se logró Guardar la configuración del home para el horario indicado. Por favor intente nuevamente.'
        }
      })
    )
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e)
    setFetching(false)
    // dispatch(
    //   setAlertModal({
    //     status: true,
    //     data: {
    //       msg: '',
    //       title:
    //         'No se logró Guardar la configuración del home para el horario indicado. Por favor intente nuevamente.'
    //     }
    //   })
    // )
  }
}

export const saveScheduleDefault = (
  mallId: number,
  setFetching: (value: boolean) => void,
  home: any,
  language: string
): AppThunk => async (dispatch, getState) => {
  try {
    setFetching(true)
    const value: ISchedules = {
      mallId: mallId,
      ...home,
      language: language
    }
    const result: any = await SectionsService.SectionsService.saveScheduleDefault(
      mallId,
      value
    )

    if (result.data.code === 200) {
      setFetching(false)
      return
    }
    if (result.httpCode === 401) {
      const resTok = await LoginService.LoginService.RefreshToken(refreshKey)

      if (resTok.httpCode == 403) {
        delTokenAccess()
        await dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: 'Su token venció',
              title: 'Por favor ingrese de vuelta'
            }
          })
        )
        return
      }
      if (resTok.httpCode === 200) {
        setTokenAccess(
          resTok.data.data.accessToken,
          resTok.data.data.refreshToken
        )
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: result.data.message,
              title: 'Su token a sido actualizado vuelva a intentarlo'
            }
          })
        )
        return
      }
      return
    }
    setFetching(false)
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: '',
          title:
            'No se logró Guardar la configuración del home para el horario indicado. Por favor intente nuevamente.'
        }
      })
    )
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e)

    setFetching(false)
    // dispatch(
    //   setAlertModal({
    //     status: true,
    //     data: {
    //       msg: '',
    //       title:
    //         'No se logró Guardar la configuración del home para el horario indicado. Por favor intente nuevamente.'
    //     }
    //   })
    // )
  }
}

export const deleteSchedule = (
  mallId: number,
  idSchedule: string
): AppThunk => async (dispatch, getState) => {
  try {
    const result: any = await SectionsService.SectionsService.deleteSchedule(
      mallId,
      idSchedule
    )

    if (result.data.code === 200) {
      return
    }
  } catch (e) {}
}

export const getImageUrl = (
  logo: string,
  type: string,
  resultResponse: any
): AppThunk => async (dispatch, getState) => {
  const id = getState().mall.info.mallId
  const value = {
    mallId: id,
    contentType: type,
    folderName: 'section',
    fileBase64: logo
  }

  try {
    const result: any = await MallService.MallService.updateMallLogo(value)
    if (result.data.statusCode === 200) {
      resultResponse(result.data.data.location)
      return result
    }
    if (result.data.statusCode === 401) {
      const resTok = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(resTok.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(resTok.data.data.refreshToken)
      )
      if (resTok.httpCode === 403) {
        localStorage.removeItem('backoffice_user_access')
        localStorage.removeItem('backoffice_user_refresh')
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: result.data.message,
              title: 'Por favor ingrese de vuelta'
            }
          })
        )
        return
      }
      return
    }
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: result.data.message,
          title: 'Error al cargar la imagen'
        }
      })
    )
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e)
  }
}
