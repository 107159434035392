import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { MallActions, MallService } from './index'
import { IInitialState } from '@store/index'
import { IFaq, INewFaq, ISocialMedia, ISocialNetwork } from './mall-interfaces'
import { setAlertModal, setCheckData } from '@commons/common-actions'
import { LoginService } from '@features/login'

type AppThunk = ThunkAction<void, IInitialState, unknown, Action<string>>
const refreshKey = localStorage.getItem('backoffice_user_refresh')

export const getMall = (
  id: string | number,
  setFetching: (value: boolean) => void
): AppThunk => async (dispatch, getState) => {
  try {
    setFetching(true)
    const result = await MallService.MallService.getMall(id)
    if (result.data.code === 200) {
      dispatch(MallActions.setMallInfo(result.data.data))
      const value = getState().settings.options?.status

      if (result.data.data.logo) {
        value[0] = { title: 'Imágenes', id: 'IMAGE_UPDATE', finished: true }
        dispatch(setCheckData({ status: value }))
      } else {
        value[0] = { title: 'Imágenes', id: 'IMAGE_UPDATE', finished: false }
        dispatch(setCheckData({ status: value }))
      }
      if (result.data.data.direction) {
        value[1] = { title: 'Dirección', id: 'ADDRESS', finished: true }
        dispatch(setCheckData({ status: value }))
      } else {
        value[1] = { title: 'Dirección', id: 'ADDRESS', finished: false }
        dispatch(setCheckData({ status: value }))
      }
    }
    if (result.httpCode === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    }
    setFetching(false)
  } catch (e) {
    setFetching(false)
    /* eslint-disable no-console */
    console.error(e)
  }
}

export const getDefaultHomeMall = (
  id: string | number,
  isDefault: boolean,
  langSelected: string,
  startTime?: string,
  endTime?: string
): AppThunk => async (dispatch) => {
  try {
    const result = await MallService.MallService.getScheduleHomeMall(
      id,
      isDefault,
      langSelected,
      startTime,
      endTime
    )

    if (result.data.code === 200) {
      dispatch(MallActions.setHomeMall(result.data))
    }
    if (result.httpCode === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    }
  } catch (e) {
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: '',
          title: 'Error al actualizar la información de mall'
        }
      })
    )
    /* eslint-disable no-console */
    console.error(e)
  }
}

export const updateMallInfo = (): AppThunk => (dispatch, getState) => {
  const value = getState().mall.info
  const id = value.mallId
  MallService.MallService.updateMallInfo(id, value)
    .then((result) => {
      if (result.data.code === 200) {
        const checkValue = getState().settings.options?.status

        if (value.logo) {
          checkValue[0] = {
            title: 'Imágenes',
            id: 'IMAGE_UPDATE',
            finished: true
          }
          dispatch(setCheckData({ status: checkValue }))
        } else {
          checkValue[0] = {
            title: 'Imágenes',
            id: 'IMAGE_UPDATE',
            finished: false
          }
          dispatch(setCheckData({ status: checkValue }))
        }
        if (value.direction) {
          checkValue[1] = { title: 'Dirección', id: 'ADDRESS', finished: true }
          dispatch(setCheckData({ status: checkValue }))
        } else {
          checkValue[1] = { title: 'Dirección', id: 'ADDRESS', finished: false }
          dispatch(setCheckData({ status: checkValue }))
        }
      } else if (result.httpCode === 401) {
        LoginService.LoginService.RefreshToken(refreshKey).then((resp: any) => {
          if (resp.code === 403) {
            localStorage.removeItem('backoffice_user_access')
            localStorage.removeItem('backoffice_user_refresh')
            dispatch(
              setAlertModal({
                status: true,
                data: {
                  msg: result.data.message,
                  title: 'Su token venció.Por favor ingrese de vuelta'
                }
              })
            )
            return
          }
          if (resp.code === 200) {
            localStorage.setItem(
              'backoffice_user_access',
              JSON.stringify(resp.data.data.accessToken)
            )
            localStorage.setItem(
              'backoffice_user_refresh',
              JSON.stringify(resp.data.data.refreshToken)
            )
            dispatch(
              setAlertModal({
                status: true,
                data: {
                  msg: result.data.message,
                  title: 'Su token a sido actualizado vuelva a intentarlo'
                }
              })
            )
            return
          }
        })
      } else {
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: result.data.message,
              title: 'Error al actualizar la información de mall'
            }
          })
        )
      }
    })
    .catch(async (e) => {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: 'Su token a vencido',
            title: 'Por favor ingrese de vuelta'
          }
        })
      )
    })
}

export const updateMallLogo = (
  logo: string,
  type: string,
  msg: any
): AppThunk => async (dispatch, getState) => {
  const id = getState().mall.info.mallId
  const value = {
    mallId: id,
    contentType: type,
    folderName: 'logo',
    fileBase64: logo
  }

  try {
    const result: any = await MallService.MallService.updateMallLogo(value)

    if (result.data.statusCode === 200) {
      dispatch(MallActions.updateLogo(result.data.data.location))
      msg('¡Logo guardado exitosamente!')
      return
    }
    if (result.data.statusCode === 401) {
      const resTok = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(resTok.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(resTok.data.data.refreshToken)
      )
      if (resTok.httpCode === 403) {
        localStorage.removeItem('backoffice_user_access')
        localStorage.removeItem('backoffice_user_refresh')
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: result.data.message,
              title: 'Por favor ingrese de vuelta'
            }
          })
        )
        return
      }
      return
    }
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: result.data.message,
          title: 'No se pudo actualizar el logo, por favor intente mas tarde'
        }
      })
    )
  } catch (e) {
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: 'Su token a vencido',
          title: 'Por favor ingrese de vuelta'
        }
      })
    )
    console.error(e)
  }
}

export const uploadImage = (img: string, type: string): AppThunk => async (
  dispatch,
  getState
) => {
  const id = getState().mall.info.mallId
  const value = {
    mallId: id,
    contentType: type,
    folderName: 'notification',
    fileBase64: img
  }

  try {
    const result = await MallService.MallService.uploadImage(value)

    if (result.data.code === 200) {
      return result
    }
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e)
  }
}

export const getSocialNetworks = (
  id: string | number,
  setFetching: (value: boolean) => void
): AppThunk => async (dispatch, getState) => {
  try {
    setFetching(true)
    const mallId = id.toString()
    const result = await MallService.MallService.getSocialNetworks(mallId)

    if (result.data.code === 200) {
      dispatch(MallActions.setSocialNetworks(result.data.data))
      const value = getState().settings.options?.status
      let checked = result.data?.data?.map((value: any) => {
        if (value.link !== '') {
          return true
        } else {
          return false
        }
      })

      if (
        result.data.data[0].link ||
        result.data.data[1].link ||
        result.data.data[2].link ||
        result.data.data[3].link ||
        result.data.data[4].link
      ) {
        value[2] = {
          title: 'Redes Sociales',
          id: 'SOCIAL_MEDIA',
          finished: checked
        }
        dispatch(setCheckData({ status: value }))
      } else {
        value[2] = {
          title: 'Redes Sociales',
          id: 'SOCIAL_MEDIA',
          finished: false
        }
        dispatch(setCheckData({ status: value }))
      }

      setFetching(false)
    }
    if (result.httpCode === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    }
    if (result.httpCode === 403) {
      localStorage.removeItem('backoffice_user_access')
      localStorage.removeItem('backoffice_user_refresh')
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: result.data.message,
            title: 'Por favor ingrese de vuelta'
          }
        })
      )
      return
    }
  } catch (e) {
    setFetching(false)

    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: '',
          title: 'Error al actualizar la información de mall'
        }
      })
    )
    /* eslint-disable no-console */
    console.error(e)
  }
}

export const setSocialNetworks = (
  id: string | number,
  socialNetworks: ISocialNetwork[],
  setFetching: (value: boolean) => void
): AppThunk => async (dispatch, getState) => {
  setFetching(true)
  socialNetworks.forEach((sn: ISocialNetwork) =>
    String(sn.link).trim() !== '' ? String(sn.link).trim() : null
  )
  const value: ISocialMedia = { data: socialNetworks }

  try {
    const mallId = id.toString()
    const result = await MallService.MallService.setSocialNetworks(
      mallId,
      value
    )

    if (result.status === 200) {
      dispatch(MallActions.setSocialNetworks(socialNetworks))

      // Blue Check
      const value = getState().mall.socialNetworks
      const valueCheck = getState().settings.options?.status

      if (
        value[0].link ||
        value[1].link ||
        value[2].link ||
        value[3].link ||
        value[4].link
      ) {
        valueCheck[2] = {
          title: 'Redes Sociales',
          id: 'SOCIAL_MEDIA',
          finished: true
        }
        dispatch(setCheckData({ status: valueCheck }))
      } else {
        valueCheck[2] = {
          title: 'Redes Sociales',
          id: 'SOCIAL_MEDIA',
          finished: false
        }
        dispatch(setCheckData({ status: valueCheck }))
      }

      setFetching(false)
    } else if (result.status === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    } else {
      setFetching(false)

      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: '',
            title: 'Error al actualizar redes sociales'
          }
        })
      )
    }
  } catch (e) {
    setFetching(false)

    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: '',
          title: 'Error al actualizar la información de mall'
        }
      })
    )
    /* eslint-disable no-console */
    console.error(e)
  }
}

export const getMallFaqs = (mallId?: number): AppThunk => async (
  dispatch,
  getState
) => {
  const id = mallId ? mallId : getState().mall.info.mallId

  try {
    const result = await MallService.MallService.getMallFaqs(id)

    if (result.data.code === 200) {
      dispatch(MallActions.getMallFaqs(result.data.data))
      const value = getState().settings.options?.status

      if (result.data.data.length > 0) {
        value[3] = {
          title: 'Preguntas Frecuentes',
          id: 'FAQ',
          finished: true
        }
        dispatch(setCheckData({ status: value }))
      } else {
        value[3] = {
          title: 'Preguntas Frecuentes',
          id: 'FAQ',
          finished: false
        }
        dispatch(setCheckData({ status: value }))
      }
    }
  } catch (e) {
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: '',
          title: 'Error al actualizar la información de mall'
        }
      })
    )
    /* eslint-disable no-console */
    console.error(e)
  }
}

export const newMallFaqs = (value: INewFaq): AppThunk => async (
  dispatch,
  getState
) => {
  const id = getState().mall.info.mallId
  const data = {
    order: 0,
    mallId: id,
    answer: value.answer,
    question: value.question
  }

  try {
    const result = await MallService.MallService.newMallFaqs(id, data)

    if (result.data.code === 200) {
      //nothing
    } else if (result.data.code === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    } else {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: result.data.message,
            title:
              'Error al actualizar pregunta frecuente, revise que haya ingresado titulo y respuesta'
          }
        })
      )
    }
  } catch (e) {
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: '',
          title: 'Error al actualizar la información de mall'
        }
      })
    )
    /* eslint-disable no-console */
    console.error(e)
  }
}

export const editMallFaqs = (value: IFaq): AppThunk => async (dispatch) => {
  const id = value.id ? value.id : 0

  try {
    const result = await MallService.MallService.editMallFaqs(id, value)

    if (result.data.code === 200) {
      //nothing
    }
    if (result.data.code === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    }
  } catch (e) {
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: '',
          title: 'Error al actualizar la información de mall'
        }
      })
    )
    /* eslint-disable no-console */
    console.error(e)
  }
}

export const deleteOneMallFaqs = (id: number): AppThunk => async (dispatch) => {
  try {
    const result = await MallService.MallService.deleteOneMallFaqs(id)

    if (result.data.code === 200) {
      // nothing
    }
    if (result.data.code === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    }
  } catch (e) {
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: '',
          title: 'Error al actualizar la información de mall'
        }
      })
    )
    /* eslint-disable no-console */
    console.error(e)
  }
}

export const toggleActivateMall = (value: any): AppThunk => async (
  dispatch
) => {
  try {
    const result = await MallService.MallService.toggleActivateMall(value)

    if (result.data.code === 200) {
      dispatch(
        setAlertModal({
          status: false,
          data: {
            msg: 'Felicidades',
            title: 'Se activo con éxito'
          }
        })
      )
      return
    }
    if (result.data.code === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
      if (result.data.code === 403) {
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: 'Su token a vencido',
              title: 'Por favor ingrese de vuelta'
            }
          })
        )
      }
    }
    if (result.data.code === 400) {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: result.data.data[0],
            title: 'Error al actualizar la información de mall'
          }
        })
      )
      return
    }
    if (result.data.code === 502) {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: '',
            title: 'Error al actualizar la información de mall'
          }
        })
      )
      return
    }
    if (
      result.data.code !== 200 &&
      result.data.code !== 201 &&
      result.data.code !== 204
    ) {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: '',
            title: 'Error al actualizar la información de mall'
          }
        })
      )
      return
    }
  } catch (e) {
    dispatch(
      setAlertModal({
        status: true,
        data: {
          msg: '',
          title: 'Error al actualizar la información de mall'
        }
      })
    )
    /* eslint-disable no-console */
    console.error(e)
  }
}
