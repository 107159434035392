import { IPromise } from '@commons/common-interfaces'
import RequestService from '@core/request-service'

export class ScheduleService extends RequestService {
  static getSchedulesStatus(id: any, langSelected: string): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/schedule/status?language=${langSelected}`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': id
      }
    }

    return super.get(endpoint, headers)
  }
  static getSchedules(id: any): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/schedules`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': id
      }
    }

    return super.get(endpoint, headers)
  }
}
