import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IInitialState } from '@store/index'
import { LoginService } from '@features/login'
import { setAlertModal } from '@commons/common-actions'
import AlertModal from '@commons/alertModal/alertModal'
import { useHistory } from 'react-router-dom'

const TokenSpired: React.FC = () => {
  const { settings } = useSelector((state: IInitialState) => state)
  const dispatch = useDispatch()
  const refreshKey = localStorage.getItem('backoffice_user_refresh')
  const history = useHistory()

  const checkToken = async () => {
    const resTok = await LoginService.LoginService.RefreshToken(refreshKey)

    if (resTok.data.code === 200) {
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(resTok.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(resTok.data.data.refreshToken)
      )
    } else {
      localStorage.removeItem('backoffice_user_access')
      localStorage.removeItem('backoffice_user_refresh')
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: 'inicia sesión nuevamente',
            title: 'Ha caducado la sesión'
          }
        })
      )
      setTimeout(() => {
        dispatch(
          setAlertModal({
            status: false,
            data: {
              msg: '',
              title: ''
            }
          })
        )
        history.push('/login')
      }, 3000)
    }
  }

  useEffect(() => {
    checkToken()
  }, [])

  return <>{settings.alert?.status && <AlertModal />}</>
}

export default TokenSpired
