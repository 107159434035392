import CustomSwitch from '@commons/customSwitch/customSwitch'
import {
  MDBCol,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions
} from 'mdbreact'
import { AiOutlinePlusCircle } from 'react-icons/ai'

import React, { useEffect, useState } from 'react'
import { BsImageAlt } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { SectionsActions, SectionsThunks } from '@features/sections'
import LogoCB from '@assets/images/bloque-carrusel-default.png'
import { ISection } from '@features/sections/sections-interface'

interface IMyProps {
  dataModal?: any
  setOpenModal: (value: boolean) => void
}
const CarouselBloqModal: React.FC<IMyProps> = ({ dataModal, setOpenModal }) => {
  const { section } = dataModal
  const dispatch = useDispatch()
  const optionsLinks: any = process.env.REACT_APP_INTERNAL_LINKS
  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const sections = homeEdit ? homeEdit.config.sections : []
  const [toggle, setToggle] = useState(false)
  const [selected, setSelected] = useState(0)
  const [data, setData] = useState([
    {
      backgroundUrl: '',
      link: '',
      internalLink: false,
      order: 0
    }
  ])
  const [alert, setAlert] = useState({ status: false, msg: '' })

  useEffect(() => {
    if (section.blocks) {
      setData(section.blocks)
    }
  }, [section])

  useEffect(() => {
    data[selected].internalLink ? setToggle(false) : setToggle(true)
    if (!data[selected].internalLink) {
      data[selected].link = data[selected].link.replace('https://', '')
      data[selected].link = data[selected].link.replace('http://', '')
      data[selected].link = `https://${data[selected].link}`
    }
  }, [data[selected]])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    ext?: boolean
  ) => {
    let newData = data

    if (ext) {
      newData[selected] = {
        ...newData[selected],
        internalLink: false,
        order: selected,
        [e.target.name]: e.target.value
      }
      setData([...newData])
    }
  }

  const updateLogo = (logo: any) => {
    let newData = data

    newData[selected] = {
      ...newData[selected],
      backgroundUrl: logo
    }
    setData([...newData])
  }

  const handleAddImage = async (e: any) => {
    const file = e.target.files[0]
    // const logo: string = URL.createObjectURL(file)
    const reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = async function () {
      const base64String: any = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '')
      dispatch(SectionsThunks.getImageUrl(base64String, file.type, updateLogo))
    }
  }

  const handleChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToggle((prevState) => !prevState)
  }
  const handleSelectLinks = (value: any) => {
    let newData = data
    newData[selected] = {
      ...newData[selected],
      internalLink: true,
      order: selected,
      link: value[0]
    }
    setData([...newData])
  }

  const handleAddNew = () => {
    let newobj = {
      backgroundUrl: '',
      link: '',
      internalLink: false,
      order: 0
    }
    setData([...data, newobj])
    setSelected(data.length)
  }

  const handleEdit = () => {
    const sectionEdit = sections.findIndex((sect) => sect.id === section.id)
    let newBlocks = section
    newBlocks.blocks = data
    sections[sectionEdit] = newBlocks
  }

  const handleConfirmCancelCarousel = () => {
    if (data[0].backgroundUrl === LogoCB) {
      if (homeEdit) {
        const index = homeEdit.config.sections.findIndex(
          (sectionHome: ISection) => sectionHome.id === section.id
        )
        if (index !== undefined) {
          homeEdit.config.sections.splice(index, 1)
        }
        dispatch(SectionsActions.setHomeEdit(homeEdit))
      }
      setOpenModal(false)
      return
    }

    setOpenModal(false)
  }

  const handleConfirmModal = (e: any) => {
    e.preventDefault()
    if (data[0].backgroundUrl === LogoCB) {
      setAlert({ status: true, msg: 'Por favor elija una imagen valida' })
      return
    } else {
      setAlert({ status: false, msg: '' })
    }
    handleEdit()
    if (homeEdit) {
      if (alert.status) {
        dispatch(SectionsActions.setHomeEdit(homeEdit))
      }
    }

    setOpenModal(false)
  }
  return (
    <>
      <MDBRow className="modal_section_title_box">
        <MDBCol size="12">
          <h3 className="modal_section_title">Agregando bloque Carrusel:</h3>
        </MDBCol>
        <MDBCol size="12">
          {/* <p className="modal_section_section">Sección A, B o C</p> */}
        </MDBCol>
      </MDBRow>
      <div className="modal-body modal_body p-0 m-0">
        <div>
          <div className="bloqs_modal_sectionItem_container_two">
            <div className="bloqs_modal_sectionItem_outline_two">
              {data[selected]?.backgroundUrl ? (
                <img src={data[selected]?.backgroundUrl} alt="Imagenes" />
              ) : (
                <MDBRow>
                  <MDBCol className="bloqs_sectionItem_carousel_container_modal">
                    <BsImageAlt />
                    <span>Bloque de carrusel</span>
                  </MDBCol>
                </MDBRow>
              )}
            </div>
          </div>
          <MDBRow center className="p-2">
            {alert.status && <div className="text-danger">{alert.msg}</div>}
            <MDBCol size="11" className="modal_button_add_image">
              <label
                htmlFor="inputButtonCarousel"
                className="updateImage_bluebutton m-0"
              >
                Selecciona un archivo
              </label>
              <input
                id="inputButtonCarousel"
                type="file"
                className="modal_Button_input"
                onChange={handleAddImage}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow center className="modal_edit_container_inputs">
            <MDBCol size="8">
              {toggle ? (
                <input
                  type="text"
                  id="link"
                  name="link"
                  className="modal_edit_inputs w-100"
                  value={data[selected]?.link}
                  onChange={(e) => handleChange(e, true)}
                  placeholder={'ingrese el link'}
                />
              ) : (
                <MDBSelect
                  label={
                    data[selected].link
                      ? data[selected].link
                      : 'Ingrese un link'
                  }
                  getValue={(value) => handleSelectLinks(value)}
                  className="modal_edit_select"
                >
                  <MDBSelectInput
                    selected={
                      data[selected].link
                        ? data[selected].link
                        : 'Ingrese un link'
                    }
                  />
                  <MDBSelectOptions>
                    <MDBSelectOption disabled>Selecciona Link</MDBSelectOption>
                    {optionsLinks &&
                      JSON.parse(optionsLinks).map(
                        (data: any, index: number) => (
                          <MDBSelectOption key={index} value={data.linkUrl}>
                            {data.linkUrl}
                          </MDBSelectOption>
                        )
                      )}
                  </MDBSelectOptions>
                </MDBSelect>
              )}
            </MDBCol>
            <MDBCol size="3" className="modal_edit_switch">
              <CustomSwitch
                id="linkToggle"
                name="linkToggle"
                checked={toggle}
                onChange={handleChangeSwitch}
                optionLabels={['Ext', 'Int']}
                small={true}
              />
            </MDBCol>
            <MDBCol size="12" className="modal_edit_carousel_text">
              *La imagen debe ser de 834 x 130 pixeles, tal como se especifica
              en el “Manual de creación de imagen para Carrusel”. .jpg o .png,
              100 kb como máximo.
            </MDBCol>
          </MDBRow>
        </div>
      </div>
      <MDBRow center>
        {data?.map((_, index) => (
          <MDBCol key={index} size="1">
            <button
              onClick={() => setSelected(index)}
              className={
                index === selected
                  ? 'modal_edit_carousel_button activeModal'
                  : 'modal_edit_carousel_button'
              }
            >
              {index + 1}
            </button>
          </MDBCol>
        ))}
        {data.length < 3 && (
          <MDBCol size="1">
            <button
              onClick={handleAddNew}
              className="modal_edit_carousel_button_add"
            >
              <AiOutlinePlusCircle />
            </button>
          </MDBCol>
        )}
      </MDBRow>
      <div className="modal-footer justify-content-center">
        <MDBCol size="5">
          <button
            type="button"
            className="modal_closeButton"
            onClick={handleConfirmCancelCarousel}
          >
            Cancelar
          </button>
        </MDBCol>
        <MDBCol size="5">
          <button
            type="button"
            className="modal_confirmButton"
            onClick={(e) => handleConfirmModal(e)}
          >
            Guardar
          </button>
        </MDBCol>
      </div>
    </>
  )
}

export default CarouselBloqModal
