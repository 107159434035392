import React, { useCallback, useEffect, useState } from 'react'
import Layout from '@commons/layout/layout'
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow
} from 'mdbreact'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import Logo from '@assets/images/arauco-plus.svg'
import { HomeThunks } from '.'
import Loading from '@commons/loading/Loading'
import { GrConfigure } from 'react-icons/gr'
import { MenuActions } from '@commons/menu'
import { FaRegUserCircle } from 'react-icons/fa'
import { LogOut } from 'src/hooks/logout'

const Homepage: React.FC = () => {
  const { malls } = useSelector((state: IInitialState) => state.general)
  const dispatch = useDispatch()
  const history = useHistory()

  const [fetching, setFetching] = useState(false)

  const fetchGetMalls = useCallback(async () => {
    setFetching(true)
    await dispatch(HomeThunks.getMalls())
    setFetching(false)
  }, [dispatch])

  useEffect(() => {
    fetchGetMalls()
  }, [fetchGetMalls])

  dispatch(MenuActions.updateMenuSelected('/'))

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <Layout>
          <div className="home-page">
            <div className="home_page_logout" onClick={() => LogOut(history)}>
              <span className="home_page_logout_icon_container">
                <FaRegUserCircle />
              </span>{' '}
              Cerrar sesión
            </div>
            <MDBContainer fluid className="h-100">
              <MDBRow className="row-logo-admin">
                <MDBCol size="12" className="text-center">
                  <p className="malls_prev_admin">ADMINISTRADOR</p>
                </MDBCol>
                <MDBCol size="12" className="text-center">
                  <img src={Logo} className="malls_prev_logo" alt="logo" />
                </MDBCol>
              </MDBRow>
              <MDBRow className="align-items-center h-65" center>
                <MDBCol xs="6" md="3" className="mall_prev_card">
                  <Link
                    to={'/malls'}
                    onClick={() =>
                      dispatch(MenuActions.updateMenuSelected('/malls'))
                    }
                  >
                    <MDBCard alignment="center" className="home-card">
                      <MDBCardBody className="home-card-body">
                        <GrConfigure className="home_card_icon" />
                        <MDBCardTitle className="home_card_title">
                          Configura <br /> tus Malls
                        </MDBCardTitle>
                        <hr />
                        <MDBCardText className="home_card_subtitle">
                          Tienes {malls.length} malls <br /> en Arauco+
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </Layout>
      )}
    </>
  )
}

export default Homepage
