import { IPromise } from '@commons/common-interfaces'

/**
 * @description Base class for Api Request
 * extends your services using this class if you want
 */
export default class RequestService {
  /**
   * @description Fetch Data using GET method
   * @param {string} endpoint requested url
   * @param {object} headers Headers object used on the request
   */
  static async get(
    endpoint: string,
    headers: Headers | string[][] | Record<string, string> | undefined
  ): Promise<IPromise> {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers
    })

    const data =
      response.ok && response.statusText !== 'No Content'
        ? await response.json()
        : []

    return {
      httpCode: response.status,
      data
    }
  }

  /**
   * @description Send data using PUT method
   * @param {string} endpoint requested url
   * @param {object} params object used like body data
   * @param {object} headers Headers object used on the request
   */

  static async putForm(
    endpoint: string,
    params: any,
    headers: Headers | string[][] | Record<string, string> | undefined
  ): Promise<IPromise> {
    const bodyData = new FormData()

    Object.keys(params).forEach((value) =>
      bodyData.append(value, params[value])
    )
    const response = await fetch(endpoint, {
      method: 'PUT',
      body: bodyData,
      headers
    })
    if (response.ok) {
      const data = response.ok ? await response.json() : []
      return {
        httpCode: data.httpCode,
        data
      }
    } else {
      return {
        httpCode: response.status,
        data: response
      }
    }
  }

  static async put(
    endpoint: string,
    params: any,
    headers: Headers | string[][] | Record<string, string> | undefined
  ): Promise<IPromise> {
    const bodyData = new FormData()

    Object.keys(params).forEach((value) =>
      bodyData.append(value, params[value])
    )
    const response = await fetch(endpoint, {
      method: 'PUT',
      body: JSON.stringify(params),
      headers
    })
    if (response.ok) {
      const data = response.ok ? await response.json() : []
      return {
        httpCode: data.httpCode,
        data
      }
    } else {
      return {
        httpCode: response.status,
        data: response
      }
    }
  }

  static async post(
    endpoint: string,
    params: any,
    headers: Headers | string[][] | Record<string, string> | undefined
  ): Promise<IPromise> {
    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(params),
      headers
    })

    if (response.ok) {
      const data = response.ok ? await response.json() : []
      return {
        httpCode: data.httpCode,
        data
      }
    } else {
      return {
        httpCode: response.status,
        data: response
      }
    }
  }

  static async delete(
    endpoint: string,
    headers: Headers | string[][] | Record<string, string> | undefined
  ): Promise<IPromise> {
    const response = await fetch(endpoint, {
      method: 'DELETE',
      headers
    })
    if (response.ok) {
      const data = response.ok ? await response.json() : []
      return {
        httpCode: data.httpCode,
        data
      }
    } else {
      return {
        httpCode: response.status,
        data: response
      }
    }
  }
}
