import React, { CSSProperties } from 'react'
import SectionItem from '@commons/home/sectionItem'
import { IBlock, ISection } from '@features/sections/sections-interface'
import { useDrag } from 'react-dnd'
import { ItemTypes } from './itemTypes'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { SectionsActions } from '@features/sections'
import { v4 as uuidv4 } from 'uuid'

const style: CSSProperties = {
  cursor: 'move'
}

export interface IMyProps {
  block: IBlock
  backgroundWhite: boolean
  mallId: number
  blockType: 'primary' | 'secundary' | 'carousel' | 'alert'
}

export const BlockDrag: React.FC<IMyProps> = ({
  block,
  backgroundWhite,
  mallId,
  blockType
}) => {
  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const dispatch = useDispatch()

  let acceptedType = ''
  let styleBlock = ''
  switch (blockType) {
    case 'primary':
      acceptedType = ItemTypes.BLOCK
      styleBlock = 'bloqs_sectionItem_container'
      break
    case 'secundary':
      acceptedType = ItemTypes.SECUNDARY_BLOCK
      styleBlock = 'bloqs_sectionItem_secondary_container'
      break
    case 'carousel':
      acceptedType = ItemTypes.SPECIAL_BLOCK
      styleBlock = 'bloqs_sectionItem_carousel_container'
      break
    case 'alert':
      acceptedType = ItemTypes.SPECIAL_BLOCK
      styleBlock = ''
      break
    default:
      acceptedType = ''
      styleBlock = ''
  }

  const addBlockToHomeEdit = (newBlock: IBlock, sectionMod: ISection) => {
    if (sectionMod.backgroundWhite) {
      newBlock.id = uuidv4()
      // newBlock.order = sectionMod.order
      newBlock.order = sectionMod.blocks.length
    }
    // newBlock.order = sectionMod.order
    newBlock.order = sectionMod.blocks.length
    newBlock.id = uuidv4()

    sectionMod.blocks.push(newBlock)
    delete sectionMod.dropEffect
    if (homeEdit) {
      if (sectionMod.id !== undefined && String(sectionMod.id).length > 0) {
        const index = homeEdit.config.sections.findIndex(
          (sectionAux: ISection) =>
            String(sectionAux.id) === String(sectionMod.id)
        )
        if (index >= 0) {
          sectionMod.order = index
          homeEdit.config.sections[index] = sectionMod
        } else if (sectionMod.backgroundWhite === true) {
          homeEdit.config.sections.push(sectionMod)
        } else {
          if (homeEdit.config.sections.length === 1) {
            homeEdit.config.sections.push(sectionMod)
          } else {
            sectionMod.order = homeEdit.config.sections.length
            homeEdit.config.sections.splice(sectionMod.order, 0, sectionMod)
          }
        }
      } else if (
        sectionMod.title !== undefined &&
        String(sectionMod.title).length > 0
      ) {
        const index = homeEdit.config.sections.findIndex(
          (sectionAux: ISection) =>
            String(sectionAux.title) === String(sectionMod.title)
        )
        sectionMod.order = index
        homeEdit.config.sections[index] = sectionMod
      }
      let i = homeEdit.config.sections.findIndex((value) => {
        return value.backgroundWhite === true
      })

      if (i >= 0) {
        let sect = homeEdit.config.sections[i]
        sect.order = homeEdit.config.sections.length
        homeEdit.config.sections.splice(i, 1)
        homeEdit.config.sections.push(sect)
      }
      dispatch(SectionsActions.setHomeEdit(homeEdit))
    }
  }

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: acceptedType,
      item: block,
      end: (item, monitor) => {
        const sectionResult = monitor.getDropResult<ISection>()
        if (item && sectionResult) {
          addBlockToHomeEdit(item, JSON.parse(JSON.stringify(sectionResult)))
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId()
      })
    }),
    [block, addBlockToHomeEdit]
  )

  const opacity = isDragging ? 0.4 : 1
  return (
    <div
      ref={drag}
      role="menuitem"
      className={styleBlock}
      style={{ ...style, opacity }}
    >
      <SectionItem
        itemData={block}
        backgroundWhite={backgroundWhite}
        style={block.style ? block.style : ''}
        mallSelected={mallId}
      />
    </div>
  )
}
