import { IAction } from '@commons/common-interfaces'
import { IBaseTemplateHome } from './sections-interface'

const SET_HOME_EDIT = 'SET_HOME_EDIT'
const SET_INITIAL_HOME = 'SET_INITIAL_HOME'

const setHomeEdit = (home: IBaseTemplateHome | null): IAction => ({
  type: SET_HOME_EDIT,
  payload: home
})

const setInitialHome = (home: IBaseTemplateHome | null): IAction => ({
  type: SET_INITIAL_HOME,
  payload: home
})

export { SET_HOME_EDIT, SET_INITIAL_HOME, setHomeEdit, setInitialHome }
