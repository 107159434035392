import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { IInitialState } from '@store/index'
import { ScheduleService } from './schedule-service'
import { ScheduleActions } from '.'
import { LoginService } from '@features/login'
type AppThunk = ThunkAction<void, IInitialState, unknown, Action<string>>
const refreshKey = localStorage.getItem('backoffice_user_refresh')

export const getMallScheduleStatus = (
  id: string | number,
  langSelected: string
): AppThunk => async (dispatch, getState) => {
  try {
    const result = await ScheduleService.getSchedulesStatus(id, langSelected)
    if (result.httpCode === 200) {
      dispatch(ScheduleActions.setMallSchedule(result.data.data))
    }
    if (result.httpCode === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    }
  } catch (e) {
    console.error(e)
  }
}

export const getMallSchedule = (id: string | number): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const result = await ScheduleService.getSchedules(id)
    if (result.httpCode === 200) {
      // dispatch(ScheduleActions.setMallSchedule(result.data.data))
    }
  } catch (e) {
    console.error(e)
  }
}
