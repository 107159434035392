import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { HomeActions } from './index'
import { IInitialState } from '@store/index'
import { HomeService } from './home-service'
import { v4 as uuidv4 } from 'uuid'
import { IBlock } from '@features/sections/sections-interface'
import { LoginService } from '@features/login'
import { delTokenAccess, setTokenAccess } from 'src/auth/tokenAccessMng'
import { setAlertModal } from '@commons/common-actions'

type AppThunk = ThunkAction<void, IInitialState, unknown, Action<string>>

const refreshKey = localStorage.getItem('backoffice_user_refresh')

export const getMalls = (): AppThunk => async (dispatch) => {
  try {
    const result = await HomeService.getMalls()

    if (result.httpCode === 200) {
      dispatch(HomeActions.setMallsList(result.data.data))
    }
    if (result.httpCode === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
      const retry = await HomeService.getMalls()

      if (result.httpCode === 200) {
        dispatch(HomeActions.setMallsList(retry.data.data))
      }
    }

    return false
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e)
  }
}

export const getMallBloqs = (
  setFetching: (value: boolean) => void
): AppThunk => async (dispatch) => {
  try {
    setFetching(true)
    const result = await HomeService.getMallBloqs()

    if (result.httpCode === 200) {
      const primary = []
      const secondary = []
      const carousel = []
      const alert = []

      for (let i = 0; i < result.data.data.length; i++) {
        const element = result.data.data[i]

        if (element.id === undefined || element.id === '') {
          element.id = uuidv4()
          element.blocks.map((block: IBlock) => {
            if (block.id === undefined || block.id === '') {
              block.id = uuidv4()
            }

            return block
          })
        }

        if (element.backgroundWhite === false) {
          primary.push(element)
        }

        if (element.backgroundWhite === true) {
          secondary.push(element)
        }

        if (element.type === 'carousel') {
          carousel.push(element)
        }

        if (element.type === 'alert') {
          alert.push(element)
        }
      }

      const data = { primary, secondary, alert, carousel }

      dispatch(HomeActions.setMallBloqs(data))
      setFetching(false)
    }
    if (result.httpCode === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    }
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e)
    setFetching(false)
  }
}

export const getNewMalls = (
  setFetching: (value: boolean) => void
): AppThunk => async (dispatch) => {
  try {
    setFetching(true)
    const result = await HomeService.getNewMalls()

    if (result.httpCode === 200) {
      dispatch(HomeActions.setNewMallsList(result.data.data))
      setFetching(false)
    }
    if (result.httpCode === 401) {
      const result = await LoginService.LoginService.RefreshToken(refreshKey)
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    }
    setFetching(false)
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e)
    setFetching(false)
  }
}

export const createNewMalls = (id: number): AppThunk => async (dispatch) => {
  try {
    const result = await HomeService.createNewMalls(id)

    if (result.httpCode === 200) {
      //nothing
    }
    if (result.httpCode === 401) {
      const resTok: any = await LoginService.LoginService.RefreshToken(
        refreshKey
      )
      if (resTok.httpCode === 403) {
        delTokenAccess()
        await dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: 'Su token venció',
              title: 'Por favor ingrese de vuelta'
            }
          })
        )
        setTimeout(() => {
          window.location.reload()
        }, 3000)
        return
      }
      if (resTok.httpCode === 200) {
        setTokenAccess(
          resTok.data.data.accessToken,
          resTok.data.data.refreshToken
        )
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: result.data.message,
              title: 'Su token a sido actualizado vuelva a intentarlo'
            }
          })
        )
        return
      }
    }
    if (result.httpCode === 500) {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg:
              'Te faltan permisos, genera un ticket de validación <a href="https://parauco.atlassian.net/servicedesk/customer/portal/1/create/15" target="_blank">aquí</a>',
            title: 'Ocurrió un problema'
          }
        })
      )
    }
    return result.httpCode
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e)
  }
}
