import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { IInitialState } from '@store/index'
import { LoginService } from './login-service'
import { ILoginError } from './login-interface'

type AppThunk = ThunkAction<void, IInitialState, unknown, Action<string>>

export const Login = (
  value: any,
  err: (value: ILoginError) => void,
  history: any
): AppThunk => async (dispatch, getState) => {
  try {
    const result = await LoginService.Login(value)
    if (result.data.code === 200) {
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
      history.push('/')
    }
    if (result.httpCode === 401) {
      err({
        msgEmail: 'Correo invalidó. Contactar al administrador.',
        msgPassword:
          'Contraseña errónea, si has olvidado tu contraseña contactar al administrador.'
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export const RefreshToken = (value: any): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const result = await LoginService.RefreshToken(value)
    if (result.httpCode === 200) {
      localStorage.setItem(
        'backoffice_user_access',
        JSON.stringify(result.data.data.accessToken)
      )
      localStorage.setItem(
        'backoffice_user_refresh',
        JSON.stringify(result.data.data.refreshToken)
      )
    }
  } catch (e) {
    console.log(e)
  }
}
