import {
  IAction,
  ICheckData,
  IAlertModal,
  IModalOpen
} from '@commons/common-interfaces'

const SET_ALERT_MODAL = 'SET_ALERT_MODAL'
const SET_CHECK_DATA = 'SET_CHECK_DATA'
const SET_OPEN_MODAL = 'SET_OPEN_MODAL'

const setAlertModal = (settings: IAlertModal): IAction => ({
  type: SET_ALERT_MODAL,
  payload: settings
})

const setCheckData = (settings: ICheckData): IAction => ({
  type: SET_CHECK_DATA,
  payload: settings
})
const setModal = (settings: IModalOpen): IAction => ({
  type: SET_OPEN_MODAL,
  payload: settings
})

export {
  SET_ALERT_MODAL,
  setAlertModal,
  SET_CHECK_DATA,
  setCheckData,
  SET_OPEN_MODAL,
  setModal
}
