import { IPromise } from '@commons/common-interfaces'
import RequestService from '../../core/request-service'
import {
  ICreateFaq,
  IFaq,
  IMallImage,
  IMallInfo,
  IMallLogo,
  ISocialMedia
} from './mall-interfaces'

export class MallService extends RequestService {
  static getMall(id: string | number): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/mall/${id}`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`
      }
    }

    return super.get(endpoint, headers)
  }

  static getScheduleHomeMall(
    id: string | number,
    isDefault: boolean,
    langSelected: string,
    startTime?: string,
    endTime?: string
  ): Promise<IPromise> {
    let endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/schedules?language=${langSelected}`

    // if (isDefault) {
    //   endpoint = endpoint + '?isDefault=true'
    // } else {
    //   if (startTime && startTime.length > 0) {
    //     endpoint = endpoint + '?startTime=' + startTime
    //   }

    //   if (endTime && endTime.length > 0) {
    //     endpoint = `${endpoint}${
    //       endpoint.includes('?') ? '&endTime=' : '?endTime='
    //     }${endTime}`
    //   }
    // }

    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': String(id)
      }
    }

    return super.get(endpoint, headers)
  }

  static updateMallInfo(
    id: string | number,
    value: IMallInfo
  ): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/mall/${id}`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'Access-Control-Allow-Origin': '*'
      }
    }
    const params = value

    return super.put(endpoint, params, headers)
  }

  static updateMallLogo(value: IMallLogo): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/admin/images`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        // Authorization: `Bearer ${getKey}`
      }
    }
    const params = value

    return super.post(endpoint, params, headers)
  }

  static uploadImage(value: IMallImage): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/admin/images`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        // Authorization: `Bearer ${getKey}`
      }
    }
    const params = value

    return super.post(endpoint, params, headers)
  }

  static getSocialNetworks(id: string): Promise<IPromise> {
    const path = process.env.REACT_APP_BASE_API_URL
    const endpoint = `${path}/home/rrss/all`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': id
      }
    }

    return super.get(endpoint, headers)
  }

  static setSocialNetworks(
    mallId: string,
    socialMedia: ISocialMedia
  ): Promise<Response> {
    const path = process.env.REACT_APP_BASE_API_URL
    const endpoint = `${path}/home/rrss/masive`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': mallId
      }
    }

    return fetch(`${endpoint}`, {
      method: 'PUT',
      body: JSON.stringify(socialMedia),
      headers
    })
  }

  static getMallFaqs(id: number): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/faq`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': String(id)
      }
    }

    return super.get(endpoint, headers)
  }
  static newMallFaqs(id: number, data: ICreateFaq): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/faq`
    const params = data
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': String(id)
      }
    }

    return super.post(endpoint, params, headers)
  }

  static editMallFaqs(id: number, data: IFaq): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/faq/${id}`
    const params = data
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': String(id)
      }
    }

    return super.put(endpoint, params, headers)
  }

  static deleteOneMallFaqs(id: number): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/faq/${id}`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`
      }
    }

    return super.delete(endpoint, headers)
  }

  static toggleActivateMall(value: any): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/mall/state`
    const params = value
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': String(value.mallId)
      }
    }

    return super.put(endpoint, params, headers)
  }
}
