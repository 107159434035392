import { IAction } from '@commons/common-interfaces'
import { SET_NEW_NOTIFICATION } from './notifications-actions'
import { INotifications } from './notifications-interface'

const country = process.env.REACT_APP_COUNTRY || 'Chile'

let channel = ['ARAUCO_PLUS']
if (country === 'Colombia') {
  channel = ['PARQUE_PLUS']
}
if (country === 'Peru') {
  channel = ['PERU_PLUS']
}
const initialState: INotifications = {
  notification: {
    type: 'Notificación general - on demand',
    title: '',
    description: '',
    logo: '',
    icon: '',
    base64String: '',
    logoType: '',
    startDate: '',
    endDate: '',
    conditions: {
      interests: [],
      hasProfileComplete: null,
      hasLicensePlate: null,
      channels: channel
    }
  }
}

export const NotificationsReducer = (state: any, action: IAction): any => {
  state = state ? state : initialState

  switch (action.type) {
    case SET_NEW_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      }

    default:
      return state
  }
}
