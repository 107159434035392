import React from 'react'
import { Markup } from 'interweave'
import './section.scss'
import { MDBRow, MDBCol } from 'mdbreact'
import SectionItem from './sectionItem'
import SectionItemCollapse from './sectionItemCollapse'
import { IMallInfo } from '@features/mall/mall-interfaces'

interface IMyProps {
  mall: IMallInfo
  sectionData: any
}

const Section: React.FC<IMyProps> = (props) => {
  const { sectionData, mall } = props

  const fullSize = sectionData.blocks.length % 2 === 0

  return (
    <div
      className={`section ${
        sectionData.backgroundWhite ? 'section__white' : ''
      }`}
    >
      <span className="title">
        <Markup content={sectionData.title} />
      </span>
      <MDBRow className="grid justify-content-between">
        {!sectionData.backgroundWhite &&
          sectionData.blocks.map((item: any, index: number) => {
            const fullSizeBloq = index === 0 && !fullSize
            const marginR = index === 1 || index === 3
            return item.collapsible ? (
              <MDBCol
                key={`colSecItem${item.id}`}
                className={`col-padding${(index + 1) % 2 ? '1' : '2'}`}
                size="12"
              >
                <SectionItemCollapse
                  key={`collapse${item.id}`}
                  itemData={item}
                  backgroundWhite={sectionData.backgroundWhite}
                  mallSelected={mall.mallId}
                />
              </MDBCol>
            ) : (
              <MDBCol
                key={`colSecItem${item.id}`}
                className={`${fullSizeBloq ? 'p-0' : 'col-grid-stretch'} ${
                  marginR ? 'pr-1 pl-1' : 'pl-1 pr-1'
                }`}
                size={fullSizeBloq ? '12' : '6'}
              >
                <SectionItem
                  key={`si${item.id}`}
                  itemData={item}
                  backgroundWhite={sectionData.backgroundWhite}
                  style={item.style}
                  mallSelected={mall.mallId}
                  fullSizeBloq={fullSizeBloq}
                />
              </MDBCol>
            )
          })}
      </MDBRow>
      {sectionData.backgroundWhite && (
        <MDBRow className="grid">
          {sectionData.blocks.map((item: any, index: number) => {
            const fullSizeBloq = index === 0 && !fullSize
            const marginR = index === 1 || index === 3
            return (
              <MDBCol
                key={`colSecItem${item.id}`}
                className={`${fullSizeBloq ? 'p-0' : 'col-grid-stretch'} ${
                  marginR ? 'pr-1 pl-0' : 'pl-1 pr-0'
                }`}
                size={fullSizeBloq ? '12' : '6'}
              >
                <SectionItem
                  key={`secItem${item.id}`}
                  itemData={item}
                  backgroundWhite={sectionData.backgroundWhite}
                  style={item.style}
                  mallSelected={mall.mallId}
                  fullSizeBloq={fullSizeBloq}
                />
              </MDBCol>
            )
          })}
        </MDBRow>
      )}
    </div>
  )
}

export default Section
