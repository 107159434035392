import React, { ReactChild, ReactChildren } from 'react'
import { Redirect } from 'react-router-dom'

interface AuxProps {
  children: ReactChild | ReactChildren | any
}

const ProtectedRoutes = ({ children }: AuxProps) => {
  const getKey = localStorage.getItem('backoffice_user_access') || null
  let isAuth = null
  if (getKey) {
    isAuth = JSON.parse(getKey)
  }

  return isAuth ? children : <Redirect to="/login" />
}

export default ProtectedRoutes
