import React, { useCallback, useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '@commons/loading/Loading'
import Logo from '@assets/images/arauco-plus.svg'
import { VscMenu } from 'react-icons/vsc'
import { AiOutlineSearch } from 'react-icons/ai'
import { IInitialState } from '@store/index'
import SectionTemplate from '@commons/home/sectionTemplate'
import AlertTemplate from '@commons/home/alertTemplate'
import CarouselTemplate from '@commons/home/carouselTemplate'
import PrimaryBloqModal from './modalsEdit/primary-bloq-modal'
import SectionModal from '@commons/sectionModal/section-modal'
import SubBloqModal from './modalsEdit/sub-bloq-modal'
import CarouselBloqModal from './modalsEdit/carousel-bloq-modal'
import { IMall } from '@features/mall/mall-interfaces'
import { SectionsThunks } from '..'
import { MallThunks } from '@features/mall'
import BlockDrop from './dragAndDrop/blockDrop'
import { ISection } from '../sections-interface'
import AlertBloqModal from './modalsEdit/alert-bloq-modal'
import { v4 as uuidv4 } from 'uuid'
import { useLocation, useParams } from 'react-router-dom'

interface IMyProps {
  mall: IMall
  mallId: number
  scheduleId: string
}

const HomeTemplate: React.FC<IMyProps> = (props) => {
  const { mall } = useSelector((state: IInitialState) => state)
  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const { mallId, scheduleId } = props
  const mallData = props.mall ? props.mall : mall
  const dispatch = useDispatch()
  const location = useLocation()
  const params: any = useParams()
  const [openPrimaryModal, setOpenPrimaryModal] = useState(false)
  const [openSubModal, setOpenSubModal] = useState(false)
  const [openCarouselModal, setOpenCarouselModal] = useState(false)
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [dataModal, setDataModal] = useState({})
  const [dataTime, setDataTime]: any = useState({
    startTime: 0,
    endTime: 0,
    name: ''
  })
  const [fetching, setFetching] = useState(false)
  const [fetchingMall, setFetchingMall] = useState(false)

  const langSelected = params.lang ? params.lang : 'es'

  const fetchGetMall = useCallback(() => {
    dispatch(MallThunks.getMall(mallId, setFetchingMall))
  }, [dispatch, mallId])

  useEffect(() => {
    if (!mallData || (mallData && mallData.info && mallData.info.code === '')) {
      fetchGetMall()
    }
  }, [fetchGetMall, mallData])

  const fecthGetHomeEdit = useCallback(() => {
    if (scheduleId) {
      if (scheduleId === 'new') {
        dispatch(
          SectionsThunks.getHomeToEdit(
            mallId,
            true,
            setFetching,
            99999,
            langSelected
          )
        )
      }
      let index = mall.home.findIndex((data) => {
        return data.id === scheduleId
      })
      if (index >= 0) {
        dispatch(
          SectionsThunks.getHomeToEdit(
            mallId,
            true,
            setFetching,
            index,
            langSelected
          )
        )
        return
      }
      if (scheduleId === 'isDefault') {
        dispatch(
          SectionsThunks.getHomeToEdit(
            mallId,
            true,
            setFetching,
            0,
            langSelected
          )
        )
      }
      return
    } else {
      dispatch(
        SectionsThunks.getHomeToEdit(mallId, true, setFetching, 0, langSelected)
      )
      return 0
    }
  }, [dispatch, mallId, langSelected])

  useEffect(() => {
    fecthGetHomeEdit()
  }, [fecthGetHomeEdit, langSelected])

  const sections = homeEdit ? homeEdit.config.sections : []

  const primarySections = sections.filter(
    (sec: any) => sec.type === 'directory' && sec.backgroundWhite === false
  )
  const secundarySections = sections.filter(
    (sec: any) => sec.type === 'directory' && sec.backgroundWhite === true
  )

  const otherSections = sections.filter(
    (sec: any) =>
      sec.type !== 'directory' ||
      (sec.type === 'directory' && sec.backgroundWhite === false)
  )

  const newSection: ISection = {
    id: uuidv4(),
    type: 'directory',
    title: '',
    order: 1,
    backgroundWhite: false,
    blocks: []
  }
  const newSectionC: ISection = {
    id: uuidv4(),
    type: 'directory',
    title: '',
    order: 2,
    backgroundWhite: false,
    blocks: []
  }
  const newSectionD: ISection = {
    id: uuidv4(),
    type: 'directory',
    title: '',
    order: 3,
    backgroundWhite: false,
    blocks: []
  }
  const newSectionE: ISection = {
    id: uuidv4(),
    type: 'directory',
    title: '',
    order: 4,
    backgroundWhite: false,
    blocks: []
  }
  const newSectionF: ISection = {
    id: uuidv4(),
    type: 'directory',
    title: '',
    order: 5,
    backgroundWhite: false,
    blocks: []
  }

  const newSecundarySection: ISection = {
    id: uuidv4(),
    type: 'directory',
    title: '',
    order: 2,
    backgroundWhite: true,
    blocks: []
  }

  const newSpecialSection: ISection = {
    id: uuidv4(),
    type: 'carousel',
    title: '',
    order: 0,
    backgroundWhite: false,
    blocks: []
  }

  useEffect(() => {
    if (location.state) {
      setDataTime(location.state)
    }
  }, [])

  return (
    <div className="templateHome">
      {fetching || fetchingMall ? (
        <Loading />
      ) : (
        <>
          {scheduleId && (
            <MDBRow className="justify-content-between container-title container-title-schedule">
              <MDBCol size="10" className="col-title">
                {scheduleId !== 'isDefault' && (
                  <h3 className="title">
                    {dataTime.name} {dataTime.startTime}:00 a {dataTime.endTime}
                    :00
                  </h3>
                )}
              </MDBCol>
            </MDBRow>
          )}
          <MDBRow>
            <MDBCol size="2">
              <div className="vertical-line vertical-line__A">
                <div className="letter letter__A">A</div>
              </div>
            </MDBCol>
            <MDBCol size="10">
              <div className="h-100 templateHome_background_start">
                <div className="home-background">
                  <div className="home-header">
                    <div className="logo-mobile">
                      <div className="mall-selected">
                        {langSelected === 'es' && 'Estás en:'}
                        {langSelected === 'en' && 'You are at:'}
                        {langSelected === 'pt' && 'Você  está no:'}
                        &nbsp;
                        <span className="mall-name">{mallData.info.name}</span>
                      </div>
                      <img
                        className="logo-img"
                        src={Logo}
                        alt="parque arauco"
                      />
                    </div>
                    <div className="icon-login">
                      <div className="demoIconPlus">
                        <i className="iconPlus-user-01" />
                      </div>
                    </div>
                    <div className="menu">
                      <VscMenu className="menu-icon" />
                    </div>
                    <h3 className="title-header">
                      {langSelected === 'es' && '¿Qué estas buscando?'}
                      {langSelected === 'en' && 'What are you looking for? '}
                      {langSelected === 'pt' && 'O que você está buscando?'}
                    </h3>
                    <div className="container-input">
                      <div className="contenedor-buscador">
                        <MDBRow>
                          <MDBCol size="1" className="column">
                            <AiOutlineSearch className="icon-search" />
                          </MDBCol>
                          <MDBCol size="11">
                            {langSelected === 'es' && '¿Qué estas buscando?'}
                            {langSelected === 'en' && "women's Clothing "}
                            {langSelected === 'pt' && 'Roupa de Mulher'}
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
          {otherSections.map((section: any, index: number) => {
            switch (section.type) {
              case 'directory':
                return (
                  <SectionTemplate
                    key={section.id}
                    mall={mallData.info}
                    sectionData={section}
                    setOpenModal={setOpenPrimaryModal}
                    setOpenSubModal={setOpenSubModal}
                    setDataModal={setDataModal}
                    letters={index}
                    primarySections={primarySections.length}
                  />
                )
              case 'alert':
                section.id = uuidv4()
                return (
                  <AlertTemplate
                    setDataModal={setDataModal}
                    setOpenAlertModal={setOpenAlertModal}
                    section={section}
                    key={section.id}
                  />
                )
              case 'carousel':
                return (
                  <CarouselTemplate
                    setDataModal={setDataModal}
                    setOpenCarouselModal={setOpenCarouselModal}
                    section={section}
                    key={section.id}
                  />
                )
              default:
                return null
            }
          })}
          {primarySections.length < 2 && (
            <>
              <MDBRow>
                <MDBCol size="2" />
                <MDBCol size="10">
                  <div className={`h-100 templateHome_background`}>
                    <div className="home-background home-background__2 home-background__special">
                      <BlockDrop
                        section={newSpecialSection}
                        blockType="special"
                      />
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="2">
                  <div className="vertical-line vertical-line__C">
                    <div className="letter letter__C">C</div>
                  </div>
                </MDBCol>
                <MDBCol size="10">
                  <div className="h-100 templateHome_background">
                    <div className="home-background home-background__2">
                      <div className="section">
                        <span className="title">
                          Ingresa un bloque de sección
                        </span>
                        <BlockDrop
                          section={newSectionC}
                          blockType={'primary'}
                        />
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="2" />
                <MDBCol size="10">
                  <div className={`h-100 templateHome_background`}>
                    <div className="home-background home-background__2 home-background__special">
                      <BlockDrop
                        section={newSpecialSection}
                        blockType="special"
                      />
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="2">
                  <div className="vertical-line vertical-line__C">
                    <div className="letter letter__D">D</div>
                  </div>
                </MDBCol>
                <MDBCol size="10">
                  <div className="h-100 templateHome_background">
                    <div className="home-background home-background__2">
                      <div className="section">
                        <span className="title">
                          Ingresa un bloque de sección
                        </span>
                        <BlockDrop
                          section={newSectionD}
                          blockType={'primary'}
                        />
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="2" />
                <MDBCol size="10">
                  <div className={`h-100 templateHome_background`}>
                    <div className="home-background home-background__2 home-background__special">
                      <BlockDrop
                        section={newSpecialSection}
                        blockType="special"
                      />
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="2">
                  <div className="vertical-line vertical-line__C">
                    <div className="letter letter__E">E</div>
                  </div>
                </MDBCol>
                <MDBCol size="10">
                  <div className="h-100 templateHome_background">
                    <div className="home-background home-background__2">
                      <div className="section">
                        <span className="title">
                          Ingresa un bloque de sección
                        </span>
                        <BlockDrop
                          section={newSectionE}
                          blockType={'primary'}
                        />
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="2" />
                <MDBCol size="10">
                  <div className={`h-100 templateHome_background`}>
                    <div className="home-background home-background__2 home-background__special">
                      <BlockDrop
                        section={newSpecialSection}
                        blockType="special"
                      />
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="2">
                  <div className="vertical-line vertical-line__C">
                    <div className="letter letter__F">F</div>
                  </div>
                </MDBCol>
                <MDBCol size="10">
                  <div className="h-100 templateHome_background">
                    <div className="home-background home-background__2">
                      <div className="section">
                        <span className="title">
                          Ingresa un bloque de sección
                        </span>
                        <BlockDrop
                          section={newSectionF}
                          blockType={'primary'}
                        />
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </>
          )}
          {secundarySections.length === 0 ? (
            <>
              {primarySections.length === 2 && (
                <MDBRow>
                  <MDBCol size="2" />
                  <MDBCol size="10">
                    <div className={`h-100 templateHome_background`}>
                      <div className="home-background home-background__2 home-background__special">
                        <BlockDrop
                          section={newSpecialSection}
                          blockType="special"
                        />
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              )}
              <MDBRow>
                <MDBCol size="2">
                  <div className="vertical-line vertical-line__D">
                    <div className="letter letter__G">G</div>
                  </div>
                </MDBCol>
                <MDBCol size="10">
                  <div className="h-100 templateHome_background_end">
                    <div className="home-background home-background__2">
                      <div className="section section__white">
                        <span className="title">
                          Ingresa un bloque de sección
                        </span>
                        <BlockDrop
                          section={newSecundarySection}
                          blockType={'secondary'}
                        />
                      </div>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </>
          ) : (
            secundarySections.map((section: any, index: number) => (
              <SectionTemplate
                key={section.id}
                mall={mallData.info}
                sectionData={section}
                setOpenModal={setOpenPrimaryModal}
                setOpenSubModal={setOpenSubModal}
                setDataModal={setDataModal}
                letters={index}
                primarySections={primarySections.length}
              />
            ))
          )}
        </>
      )}
      {openPrimaryModal && (
        <SectionModal setOpenModal={setOpenPrimaryModal}>
          <PrimaryBloqModal
            dataModal={dataModal}
            setOpenModal={setOpenPrimaryModal}
          />
        </SectionModal>
      )}
      {openSubModal && (
        <SectionModal setOpenModal={setOpenSubModal}>
          <SubBloqModal dataModal={dataModal} setOpenModal={setOpenSubModal} />
        </SectionModal>
      )}
      {openCarouselModal && (
        <SectionModal setOpenModal={setOpenCarouselModal}>
          <CarouselBloqModal
            dataModal={dataModal}
            setOpenModal={setOpenCarouselModal}
          />
        </SectionModal>
      )}
      {openAlertModal && (
        <SectionModal setOpenModal={setOpenAlertModal}>
          <AlertBloqModal
            dataModal={dataModal}
            setOpenModal={setOpenAlertModal}
          />
        </SectionModal>
      )}
    </div>
  )
}

export default HomeTemplate
