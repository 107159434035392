import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MDBCol, MDBRow } from 'mdbreact'
import { IInitialState } from '@store/index'
import SectionItem from './sectionItem'
import { Markup } from 'interweave'
import SubBloque from './subBloque'
import { IMallInfo } from '@features/mall/mall-interfaces'
import BlockDrop from '../../features/sections/elements/dragAndDrop/blockDrop'
import { setHomeEdit } from '@features/sections/sections-actions'

interface IMyProps {
  mall: IMallInfo
  sectionData: any
  setOpenModal: (value: boolean) => void
  setOpenSubModal: (value: boolean) => void
  setDataModal?: any
  letters: number
  primarySections?: number
}

const SectionTemplate: React.FC<IMyProps> = (props) => {
  const {
    sectionData,
    mall,
    setOpenModal,
    setDataModal,
    setOpenSubModal,
    letters,
    primarySections
  } = props
  const dispatch = useDispatch()
  const { homeEdit } = useSelector((state: IInitialState) => state.sections)

  const [switchInput, setSwitchInput] = useState(false)
  const [value, setValue] = useState(sectionData)
  const [tempIndex, setTempIndex]: any = useState(null)

  const getLetter = () => {
    let sections: any = []
    if (homeEdit) {
      sections = homeEdit.config.sections
    }
    const lettersArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
    
    if (sectionData.backgroundWhite) {
      return 'G'
    } else {
      if(sections[0]?.type !== "directory"){
        return lettersArray[letters]
      }else{
        return lettersArray[letters + 1]
      }
      
    }


  }

  const handleChange = (e: any, id: string) => {
    let index = homeEdit?.config.sections.findIndex((dat) => dat.id === id)
    if (index !== undefined) {
      setTempIndex(index)
      let newHomeEdit = homeEdit
      if (newHomeEdit) {
        let newEdit = newHomeEdit.config.sections[index]
        setValue({ ...newEdit, [e.target.name]: e.target.value })
        // newHomeEdit.config.sections[index] = value
      }
      // dispatch(setHomeEdit(newHomeEdit))
    }
  }

  const handleConfirm = (e: any) => {
    e.preventDefault()
    let newHomeEdit = homeEdit
    if (tempIndex !== null) {
      if (newHomeEdit) {
        newHomeEdit.config.sections[tempIndex] = value
      }
      dispatch(setHomeEdit(newHomeEdit))
    }
    setSwitchInput(false)
  }

  const letter = getLetter()
  const fullSize = sectionData.blocks.length % 2 === 0
  

  return (
    <>
      <MDBRow>
        <MDBCol size="2" />
        <MDBCol size="10">
          <div className={`h-100 templateHome_background`}>
            <div className="home-background home-background__2 home-background__special">
              <BlockDrop
                section={JSON.parse(JSON.stringify(sectionData))}
                blockType="special"
              />
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="2">
          <div className={`vertical-line vertical-line__${letter}`}>
            <div className={`letter letter__${letter}`}>{letter}</div>
          </div>
        </MDBCol>
        <MDBCol size="10">
          <div
            className={`h-100 templateHome_background${sectionData.backgroundWhite ? '_end' : ''
              }`}
          >
            <div className="home-background home-background__2">
              <div
                className={`section ${sectionData.backgroundWhite ? 'section__white' : ''
                  }`}
              >
                {switchInput ? (
                  <form className="title" onSubmit={(e) => handleConfirm(e)}>
                    <input
                      className="input-title"
                      type="text"
                      name="title"
                      onChange={(e) => handleChange(e, sectionData.id)}
                      onBlur={(e) => {
                        handleConfirm(e)
                        setSwitchInput(false)
                      }}
                      value={value.title}
                      autoFocus
                    />
                  </form>
                ) : (
                  <div
                    className={
                      sectionData.title && sectionData.title.length > 0
                        ? 'title'
                        : 'title title_empty'
                    }
                    onClick={() => setSwitchInput(true)}
                  >
                    <Markup
                      content={
                        sectionData.title && sectionData.title.length > 0
                          ? sectionData.title
                          : 'Ingresa <u>aquí</u> un título de sección'
                      }
                    />
                  </div>
                )}
                <MDBRow className="grid justify-content-between">


                  {sectionData?.blocks.map((item: any, index: number) => {
                    const fullSizeBloq = index === 0 && !fullSize

                    return (
                      <>
                        <MDBCol
                          key={`colSecItem${item.id}`}
                          className={fullSizeBloq ? 'p-0' : 'p-0 pr-2 col-grid-stretch'}
                          size={fullSizeBloq ? '12' : '6'}
                        >


                          <SectionItem
                            key={`si${item.id}`}
                            itemData={item}
                            backgroundWhite={sectionData.backgroundWhite}
                            style={item.style}
                            mallSelected={mall.mallId}
                            isTemplate={true}
                            setOpenModal={setOpenModal}
                            setDataModal={setDataModal}
                            setOpenSubModal={setOpenSubModal}
                            section={sectionData}
                            fullSizeBloq={fullSizeBloq}
                          />
                          {item?.subBlocks.map((sub: any) => (
                            <SubBloque
                              key={`sub${sub.id}`}
                              itemData={sub}
                              backgroundWhite={sectionData.backgroundWhite}
                              style={item.style}
                              mallSelected={mall.mallId}
                              isLast={
                                item?.subBlocks[item.subBlocks.length - 1].name ===
                                sub.name
                              }
                              setOpenSubModal={setOpenSubModal}
                              setDataModal={setDataModal}
                              bloqId={item.id}
                              section={sectionData}
                            />
                          ))}
                        </MDBCol>
                      </>
                    )
                  })}
                </MDBRow>
                <BlockDrop
                  section={JSON.parse(JSON.stringify(sectionData))}
                  blockType={
                    sectionData.backgroundWhite ? 'secondary' : 'primary'
                  }
                />
              </div>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default SectionTemplate
