import { IAction } from '@commons/common-interfaces'
import {
  SET_MALL,
  SET_MALL_ACTIVE,
  SET_HOME_MALL,
  UPDATE_MALL_ADDRESS,
  UPDATE_MALL_IMAGE,
  UPDATE_MALL_LOGO,
  GET_MALL_FAQS,
  SET_SOCIAL_NETWORKS
} from './mall-actions'
import { IMall } from './mall-interfaces'

const initialState: IMall = {
  info: {
    mallId: 0,
    code: '',
    name: '',
    logo: '',
    image: '',
    direction: '',
    active: false,
    lastUpdated: '0',
    createdAt: '0',
    maps: {
      mainStores: [],
      type: {
        store: {
          venue: 0,
          floors: []
        }
      }
    },
    coordinates: {
      latitude: '0',
      longitude: '0'
    }
  },
  home: [],
  faqs: [],
  socialNetworks: []
}

export const MallReducer = (state: IMall, action: IAction): IMall => {
  state = state ? state : initialState

  switch (action.type) {
    case SET_MALL:
      return {
        ...state,
        info: action.payload
      }
    case SET_MALL_ACTIVE:
      return {
        ...state,
        info: action.payload
      }
    case SET_HOME_MALL:
      return {
        ...state,
        home: action.payload.data
      }
    case UPDATE_MALL_ADDRESS:
      return {
        ...state,
        info: {
          ...state.info,
          direction: action.payload.direction,
          coordinates: {
            latitude: action.payload.latitude,
            longitude: action.payload.longitude
          }
        }
      }
    case UPDATE_MALL_IMAGE:
      return {
        ...state,
        info: {
          ...state.info,
          image: action.payload
        }
      }
    case UPDATE_MALL_LOGO:
      return {
        ...state,
        info: {
          ...state.info,
          logo: action.payload
        }
      }
    case SET_SOCIAL_NETWORKS:
      return {
        ...state,
        socialNetworks: action.payload
      }
    case GET_MALL_FAQS:
      return {
        ...state,
        faqs: action.payload
      }

    default:
      return state
  }
}
