import React, { useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import { Markup } from 'interweave'

import Logo from '@assets/images/arauco-plus.svg'
import bgNotification from '@assets/images/bg-notification.png'
import { Menu } from '@commons/menu'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import MallActive from '@commons/mallActive/mallActive'
import { GrFormClose } from 'react-icons/gr'
import { useHistory } from 'react-router-dom'
import CommonModal from '@commons/commonModal/commonModal'
import { saveIcon, sendNotification } from '../notifications-thunks'
import AlertModal from '@commons/alertModal/alertModal'
import { setAlertModal } from '@commons/common-actions'
import { setNewNotification } from '../notifications-actions'
import Loading from '@commons/loading/Loading'
import { notifi } from 'src/helpers/notification_base'

const PreviewNotification: React.FC = ({ match }: any) => {
  const { notifications, mall, settings } = useSelector(
    (state: IInitialState) => state
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const { params } = match
  const {
    type,
    title,
    description,
    logo,
    icon,
    logoType,
    startDate,
    endDate,
    base64String,
    conditions
  } = notifications.notification

  const [confirmModal, setConfirmModal] = useState(false)
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(setNewNotification({ ...notifi }))
    }
  }, [])

  // const getStartDate = () => {
  //   let startDate = new Date()
  //   startDate.setDate(startDate.getDate())

  //   return startDate.toISOString().split('T')[0]
  // }

  // const getEndDate = () => {
  //   let endDate: any = new Date()
  //   endDate.setDate(endDate.getDate() + 15)

  //   return endDate.toISOString().split('T')[0]
  // }

  const handleConfirmModal = async () => {
    setFetching(true)
    // let startDate = getStartDate()
    // let endDate = getEndDate()
    const mallId = mall.info.mallId

    if (icon) {
      sendNotification(
        title,
        description,
        icon,
        type,
        startDate,
        endDate,
        conditions,
        mallId
      ).then((resolve) => {
        setConfirmModal(false)
        dispatch(setNewNotification({ ...notifi }))
        const msg =
          resolve?.data.code === 200
            ? 'Se ha creado con éxito una notificación'
            : 'Lo siento, vuelva a intentarlo'
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: '',
              title: msg
            }
          })
        )
        setFetching(false)
        dispatch(setNewNotification({ ...notifi }))
        setTimeout(() => {
          history.push(`/editMall/${params.id}/notifications`)
        }, 2000)
      })
    } else {
      setFetching(true)
      await saveIcon(base64String, logoType, mallId).then((resp) => {
        if (resp?.data.statusCode === 200) {
          const img = resp?.data.data.location

          sendNotification(
            title,
            description,
            img,
            type,
            startDate,
            endDate,
            conditions,
            mallId
          ).then((resolve) => {
            setConfirmModal(false)
            dispatch(setNewNotification({ ...notifi }))
            const msg =
              resolve?.data.code === 200
                ? 'Se ha creado con éxito una notificación'
                : 'Lo siento, vuelva a intentarlo'
            dispatch(
              setAlertModal({
                status: true,
                data: {
                  msg: '',
                  title: msg
                }
              })
            )
            setFetching(false)
            setTimeout(() => {
              history.goBack()
            }, 2000)
          })
        }
      })
    }
  }

  const optionsModal = {
    title: 'enviar notificación',
    subtitle: 'esta acción no se podrá revertir',
    motifText: ''
  }

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <MDBRow className="mt-5 mx-0">
          <MDBCol size="3" className="mx-0 px-0">
            <div className="container-logo">
              <img src={Logo} className="mall-logo" alt="logo" />
            </div>
            <Menu />
          </MDBCol>
          <MDBCol size="9">
            <MallActive id={params.id} />
            <div className="newMall_background newMall_background-notification  p-5">
              <MDBRow className="justify-content-between container-title container-title-schedule">
                <MDBCol size="10" className="col-title">
                  <h3 className="title">Previsualización de Notificación</h3>
                </MDBCol>
              </MDBRow>
              <img className="imagen-bg-notification" src={bgNotification} />
              <MDBRow center>
                <MDBCol
                  size="4"
                  className={'notificationUnitaria'}
                  style={{
                    borderTop: '1px solid #C4C4C4',
                    borderBottom: '1px solid #C4C4C4'
                  }}
                >
                  <MDBRow>
                    <MDBCol
                      size="3"
                      className="divImg d-flex align-items-center justify-content-center"
                    >
                      <img alt="null" src={icon || logo} className="img" />
                    </MDBCol>
                    <MDBCol size="7">
                      <p className="title">{title}</p>
                      <p className="description">
                        <Markup content={description} />
                      </p>
                    </MDBCol>
                    <MDBCol size="2" className="text-right closeNoti">
                      <GrFormClose size={30} />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow className="justify-content-center px-4 mt-4">
                <MDBCol size="3" className="pr-2">
                  <button
                    type="button"
                    className="modal_edit_close_button"
                    onClick={history.goBack}
                  >
                    Volver
                  </button>
                </MDBCol>
                <MDBCol size="3" className="pl-2">
                  <button
                    type="button"
                    className="modal_edit_confirm_button"
                    onClick={() => setConfirmModal(true)}
                  >
                    Enviar
                  </button>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCol>
          {confirmModal && (
            <CommonModal
              setOpenModal={setConfirmModal}
              handleConfirmModal={handleConfirmModal}
              options={optionsModal}
            />
          )}
          {settings.alert?.status && <AlertModal />}
        </MDBRow>
      )}
    </>
  )
}
export default PreviewNotification
