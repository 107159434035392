import React, { CSSProperties } from 'react'
import { ISection } from '@features/sections/sections-interface'
import { useDrag } from 'react-dnd'
import { ItemTypes } from './itemTypes'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { SectionsActions } from '@features/sections'
import AlertItem from '@commons/home/alertItem'

const style: CSSProperties = {
  cursor: 'move'
}

export interface IMyProps {
  section: ISection
}

export const AlertDrag: React.FC<IMyProps> = ({ section }) => {
  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const acceptedType = ItemTypes.SPECIAL_BLOCK

  const dispatch = useDispatch()

  const addAlertToHomeEdit = (newSection: ISection, nextSection: ISection) => {
    delete nextSection.dropEffect

    if (homeEdit) {
      if (nextSection.id !== undefined && String(nextSection.id).length > 0) {
        const index = homeEdit.config.sections.findIndex(
          (sectionAux: ISection) =>
            String(sectionAux.id) === String(nextSection.id)
        )
        if (index >= 0) {
          newSection.order = index
          homeEdit.config.sections.splice(
            index,
            0,
            JSON.parse(JSON.stringify(newSection))
          )
        } else {
          newSection.order = homeEdit.config.sections.length - 1
          homeEdit.config.sections.push(newSection)
        }
      } else if (
        nextSection.title !== undefined &&
        String(nextSection.title).length > 0
      ) {
        const index = homeEdit.config.sections.findIndex(
          (sectionAux: ISection) =>
            String(sectionAux.title) === String(nextSection.title)
        )
        newSection.order = index
        homeEdit.config.sections.splice(
          index,
          0,
          JSON.parse(JSON.stringify(newSection))
        )
      }
      dispatch(
        SectionsActions.setHomeEdit(JSON.parse(JSON.stringify(homeEdit)))
      )
    }
  }

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: acceptedType,
      item: section,
      end: (item, monitor) => {
        const sectionResult = monitor.getDropResult<ISection>()
        if (item && sectionResult) {
          addAlertToHomeEdit(item, JSON.parse(JSON.stringify(sectionResult)))
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId()
      })
    }),
    [section, addAlertToHomeEdit]
  )

  const opacity = isDragging ? 0.4 : 1
  return (
    <div ref={drag} role="menuitem" style={{ ...style, opacity }}>
      <AlertItem block={section.blocks[0]} />
    </div>
  )
}
