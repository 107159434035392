import React, { useCallback, useEffect, useState } from 'react'
import { MDBCol, MDBCollapse, MDBCollapseHeader, MDBRow } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import {
  IoIosArrowRoundBack,
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoMdClose
} from 'react-icons/io'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { IBlock, ISection } from '../sections-interface'
import { HomeThunks } from '@features/home'
import { BlockDrag } from './dragAndDrop/blockDrag'
import { SectionsActions, SectionsThunks } from '..'
import { CarouselDrag } from './dragAndDrop/carouselDrag'
import { AlertDrag } from './dragAndDrop/alertDrag'
import { MdModeEdit } from 'react-icons/md'
import { setHomeEdit } from '../sections-actions'
import { AiFillMinusCircle } from 'react-icons/ai'
import CommonModal from '@commons/commonModal/commonModal'
import AlertModal from '@commons/alertModal/alertModal'
import { setAlertModal } from '@commons/common-actions'

interface IMyProps {
  setFetchingPage: (value: boolean) => void
  scheduleId: string
}

const BloqsSection: React.FC<IMyProps> = (props) => {
  const { bloqs } = useSelector((state: IInitialState) => state.general)
  const { info, home } = useSelector((state: IInitialState) => state.mall)
  const { settings, schedules } = useSelector((state: IInitialState) => state)
  const { homeEdit, initialHome } = useSelector(
    (state: IInitialState) => state.sections
  )
  const dispatch = useDispatch()
  const location = useLocation()
  const params: any = useParams()
  const history = useHistory()

  const { primary, secondary, carousel, alert } = bloqs
  const [collapseID, setCollapseID] = useState('')
  const [errorMsg, setErrorMsg] = useState({
    title: false,
    msg: '',
    schedule: false
  })
  const [value, setValue]: any = useState({
    startTime: 0,
    endTime: 0,
    name: ''
  })
  const [fetching, setFetching] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const langSelected = params.lang ? params.lang : 'es'

  const fetchGetMallBloqs = useCallback(async () => {
    setFetching(true)
    await dispatch(HomeThunks.getMallBloqs(setFetching))
    setFetching(false)
  }, [dispatch])

  useEffect(() => {
    fetchGetMallBloqs()
  }, [fetchGetMallBloqs])

  const toggleCollapse = (id: string) =>
    setCollapseID(collapseID !== id ? id : '')

  const cancelChanges = () => {
    if (initialHome) {
      dispatch(
        SectionsActions.setHomeEdit(JSON.parse(JSON.stringify(initialHome)))
      )
      history.push(`/editMall/${info.mallId}`)
    }
  }

  const saveSchedule = async (e: any) => {
    e.preventDefault()
    if (props.scheduleId !== 'isDefault') {
      if (!value.name) {
        setErrorMsg({
          title: true,
          msg: '*Ingrese un nombre para la sección',
          schedule: false
        })
        return
      }
    }
    let mainTienda: any = []
    homeEdit?.config.sections.forEach((element) => {
      let isTrue = element.blocks.findIndex((value) => {
        return value.link === '/MainTienda'
      })
      mainTienda.push(isTrue)
    })
    // let vd = mainTienda.find((item: any) => {
    //   return item >= 0
    // })
    // if (!vd && vd !== 0) {
    //   dispatch(
    //     setAlertModal({
    //       status: true,
    //       data: {
    //         msg: '',
    //         title:
    //           'Debes agregar al menos un bloque tienda para guardar este horario'
    //       }
    //     })
    //   )
    //   return
    // }
    if (parseInt(value.startTime) >= parseInt(value.endTime)) {
      setErrorMsg({
        title: false,
        msg: '*El horario de inicio no puede superar al de finalización',
        schedule: true
      })
      return
    }

    setErrorMsg({ title: false, msg: '', schedule: false })

    const { setFetchingPage } = props

    if (props.scheduleId === 'new') {
      if (homeEdit && home) {
        let newHome: any = home
        newHome.splice(0, 0, homeEdit)
      }
    }
    if (homeEdit && home) {
      if (props.scheduleId === 'isDefault') {
        homeEdit.isDefault = true

        await dispatch(
          SectionsThunks.saveScheduleDefault(
            info.mallId,
            setFetchingPage,
            homeEdit,
            langSelected
          )
        )
        if (props.scheduleId === 'isDefault') {
          history.push(`/editMall/${info.mallId}`)
        } else {
          history.push(`/editMall/${info.mallId}/schedules`)
        }
      } else {
        let newHome = home
        let index = home.findIndex((data) => {
          return homeEdit.id === data.id
        })
        newHome.splice(index, 1, homeEdit)
        let dataToSave = newHome.filter((data) => {
          data.language = langSelected
          return data.isDefault !== true
        })

        await dispatch(
          SectionsThunks.saveSchedule(
            info.mallId,
            setFetchingPage,
            dataToSave,
            langSelected
          )
        )
        if (props.scheduleId === 'isDefault') {
          history.push(`/editMall/${info.mallId}`)
        } else {
          history.push(`/editMall/${info.mallId}/schedules`)
        }
      }
    }
  }

  const handleDeleteSchedule = async () => {
    setOpenModal(false)
    setFetching(true)
    await dispatch(SectionsThunks.deleteSchedule(info.mallId, props.scheduleId))
    setFetching(false)
    history.push(`/editMall/${info.mallId}/schedules`)
  }

  const handleChange = (e: any) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }

  const handleSaveTime = (e?: any) => {
    if (e) {
      e.preventDefault()
    }

    if (homeEdit) {
      let newHomeEdit = homeEdit
      newHomeEdit.startTime = parseInt(value.startTime)
      newHomeEdit.endTime = parseInt(value.endTime)
      newHomeEdit.name = value.name
      dispatch(setHomeEdit(newHomeEdit))
    }
  }
  useEffect(() => {
    handleSaveTime()
  }, [value])

  useEffect(() => {
    if (props.scheduleId === 'new') {
      let state: any = location.state
        ? location.state
        : { startTime: 0, endTime: 0, name: '' }
      if (location.state) {
        setValue({
          startTime: state.startTime * 100,
          endTime: state.endTime * 100,
          name: state.name
        })
      }
    } else {
      if (homeEdit) {
        let newStart = homeEdit.startTime
        let newEnd = homeEdit.endTime
        if (newStart <= 10) {
          newStart = newStart * 100
        }
        if (newEnd <= 10) {
          newEnd = newEnd * 100
        }
        setValue({
          startTime: newStart,
          endTime: newEnd,
          name: homeEdit.name
        })
      }
    }
  }, [homeEdit])

  let primaryBlocks = 0

  return (
    <div className="bloqs-col col-floating">
      {fetching ? (
        <MDBRow style={{ border: '1px dashed #c4c4c4', height: '450px' }} />
      ) : (
        <MDBRow className="h-100 ">
          <MDBCol size="12" className="m-0 p-0">
            <MDBRow className="justify-content-between container-title">
              <MDBCol size="10" className="col-title">
                <h3 className="title">
                  {' '}
                  {props.scheduleId === 'isDefault'
                    ? 'Home Arauco+'
                    : 'Configuración de horarios'}{' '}
                </h3>
              </MDBCol>
              <MDBCol size="2">
                <Link className="btn-close" to={`/editMall/${info.mallId}`}>
                  {props.scheduleId !== 'isDefault' ? (
                    <IoIosArrowRoundBack size={32} className="icon-close" />
                  ) : (
                    <IoMdClose size={32} className="icon-close" />
                  )}
                </Link>
              </MDBCol>
            </MDBRow>
            {props.scheduleId && props.scheduleId !== 'isDefault' && (
              <MDBRow className="row-bloq justify-content-between schedule_edit_block">
                <MDBCol
                  className="bloqs_bg_principal h-100 schedule_edit_block schedule_edit_block_container"
                  size="12"
                >
                  <span
                    className="delete_schedule"
                    onClick={() => setOpenModal(true)}
                  >
                    <AiFillMinusCircle />
                  </span>

                  <MDBRow center>
                    <MDBCol className="schedule_edit_block_time" size="11">
                      <MDBRow>
                        <MDBCol size="3">
                          <select
                            name="startTime"
                            onChange={handleChange}
                            className="select_block_schedule"
                          >
                            <option selected value={value.startTime}>
                              {value.startTime / 100}:00
                            </option>

                            {schedules.times.map((time) => {
                              if (time.hour > 8 && time.hour < 22) {
                                if (time.occupied === false) {
                                  return (
                                    <option
                                      key={time.hour}
                                      value={time.hour * 100}
                                    >
                                      {time.hour}:00
                                    </option>
                                  )
                                } else {
                                  return (
                                    <option
                                      key={time.hour}
                                      value={time.hour * 100}
                                      className="text_color_primary"
                                    >
                                      {time.hour}:00
                                    </option>
                                  )
                                }
                              } else {
                                return ''
                              }
                            })}
                          </select>
                        </MDBCol>

                        <MDBCol size="1" className="letter_schedule">
                          a
                        </MDBCol>

                        <MDBCol size="3">
                          <select
                            onChange={handleChange}
                            name="endTime"
                            className="select_block_schedule"
                          >
                            <option selected value={value.endTime}>
                              {value.endTime / 100}:00
                            </option>

                            {schedules.times.map((time) => {
                              if (time.hour > 8 && time.hour < 22) {
                                if (time.occupied === false) {
                                  return (
                                    <option
                                      key={time.hour}
                                      value={time.hour * 100}
                                    >
                                      {time.hour}:00
                                    </option>
                                  )
                                } else {
                                  return (
                                    <option
                                      key={time.hour}
                                      value={time.hour * 100}
                                      className="text_color_primary"
                                    >
                                      {time.hour}:00
                                    </option>
                                  )
                                }
                              } else {
                                return ''
                              }
                            })}
                          </select>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    {errorMsg.schedule && (
                      <label className="m-0 text-danger text_alert_error">
                        {errorMsg.msg}
                      </label>
                    )}
                    <form className="col-12" onSubmit={handleSaveTime}>
                      <MDBCol
                        className="schedule_edit_block_comment p-0"
                        size="12"
                      >
                        <label className="m-0" htmlFor="comment">
                          Titulo:
                        </label>
                        <input
                          type="text"
                          id="comment"
                          name="name"
                          className="modal_edit_inputs w-100"
                          value={value.name}
                          onChange={handleChange}
                        />
                        <MdModeEdit className="edit_icon_blue" />
                      </MDBCol>
                      {errorMsg.title && (
                        <label className="m-0 text-danger" htmlFor="comment">
                          {errorMsg.msg}
                        </label>
                      )}
                    </form>
                    <MDBCol className="bloqs_edit_block_title" size="12">
                      Reorganiza o reemplaza para este horario los bloques en la
                      vista previa del Home a tu izquierda
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            )}
            {/* ====================== BLOQUE PRIMARIO */}
            <MDBRow className="row-bloq justify-content-between">
              <MDBCol className="bloqs_bg_principal h-100" size="12">
                <MDBCollapseHeader
                  onClick={() => toggleCollapse('primary')}
                  className="bloqs_bg_header"
                  tagClassName="d-flex justify-content-between "
                >
                  <p className="bloqs_title">Bloque Principal</p>
                  {collapseID === 'primary' ? (
                    <IoMdArrowDropup className="arrow_drop active_drop" />
                  ) : (
                    <IoMdArrowDropdown className="arrow_drop " />
                  )}
                </MDBCollapseHeader>
                <MDBCollapse id={'primary'} isOpen={collapseID}>
                  <MDBRow>
                    <MDBCol className="bloqs_section_title" size="8">
                      <div>
                        Arrastra un{' '}
                        <span className="font-weight-bold">
                          bloque principal{' '}
                        </span>
                        hacia la sección{' '}
                        <span className="font-weight-bold">B</span> o{' '}
                        <span className="font-weight-bold">C</span>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  {primary.map((item: ISection, i: number) => {
                    item.blocks[0].collapsible = false
                    let showBlock = true
                    homeEdit?.config.sections.forEach((section: ISection) => {
                      const blockAux = section.blocks.filter(
                        (block: IBlock) => block.style === item.blocks[0].style
                      )
                      if (blockAux.length > 4) {
                        showBlock = false
                        return
                      }
                    })
                    if (showBlock) {
                      primaryBlocks += 1
                    }
                    return (
                      showBlock && (
                        <BlockDrag
                          block={item.blocks[0]}
                          backgroundWhite={item.backgroundWhite}
                          mallId={info.mallId}
                          blockType="primary"
                        />
                      )
                    )
                  })}
                  {primaryBlocks === 0 && (
                    <div className="no-blocks-message">
                      ¡Se asignaron todos los bloques principales disponibles!
                    </div>
                  )}
                </MDBCollapse>
              </MDBCol>
            </MDBRow>

            {/* ====================== BLOQUE SECUNDARIO */}
            <MDBRow className="row-bloq justify-content-between">
              <MDBCol className="bloqs_bg_principal h-100" size="12">
                <MDBCollapseHeader
                  onClick={() => toggleCollapse('secondary')}
                  className="bloqs_bg_header"
                  tagClassName="d-flex justify-content-between "
                >
                  <p className="bloqs_title">Bloque Secundario</p>
                  {collapseID === 'secondary' ? (
                    <IoMdArrowDropup className="arrow_drop active_drop" />
                  ) : (
                    <IoMdArrowDropdown className="arrow_drop " />
                  )}
                </MDBCollapseHeader>
                <MDBCollapse id={'secondary'} isOpen={collapseID}>
                  <MDBRow>
                    <MDBCol className="bloqs_section_title" size="9">
                      <div>
                        Arrastra un{' '}
                        <span className="font-weight-bold">
                          bloque secundario{' '}
                        </span>
                        hacia la sección{' '}
                        <span className="font-weight-bold">D</span>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  {secondary.map((item: ISection, i: number) => {
                    item.blocks[0].collapsible = false
                    let showBlock = true
                    const bgWhite = homeEdit?.config.sections.filter(
                      (section: ISection) => section.backgroundWhite === true
                    )
                    if (bgWhite) {
                      if (bgWhite[0]?.blocks.length > 4) {
                        showBlock = false
                        return
                      }

                      if (showBlock) {
                        return (
                          <BlockDrag
                            block={item.blocks[0]}
                            backgroundWhite={item.backgroundWhite}
                            mallId={info.mallId}
                            blockType="secundary"
                          />
                        )
                      } else {
                        return (
                          <div className="no-blocks-message">
                            ¡Se asignaron todos los bloques Secundarios
                            disponibles!
                          </div>
                        )
                      }
                    }
                  })}
                </MDBCollapse>
              </MDBCol>
            </MDBRow>

            {/* ====================== BLOQUE CARRUSEL */}
            <MDBRow className="row-bloq justify-content-between">
              <MDBCol className="bloqs_bg_principal h-100" size="12">
                <MDBCollapseHeader
                  onClick={() => toggleCollapse('carousel')}
                  className="bloqs_bg_header"
                  tagClassName="d-flex justify-content-between "
                >
                  <p className="bloqs_title">Bloque Carrusel</p>
                  {collapseID === 'carousel' ? (
                    <IoMdArrowDropup className="arrow_drop active_drop" />
                  ) : (
                    <IoMdArrowDropdown className="arrow_drop " />
                  )}
                </MDBCollapseHeader>
                <MDBCollapse id={'carousel'} isOpen={collapseID}>
                  <MDBRow>
                    <MDBCol className="bloqs_section_title" size="10">
                      <div>
                        Arrastra un{' '}
                        <span className="font-weight-bold">
                          bloque de carrusel{' '}
                        </span>
                        entre las secciones{' '}
                        <span className="font-weight-bold">A y B</span>,
                        <span className="font-weight-bold"> B y C</span> o{' '}
                        <span className="font-weight-bold"> C y D</span>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  {carousel.map((item: ISection, i: number) => (
                    <MDBRow key={i}>
                      <CarouselDrag />
                    </MDBRow>
                  ))}
                </MDBCollapse>
              </MDBCol>
            </MDBRow>

            {/* ====================== BLOQUE ALERTAS */}
            <MDBRow className="row-bloq justify-content-between">
              <MDBCol className="bloqs_bg_principal h-100" size="12">
                <MDBCollapseHeader
                  onClick={() => toggleCollapse('alert')}
                  className="bloqs_bg_header"
                  tagClassName="d-flex justify-content-between "
                >
                  <p className="bloqs_title">Bloque Alertas</p>
                  {collapseID === 'alert' ? (
                    <IoMdArrowDropup className="arrow_drop active_drop" />
                  ) : (
                    <IoMdArrowDropdown className="arrow_drop " />
                  )}
                </MDBCollapseHeader>
                <MDBCollapse id={'alert'} isOpen={collapseID}>
                  <MDBRow>
                    <MDBCol className="bloqs_section_title" size="10">
                      <div>
                        Arrastra un{' '}
                        <span className="font-weight-bold">
                          bloque de alerta{' '}
                        </span>
                        entre las secciones{' '}
                        <span className="font-weight-bold">A y B</span>,
                        <span className="font-weight-bold"> B y C</span> o{' '}
                        <span className="font-weight-bold"> C y D</span>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  {alert.map((item: ISection, i: number) => {
                    item.blocks[0].description = 'Bloque de alerta'
                    return <AlertDrag section={item} />
                  })}
                </MDBCollapse>
              </MDBCol>
            </MDBRow>
            <MDBRow className="justify-content-between">
              <MDBCol size="6">
                <button className="section_closeButton" onClick={cancelChanges}>
                  Cancelar
                </button>
              </MDBCol>
              <MDBCol size="6">
                <button
                  className="section_confirmButton"
                  onClick={saveSchedule}
                >
                  Guardar
                </button>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      )}
      {openModal && (
        <CommonModal
          setOpenModal={setOpenModal}
          handleConfirmModal={handleDeleteSchedule}
          options={{
            title: ' eliminar ',
            subtitle: 'este horario?',
            motifText: ''
          }}
        />
      )}
      {settings.alert?.status && <AlertModal />}
    </div>
  )
}

export default BloqsSection
