import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MDBCol,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions
} from 'mdbreact'

import { IInitialState } from '@store/index'
import UploadImage from 'src/hooks/uploadImage'
import { setNewNotification } from '../notifications-actions'
import { NotificationsService } from '../notifications-service'
import { Link, useHistory } from 'react-router-dom'
import { notifi } from 'src/helpers/notification_base'
import { IoMdClose } from 'react-icons/io'
import { INotification, ITypeNotification } from '../notifications-interface'
import Loading from '@commons/loading/Loading'
import { getYesterday } from 'src/hooks/getYesterday'

interface IMyProps {
  match: any
}
const NotificationsContent: React.FC<IMyProps> = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const country = process.env.REACT_APP_COUNTRY || 'Chile'
  const { notifications, mall } = useSelector((state: IInitialState) => state)
  const [data, setData] = useState<INotification>({
    title: '',
    type: 'Notificación general - on demand',
    description: '',
    logo: '',
    icon: '',
    base64String: '',
    logoType: '',
    startDate: '',
    endDate: '',
    conditions: {
      interests: [],
      hasProfileComplete: null,
      hasLicensePlate: null,
      channels: []
    }
  })
  const [validate, setValidate] = useState({
    status: false,
    title: '',
    description: '',
    logo: '',
    date: ''
  })

  const [interestList, setInterestList] = useState([])
  const [notificationsType, setNotificationsType] = useState<
    ITypeNotification[]
  >([])
  const [fetching, setFetching] = useState(false)
  const [preSelect, setPreSelect] = useState(true)
  const [onlyPlate, setOnlyPlate] = useState(false)

  const getInterest = async () => {
    setFetching(true)
    let { data, httpCode } = await NotificationsService.getListInterest()

    if (httpCode === 200) {
      setInterestList(data.data)
    }
    setFetching(false)
  }

  const getTypeNotification = async () => {
    let {
      data,
      httpCode
    } = await NotificationsService.getListTypeNotifications()

    if (httpCode === 200) {
      setNotificationsType(data.data)
    }
  }

  useEffect(() => {
    setData(notifications.notification)
    getInterest()
    getTypeNotification()
  }, [])

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const handleSelect = (value: any) => {
    if (value[0] === 'CUSTOMER_NOTIFICACION_ON_MALL') {
      setOnlyPlate(true)
      setData({
        ...data,
        type: value[0],
        conditions: {
          ...data.conditions,
          hasLicensePlate: true,
          hasProfileComplete: null
        }
      })
    } else if (value[0] === 'NOTIFICATION_ON_DEMAND') {
      setData({
        ...data,
        type: value[0],
        conditions: {
          ...data.conditions,
          hasLicensePlate: null,
          hasProfileComplete: null
        }
      })
    } else if (onlyPlate === true) {
      setOnlyPlate(false)
      setData({
        ...data,
        type: value[0],
        conditions: {
          ...data.conditions,
          hasLicensePlate: null,
          hasProfileComplete: null
        }
      })
    } else {
      setData({
        ...data,
        type: value[0]
      })
    }
  }

  const handleIcon = (value: string) => {
    setData({ ...data, icon: value, logo: '', base64String: '' })
  }

  const handleDelIcon = () => {
    setData({ ...data, icon: '' })
  }

  const handleChecks = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const newArray: any = data.conditions.interests
    const indexItem = data.conditions.interests.findIndex((data) => {
      return data === value
    })
    if (indexItem >= 0) {
      const resultFilter = data.conditions.interests.filter((data) => {
        return data !== value
      })
      setData({
        ...data,
        conditions: { ...data.conditions, interests: resultFilter }
      })
    } else {
      newArray.push(value)
      setData({
        ...data,
        conditions: { ...data.conditions, interests: newArray }
      })
    }
  }

  const handleControl = (valid: boolean) => {
    let control = {
      status: false,
      title: '',
      description: '',
      logo: '',
      date: ''
    }
    if (!data.title) {
      control = {
        ...control,
        status: true,
        title: '*debe contener un titulo'
      }
      valid = false
    }
    if (!data.description) {
      control = {
        ...control,
        status: true,
        description: '*debe contener un mensaje'
      }
      valid = false
    }
    if (!data.icon && !data.logo) {
      control = {
        ...control,
        status: true,
        logo: '*debe contener un logo o icono'
      }
      valid = false
    }
    if (!data.startDate || !data.endDate) {
      control = {
        ...control,
        status: true,
        date: '*debe contener una fecha de inicio y fin'
      }
      valid = false
    }

    setValidate(control)
    return valid
  }

  const handlePreview = () => {
    let valid = true
    const respValid = handleControl(valid)

    if (respValid) {
      dispatch(setNewNotification(data))
      history.push(match.url + '/preview')
    }
  }

  const handleStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setData({ ...data, startDate: value })
  }
  const handleEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setData({ ...data, endDate: value })
  }

  const handleCloseNotification = () => {
    dispatch(setNewNotification({ ...notifi }))
    setData({ ...notifi })
  }

  const addAllInterest = () => {
    if (data.conditions.interests.length === interestList.length) {
      setData({
        ...data,
        conditions: { ...data.conditions, interests: [] }
      })
    } else {
      setData({
        ...data,
        conditions: { ...data.conditions, interests: interestList }
      })
    }
  }

  const handleRadioAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setPreSelect(false)
    setOnlyPlate(false)

    if (value === 'todas') {
      setData({
        ...data,
        conditions: {
          ...data.conditions,
          hasLicensePlate: null,
          hasProfileComplete: null
        }
      })
    }
    if (value === 'license') {
      setData({
        ...data,
        conditions: {
          ...data.conditions,
          hasLicensePlate: true,
          hasProfileComplete: null
        }
      })
    }
    if (value === 'noLicense') {
      setData({
        ...data,
        conditions: {
          ...data.conditions,
          hasLicensePlate: false,
          hasProfileComplete: null
        }
      })
    }
    if (value === 'noProfile') {
      setData({
        ...data,
        conditions: {
          ...data.conditions,
          hasLicensePlate: null,
          hasProfileComplete: false
        }
      })
    }
  }

  const iconsNotif = [
    {
      value:
        'https://api-image-admin-bucket-dev.s3.us-east-1.amazonaws.com/assets/icons/checkgreen.svg'
    },
    {
      value:
        'https://api-image-admin-bucket-dev.s3.us-east-1.amazonaws.com/assets/icons/infoblue.svg'
    },
    {
      value:
        'https://api-image-admin-bucket-dev.s3.us-east-1.amazonaws.com/assets/icons/warningorange.svg'
    },
    {
      value:
        'https://api-image-admin-bucket-dev.s3.us-east-1.amazonaws.com/assets/icons/minusred.svg'
    }
  ]

  return (
    <>
      {fetching ? (
        <div className="loaderPositionNotification">
          <Loading />
        </div>
      ) : (
        <>
          <MDBRow>
            <MDBCol className="no-lateral-padding margin_schedule" size="6">
              <MDBRow className="justify-content-between container-title-schedule">
                <MDBCol size="10" className="col-title">
                  <h3 className="title">Añadir Nueva Notificación</h3>
                </MDBCol>
              </MDBRow>

              <MDBRow className="justify-content-between ">
                <MDBCol size="12">
                  <div className="label_title_text  mb-3">
                    Tipo de notificación
                  </div>
                  <MDBSelect
                    label="Notificación general - on demand"
                    getValue={(value) => handleSelect(value)}
                    className="modal_edit_select"
                  >
                    <MDBSelectInput disabled selected="Selecciona un tipo" />
                    <MDBSelectOptions>
                      <MDBSelectOption disabled>
                        Selecciona un tipo
                      </MDBSelectOption>
                      {notificationsType?.map((data, index) => (
                        <MDBSelectOption key={index} value={data.code}>
                          {data.description}
                        </MDBSelectOption>
                      ))}
                    </MDBSelectOptions>
                  </MDBSelect>
                </MDBCol>

                <MDBCol size="12">
                  <input
                    type="text"
                    id="Title"
                    name="title"
                    className="edit_inputs w-100"
                    value={data?.title}
                    placeholder="Titulo de la notificación"
                    onChange={handleChange}
                  />
                  {validate.status && validate.title && (
                    <label
                      className="text-danger validate_notification_text"
                      htmlFor="Title"
                    >
                      {validate.title}
                    </label>
                  )}
                </MDBCol>

                <MDBCol size="12">
                  <textarea
                    id="textarea"
                    name="description"
                    className="edit_inputs textarea_notification w-100"
                    value={data?.description}
                    placeholder="Mensaje/Body"
                    onChange={handleChange}
                  />
                  {validate.status && validate.description && (
                    <label
                      className="text-danger validate_notification_text"
                      htmlFor="Title"
                    >
                      {validate.description}
                    </label>
                  )}
                </MDBCol>
              </MDBRow>

              <UploadImage setData={setData} data={data} />

              <div className="label_title_text  mt-3">
                Iconos de notificación
              </div>
              <MDBRow className="mt-4">
                {iconsNotif.map((icon) => {
                  if (data.icon === icon.value) {
                    return (
                      <MDBCol
                        size="2"
                        className="box_icon_notification checked_notification"
                        onClick={handleDelIcon}
                      >
                        <img src={icon.value} alt="imagen iconos" />
                      </MDBCol>
                    )
                  }
                  return (
                    <MDBCol
                      size="2"
                      className="box_icon_notification"
                      onClick={() => handleIcon(icon.value)}
                    >
                      <img src={icon.value} alt="imagen iconos" />
                    </MDBCol>
                  )
                })}
                {validate.status && validate.logo && (
                  <label className="text-danger ml-1" htmlFor="Title">
                    {validate.logo}
                  </label>
                )}
              </MDBRow>
            </MDBCol>
            <MDBCol className="newMall_Preview no-lateral-padding">
              <MDBRow className="">
                <MDBCol size="12">
                  <Link
                    className="btn-close mr-4 mt-4"
                    to={`/editMall/${mall.info.mallId}`}
                  >
                    <IoMdClose size={32} className="icon-close" />
                  </Link>
                </MDBCol>
              </MDBRow>
              {/* ==========RADIO  */}
              <MDBRow className="justify-content-between title-preview">
                <MDBCol size="10" className="col-title mb-2">
                  <div className="label_title_text ">Tipo de usuarios</div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="">
                <MDBCol size="6" className="">
                  <div className="form-check input_notification_radio">
                    {preSelect ? (
                      <input
                        className="input_notification_radio"
                        type="radio"
                        value="todas"
                        id="flexCheckDefault"
                        name="radioEvents"
                        onChange={handleRadioAll}
                        checked={true}
                        disabled={onlyPlate ? true : false}
                      />
                    ) : (
                      <input
                        className="input_notification_radio"
                        type="radio"
                        value="todas"
                        id="flexCheckDefault"
                        name="radioEvents"
                        onChange={handleRadioAll}
                        disabled={onlyPlate ? true : false}
                      />
                    )}

                    <label
                      className="form-check-label ml-2"
                      htmlFor="flexCheckDefault"
                    >
                      Todos
                    </label>
                  </div>
                </MDBCol>
                <MDBCol
                  size="6"
                  className={country === 'Chile' ? '' : 'd-none'}
                >
                  <div className="form-check input_notification_radio">
                    {onlyPlate ? (
                      <input
                        className="input_notification_radio"
                        type="radio"
                        value="license"
                        id="flexCheckDefault"
                        name="radioEvents"
                        onChange={handleRadioAll}
                        checked={true}
                      />
                    ) : (
                      <input
                        className="input_notification_radio"
                        type="radio"
                        value="license"
                        id="flexCheckDefault"
                        name="radioEvents"
                        onChange={handleRadioAll}
                      />
                    )}

                    <label
                      className="form-check-label ml-2"
                      htmlFor="flexCheckDefault"
                    >
                      Con patente
                    </label>
                  </div>
                </MDBCol>
                <MDBCol size="6" className="">
                  <div className="form-check input_notification_radio">
                    <input
                      className="input_notification_radio"
                      type="radio"
                      value="noProfile"
                      id="flexCheckDefault"
                      name="radioEvents"
                      onChange={handleRadioAll}
                      disabled={onlyPlate ? true : false}
                    />
                    <label
                      className="form-check-label ml-2"
                      htmlFor="flexCheckDefault"
                    >
                      Perfil incompleto
                    </label>
                  </div>
                </MDBCol>
                <MDBCol
                  size="6"
                  className={country === 'Chile' ? '' : 'd-none'}
                >
                  <div className="form-check input_notification_radio">
                    <input
                      className="input_notification_radio"
                      type="radio"
                      value="noLicense"
                      id="flexCheckDefault"
                      name="radioEvents"
                      onChange={handleRadioAll}
                      disabled={onlyPlate ? true : false}
                    />
                    <label
                      className="form-check-label ml-2"
                      htmlFor="flexCheckDefault"
                    >
                      Sin patente
                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
              {/*========== DATE  */}
              <MDBRow className="mt-4 rowDate">
                <MDBCol size="10" className="col-title mb-2">
                  <div className="label_title_text ">Vigencia</div>
                </MDBCol>
                <MDBCol size="6" className="">
                  <input
                    className="inputDate"
                    type="date"
                    onChange={handleStartDate}
                    min={getYesterday()}
                  />
                </MDBCol>

                <span className="aspan">a</span>
                <MDBCol size="6" className="">
                  <input
                    className="inputDate"
                    type="date"
                    onChange={handleEndDate}
                    min={data.startDate}
                    disabled={data.startDate === ''}
                  />
                </MDBCol>
              </MDBRow>

              {validate.status && validate.date && (
                <label
                  className="text-danger validate_notification_text mt-4"
                  htmlFor="Title"
                >
                  {validate.date}
                </label>
              )}

              {/*========== CHECK  */}
              <MDBRow className="justify-content-between mt-4">
                <MDBCol size="10" className="col-title mb-2">
                  <div className="label_title_text ">Intereses</div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="">
                <MDBCol size="7" className="col-title">
                  <button
                    type="button"
                    className="modal_edit_close_button botonAddAllNotification"
                    onClick={addAllInterest}
                  >
                    {data.conditions.interests.length === interestList.length
                      ? 'deseleccionar todo'
                      : 'Seleccionar todo'}
                  </button>
                </MDBCol>
              </MDBRow>
              <MDBRow className="">
                {interestList.map((value) => {
                  let exist = data.conditions.interests.find(
                    (data) => data === value
                  )
                  return (
                    <MDBCol size="6" key={value} className="">
                      <div className="form-check input_notification_check">
                        <input
                          className="input_notification_check"
                          type="checkbox"
                          value={value}
                          id={value}
                          name={value}
                          onChange={handleChecks}
                          checked={exist ? true : false}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor={value}
                        >
                          {value}
                        </label>
                      </div>
                    </MDBCol>
                  )
                })}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow className="justify-content-center px-4 mt-4">
            <MDBCol size="3" className="pr-2">
              <button
                type="button"
                className="modal_edit_close_button"
                onClick={handleCloseNotification}
              >
                CANCELAR
              </button>
            </MDBCol>
            <MDBCol size="3" className="pl-2">
              <button
                type="button"
                className="modal_edit_confirm_button"
                onClick={handlePreview}
              >
                PREVISUALIZAR
              </button>
            </MDBCol>
          </MDBRow>
        </>
      )}
    </>
  )
}

export default NotificationsContent
