import CustomSwitch from '@commons/customSwitch/customSwitch'
import {
  MDBCol,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions
} from 'mdbreact'

import React, { useEffect, useState } from 'react'
import AlertItem from '@commons/home/alertItem'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { SectionsActions, SectionsThunks } from '@features/sections'
import Loading from '@commons/loading/Loading'

interface IMyProps {
  dataModal?: any
  setOpenModal: (value: boolean) => void
}
const AlertBloqModal: React.FC<IMyProps> = ({ dataModal, setOpenModal }) => {
  const { section } = dataModal
  const dispatch = useDispatch()
  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const sections = homeEdit ? homeEdit.config.sections : []
  const [fetching, setFetching] = useState(false)

  const optionsLinks: any = process.env.REACT_APP_INTERNAL_LINKS
  const [toggle, setToggle] = useState(false)

  const [data, setData] = useState({
    backgroundUrl: '',
    link: '',
    internalLink: false,
    order: 0,
    description: 'Bloque Alerta'
  })

  useEffect(() => {
    if (section) {
      let data = section.blocks[0]
      data.backgroundUrl =
        'https://api-image-admin-bucket-dev.s3.amazonaws.com/assets/fondoAlertDefault.png'
      data.internalLink ? setToggle(false) : setToggle(true)
      setData(data)
    }
  }, [section])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    ext?: boolean
  ) => {
    const { name, value } = e.target
    if (ext) {
      setData({
        ...data,
        [name]: value
      })
    } else {
      setData({
        ...data,
        internalLink: false,
        [name]: value
      })
    }
  }

  const updateLogo = (logo: any) => {
    let newData = data
    newData = {
      ...newData,
      backgroundUrl: logo
    }
    setData({ ...newData })
  }

  const handleAddImage = async (e: any) => {
    const file = e.target.files[0]
    // const logo: string = URL.createObjectURL(file)
    const reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = async function () {
      const base64String: any = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '')
      dispatch(SectionsThunks.getImageUrl(base64String, file.type, updateLogo))
    }
  }

  const handleChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToggle((prevState) => !prevState)
  }
  const handleSelectLinks = (value: any) => {
    let newData = data
    newData = {
      ...newData,
      internalLink: true,
      link: value[0]
    }
    setData({ ...newData })
  }

  const handleEdit = () => {
    const sectionEdit = sections.findIndex((sect) => sect.id === section.id)
    let newBlocks = section
    if (!data.internalLink) {
      data.link = data.link.replace('https://', '')
      data.link = data.link.replace('http://', '')
      data.link = `https://${data.link}`
    }
    newBlocks.blocks[0] = data
    sections[sectionEdit] = newBlocks
  }

  const handleConfirmModal = (e: any) => {
    e.preventDefault()
    handleEdit()
    if (homeEdit) {
      dispatch(SectionsActions.setHomeEdit(homeEdit))
    }

    setOpenModal(false)
  }
  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <>
          <MDBRow className="modal_section_title_box">
            <MDBCol size="12">
              <h3 className="modal_section_title">
                Agregando bloque de alerta:
              </h3>
            </MDBCol>
            <MDBCol size="12">
              {/* <p className="modal_section_section">Sección A, B o C</p> */}
            </MDBCol>
          </MDBRow>
          <div className="modal-body modal_body p-0 m-0">
            <div>
              <div className="bloqs_modal_sectionItem_container_alert">
                <div className="bloqs_modal_sectionItem_outline_alert">
                  <MDBRow className="h-100">
                    <MDBCol className="bloqs_sectionItem_alert_container_modal">
                      <AlertItem block={data} />
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
              <MDBRow center className="px-3">
                <MDBCol size="11" className="modal_button_add_alert">
                  <label
                    htmlFor="inputButtonCarousel"
                    className="updateImage_bluebutton m-0"
                  >
                    Selecciona un archivo (opcional)
                  </label>
                  <input
                    id="inputButtonCarousel"
                    type="file"
                    className="modal_Button_input"
                    onChange={handleAddImage}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow center className="modal_edit_container_inputs px-3">
                <MDBCol size="12">
                  <input
                    type="description"
                    id="description"
                    name="description"
                    className="modal_edit_inputs w-100"
                    value={data.description}
                    onChange={(e) => handleChange(e, true)}
                  />
                </MDBCol>
                <MDBCol size="8" className="px-0">
                  {toggle ? (
                    <input
                      type="text"
                      id="link"
                      name="link"
                      className="modal_edit_inputs w-100"
                      value={data.link}
                      onChange={(e) => handleChange(e, false)}
                      placeholder={'ingrese el link'}
                    />
                  ) : (
                    <MDBSelect
                      label={data.link ? data.link : 'Ingrese un link'}
                      getValue={(value) => handleSelectLinks(value)}
                      className="modal_edit_select select_active"
                    >
                      <MDBSelectInput
                        selected={data.link ? data.link : 'Ingrese un link'}
                      />
                      <MDBSelectOptions>
                        <MDBSelectOption disabled>
                          Selecciona Link
                        </MDBSelectOption>
                        {optionsLinks &&
                          JSON.parse(optionsLinks).map(
                            (data: any, index: number) => (
                              <MDBSelectOption key={index} value={data.linkUrl}>
                                {data.linkUrl}
                              </MDBSelectOption>
                            )
                          )}
                      </MDBSelectOptions>
                    </MDBSelect>
                  )}
                </MDBCol>
                <MDBCol size="3" className="modal_edit_switch">
                  <CustomSwitch
                    id="linkToggle"
                    name="linkToggle"
                    checked={toggle}
                    onChange={handleChangeSwitch}
                    optionLabels={['Ext', 'Int']}
                    small={true}
                  />
                </MDBCol>
                <MDBCol size="12" className="modal_edit_carousel_text">
                  *La imagen debe ser de 834 x 130 pixeles, tal como se
                  especifica en el “Manual de creación de imagen para Carrusel”.
                  .jpg o .png, 100 kb como máximo.
                </MDBCol>
              </MDBRow>
            </div>
          </div>

          <div className="modal-footer justify-content-center">
            <MDBCol size="5">
              <button
                type="button"
                className="modal_closeButton"
                onClick={() => setOpenModal(false)}
              >
                Cancelar
              </button>
            </MDBCol>
            <MDBCol size="5">
              <button
                type="button"
                className="modal_confirmButton"
                onClick={(e) => handleConfirmModal(e)}
              >
                Guardar
              </button>
            </MDBCol>
          </div>
        </>
      )}
    </>
  )
}

export default AlertBloqModal
