import React, { useEffect, useState } from 'react'
import { Markup } from 'interweave'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { RiPencilLine } from 'react-icons/ri'
import { FiMinus } from 'react-icons/fi'
import './carouselSection.scss'
import CommonModal from '@commons/commonModal/commonModal'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { ISection } from '@features/sections/sections-interface'
import { SectionsActions } from '@features/sections'
import { setModal } from '@commons/common-actions'

interface IMyProps {
  section: any
  isTemplate?: boolean
  setDataModal?: (value: any) => void
  setOpenCarouselModal?: (value: boolean) => void
}

const CarouselSection: React.FC<IMyProps> = (props) => {
  const { section, isTemplate, setDataModal, setOpenCarouselModal } = props
  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const { modalOpen } = useSelector((state: IInitialState) => state.settings)

  const [openModal, setOpenModal] = useState(false)

  const dispatch = useDispatch()

  const options = {
    title: 'eliminar',
    subtitle: `el carrusel?`,
    motifText: ''
  }

  const handleConfirmDeleteCarousel = () => {
    if (homeEdit) {
      const index = homeEdit.config.sections.findIndex(
        (sectionHome: ISection) => sectionHome.id === section.id
      )
      if (index !== undefined) {
        homeEdit.config.sections.splice(index, 1)
      }
      dispatch(SectionsActions.setHomeEdit(homeEdit))
    }
    setOpenModal(false)
  }

  useEffect(() => {
    if (modalOpen.status) {
      openOnLoad()
      dispatch(setModal({ status: false }))
    }
  }, [])

  const openOnLoad = () => {
    setDataModal &&
      setDataModal({
        section
      })
    setOpenCarouselModal && setOpenCarouselModal(true)
  }

  return (
    <div className="carousel-section">
      {section.title && section.title.length > 0 && (
        <div className="title">
          <Markup content={section.title} />
        </div>
      )}
      <Carousel
        autoPlay
        infiniteLoop
        interval={5000}
        showThumbs={false}
        showIndicators
        showArrows={false}
        showStatus={false}
        dynamicHeight
      >
        {section.blocks.map((block: any) => {
          // let img = block.backgroundUrl.replace(/blob:/g, '')
          return (
            <div key={block.id}>
              <img src={block.backgroundUrl} alt="" />
            </div>
          )
        })}
      </Carousel>
      {isTemplate && (
        <>
          <span className="edit-carousel">
            <RiPencilLine
              className="icon-edit"
              onClick={() => {
                setDataModal &&
                  setDataModal({
                    section
                  })
                setOpenCarouselModal && setOpenCarouselModal(true)
              }}
            />
          </span>
          <span className="delete-carousel" onClick={() => setOpenModal(true)}>
            <FiMinus className="icon-delete" />
          </span>
          {openModal && (
            <CommonModal
              setOpenModal={setOpenModal}
              handleConfirmModal={handleConfirmDeleteCarousel}
              options={options}
            />
          )}
        </>
      )}
    </div>
  )
}

export default CarouselSection
