import React, { useState } from 'react'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact'
import { LoginThunks } from './index'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Loading from '@commons/loading/Loading'
import Logo from '@assets/images/arauco-plus.svg'

const LoginPage: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [value, setValue] = useState({ email: '', password: '' })
  const [fetchingPage, setFetchingPage] = useState(false)
  const [error, setError] = useState({
    msgEmail: '',
    msgPassword: ''
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }

  const handleConfirm = async () => {
    if (value.email === '') {
      setError({
        ...error,
        msgEmail: 'Correo inválido. Contactar al administrador.'
      })
      return
    }
    if (value.password === '') {
      setError({
        ...error,
        msgPassword:
          'Contraseña errónea, si has olvidado tu contraseña contactar al administrador.'
      })
      return
    }

    setFetchingPage(true)
    await dispatch(LoginThunks.Login(value, setError, history))
    setFetchingPage(false)
  }

  return (
    <>
      {fetchingPage ? (
        <Loading />
      ) : (
        <MDBContainer className="login">
          <MDBRow className="h-100 justify-content-center align-items-center">
            <MDBCol size="12">
              <MDBRow className="login_container">
                <MDBCol size="6">
                  <MDBRow className="row-logo-login">
                    <MDBCol size="12" className="text-center">
                      <p className="malls_prev_admin">ADMINISTRADOR</p>
                    </MDBCol>
                    <MDBCol size="12" className="text-center">
                      <img src={Logo} className="malls_prev_logo" alt="logo" />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol size="6">
                  <MDBRow center>
                    <MDBCol size="12" className="text-center text_login">
                      <p>
                        Bienvenido a <span>Arauco+</span>
                      </p>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow center className="container_input_login">
                    <MDBCol size="8" className="col_input_login">
                      <label
                        className={error.msgEmail ? ' label_login_error' : ''}
                      >
                        Email
                      </label>
                      <input
                        onChange={handleChange}
                        type="email"
                        name="email"
                        className={
                          error.msgEmail
                            ? 'input_login input_login_error'
                            : 'input_login'
                        }
                      />
                      {error.msgEmail && (
                        <span className="span_login_error">
                          {error.msgEmail}
                        </span>
                      )}
                    </MDBCol>
                    <MDBCol size="8" className="col_input_login">
                      <label
                        className={
                          error.msgPassword ? ' label_login_error' : ''
                        }
                      >
                        Contraseña
                      </label>
                      <input
                        onChange={handleChange}
                        type="password"
                        name="password"
                        className={
                          error.msgPassword
                            ? 'input_login input_login_error'
                            : 'input_login'
                        }
                      />
                      {error.msgPassword && (
                        <span className="span_login_error">
                          {error.msgPassword}
                        </span>
                      )}
                    </MDBCol>
                    <MDBCol size="8">
                      <button onClick={handleConfirm} className="button_login">
                        Iniciar sesión
                      </button>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )}
    </>
  )
}

export default LoginPage
