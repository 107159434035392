export const getYesterday = () => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate())
  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0')
  }

  function formatDate(date: Date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-')
  }

  return formatDate(yesterday)
}
