import { MDBCol, MDBRow } from 'mdbreact'
import React from 'react'
import Switch from 'react-switch'
import './inputSwitch.scss'

interface IMyProps {
  id: string
  name: string
  checked: boolean
  onChange: (e: any) => void
  optionLabels: [string, string]
  disabled?: boolean
  small?: boolean
}

const InputSwitch: React.FC<IMyProps> = ({
  id,
  name,
  checked,
  onChange,
  optionLabels,
  small,
  disabled
}) => {
  return (
    <div className="switch">
      <MDBRow className="no-lateral-padding">
        <MDBCol size="7" className="no-lateral-padding">
          <span className="switch-label">
            {checked ? optionLabels[0] : optionLabels[1]}
          </span>
        </MDBCol>
        <MDBCol size="5" className="no-lateral-padding">
          <Switch
            onChange={onChange}
            checked={checked}
            id={id}
            height={40}
            width={66}
            handleDiameter={24}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor="#c4c4c4"
            onColor="#00379b"
            offHandleColor="#c4c4c4"
            onHandleColor="#ffffff"
            className={`${checked ? '' : 'local-switch'}`}
            disabled={disabled}
          />
        </MDBCol>
      </MDBRow>
    </div>
  )
}

export default InputSwitch
