import React, { useCallback, useEffect, useState } from 'react'
import {
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBCollapse,
  MDBCollapseHeader
} from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '@commons/loading/Loading'
import { MallThunks } from '..'
import { IInitialState } from '@store/index'
import CommonModal from '@commons/commonModal/commonModal'
import { IoMdClose } from 'react-icons/io'
import { BiMinusCircle } from 'react-icons/bi'
import CustomSwitch from '@commons/customSwitch/customSwitch'
import { Markup } from 'interweave'
import { IFaq } from '../mall-interfaces'
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go'
import { setAlertModal } from '@commons/common-actions'

interface IMyProps {
  setSelectView: any
  mall: object
  setOptions: any
  options: any
  selectView: string
}

const FaqPage: React.FC<IMyProps> = (props) => {
  const { faqs, info } = useSelector((state: IInitialState) => state.mall)
  const [fetching, setFetching] = useState(false)
  const [isEditing, setIsEditing] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [switchToggle, setSwitchToggle] = useState(false)
  const [idToDelete, setIdToDelete] = useState(0)
  const [collapseID, setCollapseID] = useState('')
  const [value, setValue] = useState({
    question: '',
    answer: '',
    id: 0,
    order: 0
  })
  const [questions, setQuestions] = useState([
    {
      question: '',
      answer: '',
      id: 0,
      order: 0,
      mallId: 0
    }
  ])
  const dispatch = useDispatch()
  const fetchGetMallFaqs = useCallback(async () => {
    setFetching(true)
    await dispatch(MallThunks.getMallFaqs())
    setFetching(false)
  }, [dispatch])

  useEffect(() => {
    fetchGetMallFaqs()
  }, [fetchGetMallFaqs])

  useEffect(() => {
    setQuestions(faqs ? faqs : [])
  }, [faqs])

  const setActive = () => {
    const position = props.options.findIndex(
      (value: any) => value.id === props.selectView
    )
    let newArray = props.options
    for (let i = 0; i < props.options.length; i++) {
      const element = props.options[i]
      if (element.id === props.selectView) {
        newArray[position].finished = true
      }
    }

    return newArray
  }

  const toggleCollapse = (id: string) =>
    setCollapseID(collapseID !== id ? id : '')

  const handleChange = (e: any) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }
  const handleAddNewQuestion = async (e: any) => {
    e.preventDefault()
    setFetching(true)
    await dispatch(MallThunks.newMallFaqs(value))
    await dispatch(MallThunks.getMallFaqs())
    handleResetNewQuestion()
    setFetching(false)
  }

  const handleResetNewQuestion = () => {
    setValue({
      question: '',
      answer: '',
      id: 0,
      order: 0
    })
    setIsEditing(true)
  }

  const handleConfirmDeleteModal = async () => {
    setOpenModal(false)
    setFetching(true)
    await dispatch(MallThunks.deleteOneMallFaqs(idToDelete))
    fetchGetMallFaqs()
  }

  const handleOpenModal = (id: number) => {
    if (info.active) {
      if (faqs.length === 1) {
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: '',
              title:
                'Mientras el Mall este activo, debes tener al menos una pregunta frecuente.'
            }
          })
        )
        return
      }
    }
    setOpenModal(true)
    setIdToDelete(id)
  }

  const handleEditChange = (event: any, index: number) => {
    let items = [...questions]
    let item = { ...items[index] }
    item = { ...item, [event.target.name]: event.target.value }
    items[index] = item
    setQuestions(items)
  }

  const handleEditSave = async (data: IFaq) => {
    setFetching(true)
    await dispatch(MallThunks.editMallFaqs(data))
    fetchGetMallFaqs()
    const resp = await setActive()
    props.setOptions([...resp])
  }

  const options = {
    title: 'eliminar',
    subtitle: 'esta pregunta?',
    motifText: 'Esta acción no se puede deshacer'
  }

  const handleChangeSwitch = (e: any) => {
    setSwitchToggle((prevState) => !prevState)
  }
  return (
    <>
      <MDBRow className="h-100 preview_Background">
        <MDBCol size="12" className="no-lateral-padding">
          <MDBRow className="justify-content-between container-title">
            <MDBCol size="10" className="col-title">
              <h3 className="title">Preguntas Frecuentes</h3>
            </MDBCol>
            <MDBCol size="2">
              <div
                className="btn-close"
                onClick={() => props.setSelectView('DEFAULT_VIEW')}
              >
                <IoMdClose size={32} className="icon-close" />
              </div>
            </MDBCol>
          </MDBRow>
          {fetching ? (
            <Loading />
          ) : (
            <>
              {!isEditing ? (
                <MDBRow className="justify-content-between faq_add_new_faq_row mb-2">
                  <MDBCol
                    className={`faq_add_new_faq_col ${
                      !value || !value.answer || value.answer.length === 0
                        ? 'faq_add_new_faq_col__empty'
                        : ''
                    }`}
                    size="12"
                  >
                    <MDBCollapseHeader
                      className="faq_add_new_faq"
                      tagClassName="d-flex justify-content-between faq_add_new_faq_text"
                    >
                      {collapseID === JSON.stringify(value.id) ? (
                        <textarea
                          placeholder="Ingrese la pregunta"
                          onChange={handleChange}
                          name="question"
                          value={value?.question}
                        />
                      ) : (
                        <input
                          type="text"
                          placeholder="Ingrese la pregunta"
                          onChange={handleChange}
                          name="question"
                          value={value?.question}
                        />
                      )}
                      <BiMinusCircle
                        size={24}
                        className={`faq_delete_faq ${
                          value &&
                          value.answer &&
                          value.answer.length > 0 &&
                          value.question &&
                          value.question.length > 0
                            ? ''
                            : 'faq_delete_faq__empty'
                        } mr-1`}
                        onClick={() => setIsEditing(true)}
                      />
                      {collapseID === JSON.stringify(value.id) ? (
                        <GoTriangleUp
                          className="icon-triangle-up"
                          onClick={() => {
                            toggleCollapse(JSON.stringify(value.id))
                          }}
                        />
                      ) : (
                        <GoTriangleDown
                          className="icon-triangle-down"
                          onClick={() => {
                            toggleCollapse(JSON.stringify(value.id))
                          }}
                        />
                      )}
                    </MDBCollapseHeader>
                    <MDBCollapse
                      id={JSON.stringify(value.id)}
                      isOpen={collapseID}
                    >
                      <div>
                        <label
                          className="label_custom_Switch"
                          htmlFor="preview"
                        >
                          Vista
                        </label>
                        <CustomSwitch
                          id="preview"
                          name="previewSwitch"
                          checked={switchToggle}
                          onChange={handleChangeSwitch}
                          optionLabels={['Previa', 'Edición']}
                        />
                        {switchToggle ? (
                          <div className="preview-data">
                            <Markup content={value.answer} />
                          </div>
                        ) : (
                          <textarea
                            className="faq_add_new_faq_textarea"
                            rows={6}
                            placeholder="Ingresa aquí el contenido de la información o respuesta"
                            onChange={handleChange}
                            name="answer"
                            value={value?.answer}
                          />
                        )}
                      </div>
                      {value &&
                        ((value.answer && value.answer.length > 0) ||
                          (value.question && value.question.length > 0)) && (
                          <MDBRow className="justify-content-between pt-3 no-lateral-padding">
                            <MDBCol
                              size="6"
                              className="no-lateral-padding pr-2"
                            >
                              <button
                                onClick={() => handleResetNewQuestion()}
                                className="faq_closeButton"
                              >
                                Cancelar
                              </button>
                            </MDBCol>
                            <MDBCol
                              size="6"
                              className="no-lateral-padding pl-2"
                            >
                              <button
                                onClick={handleAddNewQuestion}
                                className="faq_confirmButton"
                                type="submit"
                              >
                                Guardar
                              </button>
                            </MDBCol>
                          </MDBRow>
                        )}
                    </MDBCollapse>
                  </MDBCol>
                </MDBRow>
              ) : (
                ''
              )}
              {questions?.map((value, index) => (
                <MDBRow
                  key={index}
                  className="justify-content-between faq_add_new_faq_row mb-2"
                >
                  <MDBCol className="faq_add_new_faq_col" size="12">
                    <MDBCollapseHeader
                      className={
                        isEditing
                          ? ' faq_add_new_faq mb-0'
                          : 'faq_add_new_faq mb-0 disabled'
                      }
                      tagClassName="d-flex justify-content-between faq_add_new_faq_text"
                    >
                      {collapseID !== JSON.stringify(value.id) ? (
                        <div>{value?.question}</div>
                      ) : (
                        <textarea
                          placeholder="Ingrese la pregunta"
                          onChange={(e) => handleEditChange(e, index)}
                          name="question"
                          value={value?.question}
                        />
                      )}
                      {collapseID === JSON.stringify(value.id) ? (
                        <BiMinusCircle
                          size={24}
                          className="faq_delete_faq active_faq mr-1"
                          onClick={() => handleOpenModal(value.id)}
                        />
                      ) : (
                        <BiMinusCircle
                          size={24}
                          className="faq_delete_faq mr-1"
                          onClick={() => handleOpenModal(value.id)}
                        />
                      )}
                      {collapseID === JSON.stringify(value.id) ? (
                        <GoTriangleUp
                          className="icon-triangle-up"
                          onClick={() => {
                            toggleCollapse(JSON.stringify(value.id))
                          }}
                        />
                      ) : (
                        <GoTriangleDown
                          className="icon-triangle-down"
                          onClick={() => {
                            toggleCollapse(JSON.stringify(value.id))
                          }}
                        />
                      )}
                    </MDBCollapseHeader>
                    <MDBCollapse
                      id={JSON.stringify(value.id)}
                      isOpen={collapseID}
                    >
                      <div>
                        <label
                          className="label_custom_Switch"
                          htmlFor="preview"
                        >
                          Vista
                        </label>
                        <CustomSwitch
                          id="preview"
                          name="previewSwitch"
                          checked={switchToggle}
                          onChange={handleChangeSwitch}
                          optionLabels={['Edición', 'Previa']}
                        />
                        {!switchToggle ? (
                          <div className="preview-data">
                            <Markup content={value.answer} />
                          </div>
                        ) : (
                          <textarea
                            className="faq_add_new_faq_textarea"
                            rows={6}
                            placeholder="Ingresa aquí el contenido de la información o respuesta"
                            onChange={(e) => handleEditChange(e, index)}
                            name="answer"
                            value={value?.answer}
                          />
                        )}
                      </div>
                      <MDBRow className="justify-content-between pt-3 no-lateral-padding">
                        <MDBCol size="6" className="no-lateral-padding pr-2">
                          <button
                            onClick={() => {
                              fetchGetMallFaqs()
                              toggleCollapse(JSON.stringify(value.id))
                            }}
                            className="faq_closeButton"
                          >
                            Cancelar
                          </button>
                        </MDBCol>
                        <MDBCol size="6" className="no-lateral-padding pl-2">
                          <button
                            className="faq_confirmButton"
                            onClick={() => handleEditSave(value)}
                          >
                            Guardar
                          </button>
                        </MDBCol>
                      </MDBRow>
                    </MDBCollapse>
                  </MDBCol>
                </MDBRow>
              ))}
              <MDBRow
                className={
                  isEditing
                    ? 'justify-content-between faq_add_new_box  mt-3'
                    : 'justify-content-between faq_add_new_box faq_add_new_box_Disabled mt-3'
                }
                onClick={() => {
                  if (isEditing) {
                    setIsEditing(false)
                    toggleCollapse('0')
                  }
                }}
              >
                <MDBCol className="faq_add_new_text" size="10">
                  <p>Agrega nueva Pregunta frecuente</p>
                </MDBCol>
                <MDBCol className="faq_add_new_icon" size="2">
                  <MDBIcon icon="plus-circle" />
                </MDBCol>
              </MDBRow>
            </>
          )}
        </MDBCol>
      </MDBRow>
      {openModal && (
        <CommonModal
          setOpenModal={setOpenModal}
          handleConfirmModal={handleConfirmDeleteModal}
          options={options}
        />
      )}
    </>
  )
}

export default FaqPage
