import './header.scss'
import React, { useState } from 'react'
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse
} from 'mdbreact'
import { BiExit } from 'react-icons/bi'
import { IoMdNotificationsOutline } from 'react-icons/io'
import Logo from '@assets/images/arauco-plus.svg'
//import { Sidenav } from '@commons/sidenav'

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  function toggleCollapse() {
    setIsOpen(!isOpen)
  }

  const hostname = window.location.pathname

  return (
    <header className="header">
      {/* <Sidenav /> */}
      <MDBNavbar expand="md">
        <MDBNavbarBrand>
          {hostname !== '/' && hostname !== '/malls' && (
            <img src={Logo} alt="" className="header__logo" />
          )}
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
          <MDBNavbarNav right>
            <MDBNavItem>
              <IoMdNotificationsOutline />
            </MDBNavItem>
            <MDBNavItem className="nav-item-user">USUARIO</MDBNavItem>
            <MDBNavItem>
              <BiExit />
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    </header>
  )
}

export default Header
