import React, { useEffect, useRef, useState } from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { MallActions, MallThunks } from '../index'
import Loading from '@commons/loading/Loading'
import { IoMdClose } from 'react-icons/io'
import { FiMinus } from 'react-icons/fi'
import { GrLinkUp } from 'react-icons/gr'
import CommonModal from '@commons/commonModal/commonModal'
import { IConfigFileAttachments } from '@features/sections/sections-interface'
import { setAlertModal } from '@commons/common-actions'
interface IMyProps {
  setSelectView: any
  setOptions: any
  options: any
  selectView: any
}

const UpdateImage: React.FC<IMyProps> = (props) => {
  const dispatch = useDispatch()
  const { logo, mallId, name, active } = useSelector(
    (state: IInitialState) => state.mall.info
  )

  const inputFileRef = useRef<HTMLInputElement>(null)
  const _contentRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [showDelete, setShowDelete] = useState(true)
  const [showInput, setShowInput] = useState(true)
  const [showSave, setShowSave] = useState(true)
  const [warningMsg, setWarningMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [logo64, setLogo64]: any = useState()
  const [logoType, setLogoType]: any = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [openModalExit, setOpenModalExit] = useState(false)

  const configFiles: IConfigFileAttachments = JSON.parse(
    process.env.REACT_APP_CONFIG_UPLOAD_IMAGES
      ? process.env.REACT_APP_CONFIG_UPLOAD_IMAGES
      : ''
  )

  let maxSizeFiles = 0
  let extensions = ''
  let formats = ''
  let maxSizeFilesMB = 0
  if (configFiles) {
    maxSizeFiles = configFiles.maxFileSize
    extensions = configFiles.extensions.toString()
    formats = extensions.replace(/\./gi, ' ').toUpperCase()
    maxSizeFilesMB = maxSizeFiles / 1048576
  }

  const validateFile = (fileUp: File): boolean => {
    let valid = true
    if (configFiles) {
      if (fileUp) {
        const filename = fileUp.name
        if (!configFiles.mimetypes.includes(fileUp.type)) {
          setWarningMsg(
            `El documento '${filename}' no tiene un formato permitido: ${formats}`
          )
          valid = false
        }
        if (fileUp.size > maxSizeFiles) {
          setWarningMsg(
            `El documento '${filename}' excede el tamaño máximo de ${maxSizeFilesMB}MB`
          )
          valid = false
        }
      }
    }
    return valid
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true)
      setWarningMsg('')
      setSuccessMsg('')
    }
  }
  const handleDragOut = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    setIsDragging(false)
  }
  const dragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
  }

  const fileDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    setIsDragging(false)
    const files = e.dataTransfer.files
    if (files && files.length > 0 && validateFile(files[0])) {
      e.dataTransfer.clearData()
      updateLogo(files[0])
    }
  }

  const handleUpdateLogo = (e: any) => {
    const file = e.target.files[0]
    if (file && validateFile(file)) {
      updateLogo(file)
    }
  }

  const updateLogo = (file: File) => {
    setShowDelete(false)
    setShowSave(false)
    // setShowInput(false)
    const logo: string = URL.createObjectURL(file)
    setLogoType(file.type)
    dispatch(MallActions.updateLogo(logo))
    const reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
      const base64String: any = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '')
      setLogo64(base64String)
    }
  }

  const handleDelLogo = () => {
    if (active) {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: '',
            title:
              'Mientras el Mall este activo, no se puede modificar el Logo.'
          }
        })
      )
      return
    }
    setOpenModal(true)
  }

  const handleConfirmDeleteLogo = async () => {
    setFetching(true)
    await dispatch(MallActions.updateLogo(''))
    setTimeout(() => {
      dispatch(MallThunks.updateMallInfo())
    }, 1000)
    setFetching(false)
    setOpenModal(false)
    setSuccessMsg('¡Logo eliminado exitosamente!')
  }

  const success = (text: string) => {
    setSuccessMsg(text)
  }

  const handleSend = async (e: any) => {
    e.preventDefault()
    setFetching(true)
    const resp = await setActive()
    props.setOptions([...resp])
    if (logo64) {
      await dispatch(MallThunks.updateMallLogo(logo64, logoType, success))
      setTimeout(() => {
        dispatch(MallThunks.updateMallInfo())
      }, 1000)
      setShowInput(false)
      setShowSave(true)
      setFetching(false)
      setShowDelete(true)
    }
  }

  const setActive = () => {
    const position = props.options.findIndex(
      (value: any) => value.id === props.selectView
    )
    let newArray = props.options
    for (let i = 0; i < props.options.length; i++) {
      const element = props.options[i]
      if (element.id === props.selectView) {
        newArray[position].finished = true
      }
    }

    return newArray
  }

  const handleCancel = () => {
    dispatch(MallThunks.getMall(mallId, setFetching))
    setWarningMsg('')
    setSuccessMsg('')
  }
  useEffect(() => {
    if (logo && showDelete) {
      setShowInput(false)
    } else {
      setShowInput(true)
    }
  }, [logo])

  const handleConfirmExit = async () => {
    props.setSelectView('DEFAULT_VIEW')
  }

  const options = {
    title: 'eliminar',
    subtitle: `el logo de '${name}'?`,
    motifText: 'Esta acción no se puede deshacer'
  }

  const optionsExit = {
    title: 'salir sin guardar?',
    subtitle: ``,
    motifText: ''
  }

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <MDBRow className="h-100 preview_Background">
          <MDBCol size="12" className="no-lateral-padding">
            <MDBRow className="justify-content-between tittle_Preview">
              <MDBCol size="10" className="tittle_Preview_name">
                <p>Imágenes</p>
              </MDBCol>
              <MDBCol size="2">
                <div
                  className="btn-close"
                  onClick={() => {
                    if (showInput) {
                      setOpenModalExit(true)
                    } else {
                      props.setSelectView('DEFAULT_VIEW')
                    }
                  }}
                >
                  <IoMdClose size={32} className="icon-close" />
                </div>
              </MDBCol>
            </MDBRow>
            {logo && showDelete && (
              <div
                className="updateImage_delete"
                onClick={() => handleDelLogo()}
              >
                <FiMinus className="icon-delete" />
              </div>
            )}
            <form onSubmit={handleSend}>
              <h4 className="updateImage_subTitles">Logo de {name}</h4>
              <MDBRow className={`updateImage_box`}>
                <MDBCol
                  className={`updateImage_insidebox ${
                    logo ? 'updateImage_insidebox__noborder' : ''
                  } ${isDragging ? 'updateImage_insidebox__dragging' : ''}`}
                >
                  {logo ? (
                    <img className="updateImage_image" src={logo} alt="Logo" />
                  ) : (
                    <div
                      ref={_contentRef}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragOut}
                      onDragOver={dragOver}
                      onDrop={fileDrop}
                    >
                      <div className="icon-upload">
                        <GrLinkUp className="upload" />
                      </div>
                      <div
                        className={`text-upload ${
                          isDragging ? 'text-upload__dragging' : ''
                        }`}
                      >
                        {isDragging
                          ? 'Suelta el logo dentro del recuadro'
                          : 'Arrastra el logo aquí para subirlo'}
                      </div>
                      <div className="text-upload-bottom">
                        <div className="horizontal-line" />
                        <div className="text">o</div>
                        <div className="horizontal-line" />
                      </div>
                    </div>
                  )}
                  {showInput && (
                    <>
                      <label
                        htmlFor="inputButtonLogo"
                        className="updateImage_bluebutton"
                      >
                        Selecciona un archivo
                      </label>

                      <input
                        type="file"
                        ref={inputFileRef}
                        accept={extensions}
                        className="updateImage_input"
                        id="inputButtonLogo"
                        onChange={handleUpdateLogo}
                      />
                    </>
                  )}
                </MDBCol>
              </MDBRow>
              {warningMsg.length > 0 && (
                <div className="message message__warning">{warningMsg}</div>
              )}
              {successMsg.length > 0 && (
                <div className="message">{successMsg}</div>
              )}
              <MDBRow className="container-btns justify-content-between">
                <MDBCol size="6" className="no-lateral-padding pr-2">
                  <button
                    className="updateImage_closeButton"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </button>
                </MDBCol>
                <MDBCol size="6" className="no-lateral-padding pl-2">
                  {!showSave ? (
                    <button className="updateImage_confirmButton" type="submit">
                      Guardar
                    </button>
                  ) : (
                    <button
                      className="updateImage_confirmButtonDisable"
                      disabled
                    >
                      Guardar
                    </button>
                  )}
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
          {openModal && (
            <CommonModal
              setOpenModal={setOpenModal}
              handleConfirmModal={handleConfirmDeleteLogo}
              options={options}
            />
          )}
        </MDBRow>
      )}
      {openModalExit && (
        <CommonModal
          setOpenModal={setOpenModalExit}
          handleConfirmModal={handleConfirmExit}
          options={optionsExit}
        />
      )}
    </>
  )
}

export default UpdateImage
