import React, { useEffect, useState } from 'react'
import Layout from '@commons/layout/layout'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { Link, useHistory } from 'react-router-dom'
import CommonModal from '@commons/commonModal/commonModal'
import Logo from '@assets/images/arauco-plus.svg'
import { HomeThunks } from '@features/home'
import Loading from '@commons/loading/Loading'
import { IMallInfo } from '@features/mall/mall-interfaces'
import { MenuActions } from '@commons/menu'
import TokenSpired from 'src/helpers/tokenSpired'
import AlertModal from '@commons/alertModal/alertModal'
import { setAlertModal } from '@commons/common-actions'

const MallsPage: React.FC = () => {
  const { malls } = useSelector((state: IInitialState) => state.general)
  const { settings } = useSelector((state: IInitialState) => state)
  const newMallsList = useSelector(
    (state: IInitialState) => state.general.newMalls
  )
  const [openModal, setOpenModal] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [mallId, setmallId] = useState(0)
  const history = useHistory()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(HomeThunks.getNewMalls(setFetching))
    dispatch(HomeThunks.getMalls())
  }, [dispatch])

  const handleConfirmModal = async () => {
    setFetching(true)
    const resp: any = await dispatch(HomeThunks.createNewMalls(mallId))
    if (resp === 500) {
      setOpenModal(false)
      setFetching(false)
    } else {
      setOpenModal(false)
      await dispatch(HomeThunks.getMalls())
      setFetching(false)

      history.push(`/editMall/${mallId}`)
    }
  }

  const handleOpenModal = (id: number) => {
    setOpenModal(true)
    setmallId(id)
  }
  const options = {
    title: 'agregar',
    subtitle: 'un nuevo mall?',
    motifText: 'Esta acción no se puede deshacer'
  }
  return (
    <Layout>
      {fetching ? (
        <Loading />
      ) : (
        <MDBContainer className="h-100">
          <MDBRow className="row-logo-admin">
            <MDBCol size="12" className="text-center">
              <p className="malls_prev_admin">ADMINISTRADOR</p>
            </MDBCol>
            <MDBCol size="12" className="text-center">
              <img src={Logo} className="malls_prev_logo" alt="logo" />
            </MDBCol>
          </MDBRow>
          <MDBRow className="malls_prev_container">
            <MDBCol xs="12" md="5">
              <MDBRow className="align-items-center mt-4 text-center" center>
                <MDBCol size="12">
                  <h4 className="malls_prev_title">
                    Agrega un <span>nuevo Mall</span>
                  </h4>
                </MDBCol>
                <div className="malls_prev_createOut">
                  <div className="align-items-center text-center malls_prev_createIn">
                    {Array.isArray(newMallsList)!
                      ? newMallsList?.map((value) => (
                          <div
                            key={value.mallId}
                            className="malls_prev_createcol"
                            onClick={() => handleOpenModal(value.mallId)}
                          >
                            <div className="malls_prev_button">
                              Mall <span>{value.name}</span>
                            </div>
                          </div>
                        ))
                      : ''}
                  </div>
                </div>
              </MDBRow>
            </MDBCol>
            <MDBCol xs="12" md="5">
              <MDBRow className="align-items-center mt-4 text-center" center>
                <MDBCol size="12">
                  <h4 className="malls_prev_title">
                    Edita un <span>Mall</span>
                  </h4>
                </MDBCol>
                <div className="malls_prev_editOut">
                  <div className="align-items-center text-center malls_prev_editrow">
                    {Array.isArray(malls)!
                      ? malls.map((value: IMallInfo) => (
                          <div key={value.mallId} className="malls_prev_editIn">
                            <Link
                              to={`/editMall/${value.mallId}`}
                              onClick={() =>
                                dispatch(
                                  MenuActions.updateMenuSelected(
                                    `/editMall${value.mallId}`
                                  )
                                )
                              }
                            >
                              <div className="malls_prev_button ">
                                Mall <span>{value.name}</span>
                              </div>
                            </Link>
                          </div>
                        ))
                      : ''}
                  </div>
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )}
      {openModal && (
        <CommonModal
          setOpenModal={setOpenModal}
          handleConfirmModal={handleConfirmModal}
          options={options}
        />
      )}
      <TokenSpired />
      {settings.alert?.status && <AlertModal />}
    </Layout>
  )
}

export default MallsPage
