import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MDBCol, MDBRow } from 'mdbreact'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { IInitialState } from '@store/index'

import Logo from '@assets/images/arauco-plus.svg'

import { MallThunks } from '@features/mall'
import { toggleActivateMall } from '@features/mall/mall-thunks'

import Layout from '@commons/layout/layout'
import InputSwitch from '@commons/inputSwitch/inputSwitch'
import Loading from '@commons/loading/Loading'
import { Menu } from '@commons/menu'
import { setAlertModal } from '@commons/common-actions'
import CommonModal from '@commons/commonModal/commonModal'
import AlertModal from '@commons/alertModal/alertModal'

import ScheduleHomePreview from './elements/schedule-home-preview'
import ScheduleTimes from './elements/schedule-times'
import { ScheduleThunks } from '.'

const SchedulePage: React.FC = ({ match }: any) => {
  const dispatch = useDispatch()
  const { mall, settings } = useSelector((state: IInitialState) => state)
  const [toggle, setToggle] = useState(false)
  const [fetchingPage, setFetchingPage] = useState(false)
  const [index, setIndex] = useState(0)
  const [confirmModal, setConfirmModal] = useState(false)
  const [langSelected, setLangSelected] = useState('es')

  const fetchGetMall = useCallback(async () => {
    setFetchingPage(true)
    await dispatch(MallThunks.getMall(match.params.id, setFetchingPage))

    setFetchingPage(false)
  }, [dispatch])

  useEffect(() => {
    fetchGetMall()
  }, [fetchGetMall])

  const fetchGetSchedules = useCallback(async () => {
    await dispatch(ScheduleThunks.getMallSchedule(match.params.id))
  }, [dispatch])
  useEffect(() => {
    fetchGetSchedules()
  }, [fetchGetSchedules])

  useEffect(() => {
    setToggle(mall.info.active)
  }, [mall.info.active])

  const handleActivateMall = () => {
    const isTrue = settings.options.status.find(
      (item) => item.finished === false
    )
    if (isTrue) {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: '',
            title:
              'No puedes activar el Mall porque no está completa toda la información '
          }
        })
      )
    } else {
      setConfirmModal(true)
    }
  }

  const handleConfirmModal = () => {
    if (!toggle) {
      const data = {
        mallId: mall.info.mallId,
        isActive: true
      }
      dispatch(toggleActivateMall(data))
    } else {
      const data = {
        mallId: mall.info.mallId,
        isActive: false
      }
      dispatch(toggleActivateMall(data))
    }
    setToggle((toggle) => !toggle)
    setConfirmModal(false)
  }

  const optionsModal = {
    title: toggle ? 'inactivar' : 'activar',
    subtitle: toggle ? ' este Mall' : ' este Mall',
    motifText: ''
  }

  const initialLang = [
    { code: 'es', name: 'ESP' },
    { code: 'en', name: 'ING' },
    { code: 'pt', name: 'POR' }
  ]

  return (
    <Layout>
      <div>
        {fetchingPage ? (
          <Loading />
        ) : (
          <DndProvider backend={HTML5Backend}>
            <MDBRow className="mt-5 mx-0">
              <MDBCol size="3" className="mx-0 px-0">
                <div className="container-logo">
                  <img src={Logo} className="mall-logo" alt="logo" />
                </div>
                <Menu />
              </MDBCol>
              <MDBCol size="9">
                <div className="container-name-mall">
                  <MDBRow className="h-100 text-center" between>
                    <h2>
                      Mall{' '}
                      <span className="newMall_mallName">{mall.info.name}</span>{' '}
                    </h2>
                    <InputSwitch
                      id="activeMall"
                      name="activeMallSwitch"
                      checked={toggle}
                      onChange={handleActivateMall}
                      optionLabels={['Activo', 'Inactivo']}
                      disabled={false}
                    />
                  </MDBRow>
                </div>
                <MDBRow>
                  {initialLang.map((value) => (
                    <MDBCol
                      size="1"
                      className={`tabLang ${
                        langSelected === value.code && 'activeTab'
                      }`}
                      onClick={() => setLangSelected(value.code)}
                    >
                      {value.name}
                    </MDBCol>
                  ))}
                </MDBRow>
                <div className="newMall_background p-5">
                  <MDBRow>
                    <MDBCol
                      className="no-lateral-padding margin_schedule"
                      size="6"
                    >
                      <MDBRow className="justify-content-between container-title container-title-schedule">
                        <MDBCol size="10" className="col-title">
                          <h3 className="title">{mall.home[index]?.name}</h3>
                        </MDBCol>
                      </MDBRow>
                      <ScheduleHomePreview
                        paramsId={match.params.id}
                        defaultTime={false}
                        index={index}
                        langSelected={langSelected}
                      />
                    </MDBCol>
                    <MDBCol className="newMall_Preview no-lateral-padding">
                      <ScheduleTimes
                        setIndex={setIndex}
                        langSelected={langSelected}
                      />
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
            </MDBRow>
          </DndProvider>
        )}
      </div>
      {confirmModal && (
        <CommonModal
          setOpenModal={setConfirmModal}
          handleConfirmModal={handleConfirmModal}
          options={optionsModal}
        />
      )}
      {settings.alert?.status && <AlertModal />}
    </Layout>
  )
}

export default SchedulePage
