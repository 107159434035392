import React from 'react'
import LogoCorp from '@assets/images/arauco-plus.svg'
interface IMyProps {
  setOpenModal: any
  handleConfirmModal: any
  options: {
    title: string
    subtitle: string
    motifText: string
  }
}

const CommonModal: React.FC<IMyProps> = (props: IMyProps) => {
  const Logo = process.env.logo || LogoCorp

  return (
    <div className="modal_box_common">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal_content">
          <div className="modal-header m-auto">
            <img src={Logo} alt="logo" />
          </div>
          <div className="modal-body modal_body text-center">
            <h4>
              ¿Estás seguro que deseas {props.options.title} <br />{' '}
              {props.options.subtitle}
            </h4>
            <p>{props.options.motifText}</p>
          </div>
          <div className="modal-footer">
            <div className="button-container">
              <button
                type="button"
                className="modal_confirmButton"
                onClick={() => props.handleConfirmModal()}
              >
                Si, {props.options.title}
              </button>
            </div>
            <div className="button-container">
              <button
                type="button"
                className="modal_closeButton"
                onClick={() => props.setOpenModal(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommonModal
