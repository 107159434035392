import { IAction } from '@commons/common-interfaces'
import { SET_HOME_EDIT, SET_INITIAL_HOME } from './sections-actions'

import { ISectionsState } from './sections-interface'

const initialState: ISectionsState = {
  homeEdit: null,
  initialHome: null
}

export const SectionsReducer = (
  state: ISectionsState,
  action: IAction
): ISectionsState => {
  state = state ? state : initialState

  switch (action.type) {
    case SET_HOME_EDIT:
      return {
        ...state,
        homeEdit: action.payload
      }
    case SET_INITIAL_HOME:
      return {
        ...state,
        initialHome: action.payload
      }
    default:
      return state
  }
}
