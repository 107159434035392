import RequestService from '../../core/request-service'
import { IPromise } from '@commons/common-interfaces'
import { INotificationSend } from './notifications-interface'

let channel = process.env.REACT_APP_COUNTRY || 'Chile'
if (channel === 'Chile') {
  channel = 'ARAUCO_PLUS'
}
if (channel === 'Colombia') {
  channel = 'PARQUE_PLUS'
}
if (channel === 'Peru') {
  channel = 'PERU_PLUS'
}
export class NotificationsService extends RequestService {
  static SendNotification(value: INotificationSend): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_CORP_API_URL}/bo/campaigns`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`
      }
    }
    const params = { ...value }
    return super.post(endpoint, params, headers)
  }

  static getListNotification(
    id: string,
    status?: string,
    type?: string
  ): Promise<IPromise> {
    const path = process.env.REACT_APP_CORP_API_URL
    let endpoint = `${path}/bo/campaigns?mallId=${id}&pageSize=10&order=desc`

    if (status && status !== 'Todas') {
      endpoint += `&status=${status}`
    }
    if (type && type !== 'Todas') {
      endpoint += `&type=${type}`
    }

    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': id
      }
    }

    return super.get(endpoint, headers)
  }

  static getListNotificationNext(
    id: string,
    lastCampaignId: string,
    status?: string,
    type?: string
  ): Promise<IPromise> {
    const path = process.env.REACT_APP_CORP_API_URL
    let endpoint = `${path}/bo/campaigns?mallId=${id}&pageSize=10&lastCampaignId=${lastCampaignId}&order=desc`

    if (status && status !== 'Todas') {
      endpoint += `&status=${status}`
    }
    if (type && type !== 'Todas') {
      endpoint += `&type=${type}`
    }

    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`,
        'mall-id': id
      }
    }

    return super.get(endpoint, headers)
  }

  static getListInterest(): Promise<IPromise> {
    const path = process.env.REACT_APP_BASE_API_URL
    const endpoint = `${path}/profile/interests`

    let headers: Headers | string[][] | Record<string, string> | undefined = {}

    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    return super.get(endpoint, headers)
  }
  static getListTypeNotifications(): Promise<IPromise> {
    const path = process.env.REACT_APP_CORP_API_URL
    const endpoint = `${path}/bo/campaigns/types/creational?channel=${channel}`

    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`
      }
    }

    return super.get(endpoint, headers)
  }

  static getListAllTypeNotifications(): Promise<IPromise> {
    const path = process.env.REACT_APP_CORP_API_URL
    const endpoint = `${path}/bo/campaigns/types?channel=${channel}`

    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`
      }
    }

    return super.get(endpoint, headers)
  }
}
