import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MDBCol,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions
} from 'mdbreact'
import { GrAddCircle } from 'react-icons/gr'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'
import { IInitialState } from '@store/index'
import { NotificationsService } from '../index'
import Loading from '@commons/loading/Loading'
import { ITypeNotification } from '../notifications-interface'

interface IMyProps {
  setNewNotification: any
  match: any
}
const NotificationsList: React.FC<IMyProps> = ({
  setNewNotification,
  match
}) => {
  // const { mall } = useSelector((state: IInitialState) => state)
  const mallId = match.params.id
  const dispatch = useDispatch()
  const [dataCampaigns, setDataCampaigns] = useState([])
  const [idCampaigns, setIdCampaigns] = useState([''])
  const [page, setPage] = useState(0)
  const [fetching, setFetching] = useState(false)
  const [noMore, setNoMore] = useState(false)
  const [status, setStatus] = useState('')
  const [type, setType] = useState('')
  const [Nametype, setNameType] = useState('')
  const [statusName, setNameStatus] = useState('')
  const [notificationsType, setNotificationsType] = useState<
    ITypeNotification[]
  >([])
  const fetchGetListNotification = useCallback(
    async (status, type) => {
      setFetching(true)
      const {
        data
      } = await NotificationsService.NotificationsService.getListNotification(
        mallId,
        status,
        type
      )

      if (data.data) {
        const { campaigns, lastCampaignId } = data.data
        if (campaigns) {
          setDataCampaigns(campaigns)
        }
        if (lastCampaignId && campaigns) {
          setIdCampaigns([null, lastCampaignId])
          setPage(0)
          setNoMore(false)
        } else {
          setNoMore(true)
        }
      } else {
        setDataCampaigns([])
        setIdCampaigns([''])
        setPage(0)
        setNoMore(false)
      }

      setStatus(status)
      setType(type)

      setFetching(false)
    },
    [dispatch, mallId, status, type]
  )

  const getTypeNotification = async () => {
    let {
      data,
      httpCode
    } = await NotificationsService.NotificationsService.getListAllTypeNotifications()

    if (httpCode === 200) {
      setNotificationsType(data.data)
    }
  }

  useEffect(() => {
    fetchGetListNotification(status, type)
  }, [])
  useEffect(() => {
    getTypeNotification()
  }, [])

  const nextNotificationList = async () => {
    setFetching(true)
    const next = idCampaigns[page + 1]

    const {
      data
    } = await NotificationsService.NotificationsService.getListNotificationNext(
      mallId,
      next,
      status,
      type
    )
    const { campaigns, lastCampaignId } = data.data
    if (campaigns) {
      setPage(page + 1)
      setDataCampaigns(campaigns)
    }
    if (lastCampaignId) {
      setIdCampaigns([...idCampaigns, lastCampaignId])
      setNoMore(false)
    } else {
      setNoMore(true)
    }
    setFetching(false)
  }

  const backNotificationList = async () => {
    setFetching(true)
    const {
      data
    } = await NotificationsService.NotificationsService.getListNotificationNext(
      mallId,
      idCampaigns[page - 1]
    )
    const { campaigns, lastCampaignId } = data.data
    if (campaigns) {
      setPage(page - 1)
      setDataCampaigns(campaigns)
    }
    if (lastCampaignId) {
      setIdCampaigns([...idCampaigns])
      setNoMore(false)
    } else {
      setNoMore(true)
    }
    setFetching(false)
  }

  const handleSelect = (value: any) => {
    fetchGetListNotification(value[0], type)
  }
  const handleSelectType = (value: any) => {
    fetchGetListNotification(status, value[0])
  }

  const resolveDate = (value: string) => {
    const init = value.split(' ', 1)
    const [year, month, day] = init[0].split('-')
    const date = [day, month, year].join('/')

    return date
  }

  const states = [
    { name: 'Todas', value: 'Todas' },
    { name: 'Activo', value: 'ACTIVE' },
    { name: 'Inactivo', value: 'INACTIVE' }
  ]

  return (
    <>
      {fetching ? (
        <div className="loaderPosition">
          <Loading />
        </div>
      ) : (
        <div className="newMall_background p-5 minSizeNotification">
          <MDBRow className="">
            <MDBCol className="no-lateral-padding margin_schedule" size="5">
              <MDBRow className="justify-content-between container-title container-title-schedule">
                <MDBCol size="10" className="col-title">
                  <h3 className="title">Lista de Notificaciones</h3>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol className="no-lateral-padding margin_schedule" size="5">
              <MDBRow className=" justify-content-end">
                <MDBCol size="4" className="notification-add">
                  <p>
                    Añadir{' '}
                    <span>
                      <GrAddCircle
                        onClick={() => setNewNotification(true)}
                        className="icon-add-notification"
                      />
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          {/* Filters  */}
          <MDBRow center className="mb-2">
            <MDBCol size="3">
              <MDBSelect
                label={statusName ? statusName : 'Selecciona un tipo'}
                getValue={(value) => handleSelect(value)}
                getTextContent={(value) => setNameStatus(value)}
                className="modal_edit_select"
              >
                <MDBSelectInput disabled selected="Selecciona un tipo" />
                <MDBSelectOptions>
                  {states?.map((data, index) => (
                    <MDBSelectOption key={index} value={data.value}>
                      {data.name}
                    </MDBSelectOption>
                  ))}
                </MDBSelectOptions>
              </MDBSelect>
            </MDBCol>
            <MDBCol size="5">
              <MDBSelect
                label={Nametype ? Nametype : 'Selecciona un tipo'}
                getValue={(value) => handleSelectType(value)}
                getTextContent={(value) => setNameType(value)}
                className="modal_edit_select"
              >
                <MDBSelectInput disabled selected="Selecciona un tipo" />
                <MDBSelectOptions>
                  <MDBSelectOption value={'Todas'}>Todas</MDBSelectOption>
                  {notificationsType?.map((data, index) => (
                    <MDBSelectOption key={index} value={data.code}>
                      {data.description}
                    </MDBSelectOption>
                  ))}
                </MDBSelectOptions>
              </MDBSelect>
            </MDBCol>
          </MDBRow>

          {/* CARDS  */}
          {dataCampaigns.length !== 0 ? (
            <div className="minHeight_ListNotification">
              <MDBRow className="px-3 justify-content-between">
                {dataCampaigns?.map((value: any) => {
                  const startDate = resolveDate(value?.startDate)
                  const endDate = resolveDate(value?.endDate)

                  return (
                    <MDBCol
                      className={
                        value.status === 'ACTIVE' ? ' margin_schedule mb-3' : ''
                      }
                      size="5"
                    >
                      <MDBRow
                        className={
                          value.status === 'ACTIVE'
                            ? 'justify-content-between align-items-center notification-list-box'
                            : 'justify-content-between align-items-center notification-list-box-disabled'
                        }
                      >
                        <MDBCol size="2" className="icon-listNotification">
                          <img className="" src={value?.icon} alt="" />
                        </MDBCol>
                        <MDBCol
                          size="10"
                          className="notification-name text-center"
                        >
                          <p>{value?.title}</p>
                        </MDBCol>
                        <span className="vigency-listNotification">
                          {startDate} a {endDate}
                        </span>
                      </MDBRow>
                    </MDBCol>
                  )
                })}
              </MDBRow>
            </div>
          ) : (
            <div className="minHeight_ListNotification">
              <MDBRow className="px-3 justify-content-center listNoNotification_card">
                <MDBCol size="5" className="">
                  <div className="text_NoNotification">
                    <img
                      className="mb-1 noListNotification"
                      src={
                        'https://api-image-admin-bucket-dev.s3.us-east-1.amazonaws.com/assets/icons/infoblue.svg'
                      }
                      alt=""
                    />

                    <p>
                      La lista de notificaciones <br /> está vacía
                    </p>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
          )}

          <MDBRow className="justify-content-between mt-5">
            <MDBCol className="notification_list_button" size="3">
              {page === 0 ? (
                <></>
              ) : (
                <button
                  className="section_confirmButton"
                  onClick={backNotificationList}
                >
                  <MdArrowBack className="notification_list_arrow _L" />
                  Anterior
                </button>
              )}
            </MDBCol>
            <MDBCol className="notification_list_button" size="3">
              {noMore || dataCampaigns.length <= 9 ? (
                <></>
              ) : (
                <button
                  className="section_confirmButton  "
                  onClick={nextNotificationList}
                >
                  Siguiente
                  <MdArrowForward className="notification_list_arrow _R" />
                </button>
              )}
            </MDBCol>
          </MDBRow>
        </div>
      )}
    </>
  )
}

export default NotificationsList
