import React, { useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import { Markup } from 'interweave'
import { RiPencilLine } from 'react-icons/ri'
import { HiPlus } from 'react-icons/hi'
import { FiMinus } from 'react-icons/fi'

import { IBlock, ISection } from '@features/sections/sections-interface'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { SectionsActions } from '@features/sections'
import CommonModal from '@commons/commonModal/commonModal'
import { setModal } from '@commons/common-actions'
import './sectionItem.scss'

interface IMyProps {
  mallSelected?: number
  itemData: IBlock
  backgroundWhite: boolean
  style: string
  isTemplate?: boolean
  setOpenModal?: (value: boolean) => void
  setDataModal?: any
  setOpenSubModal?: (value: boolean) => void
  section?: ISection
  fullSizeBloq?: boolean
}

const SectionItem: React.FC<IMyProps> = (props) => {
  const {
    itemData,
    backgroundWhite,
    style,
    isTemplate,
    setOpenModal,
    setDataModal,
    setOpenSubModal,
    section,
    fullSizeBloq
  } = props

  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const { modalOpen } = useSelector((state: IInitialState) => state.settings)
  const [openDelModal, setOpenDelModal] = useState(false)

  const dispatch = useDispatch()

  const textColor = `title-item__${style}`
  const backgroundIconColor = `item-icon__${style}`
  const sectionItemBorder = `section-item__${style}`
  const iconPlusStyle = `demoIconPlus__${style}`

  let renderIcon = (
    <div className={`demoIcon ${backgroundWhite ? 'demoIcon__black' : ''}`}>
      <i className={itemData.icon} />
    </div>
  )

  if (String(itemData.icon).includes('Plus')) {
    // const icon = itemData.icon?.replace(/iconPlus/g, 'icon')

    renderIcon = (
      <div
        className={`demoIconPlus ${
          backgroundWhite ? 'demoIconPlus__black' : ''
        } ${iconPlusStyle}`}
      >
        <i className={`${itemData.icon}`} />
      </div>
    )
  }

  const handleConfirmDeleteBlock = () => {
    if (homeEdit) {
      homeEdit.config.sections.map((sectionMap: ISection) => {
        if (sectionMap.id === section?.id) {
          const index = sectionMap.blocks.findIndex(
            (block: IBlock) => block.id === itemData.id
          )
          if (index !== undefined) {
            sectionMap.blocks.splice(index, 1)
          }
        }
        return sectionMap
      })
      dispatch(SectionsActions.setHomeEdit(homeEdit))
    }
    setOpenDelModal(false)
  }

  useEffect(() => {
    if (modalOpen.status) {
      openOnLoad()
      dispatch(setModal({ status: false }))
    }
  }, [])

  const openOnLoad = () => {
    if (setDataModal) {
      setDataModal({
        itemData,
        backgroundWhite,
        style,
        section
      })
      setOpenModal && setOpenModal(true)
    }
  }

  const options = {
    title: 'eliminar',
    subtitle: `el bloque '${itemData.title}'?`,
    motifText: `${
      itemData.subBlocks !== undefined && itemData.subBlocks.length > 0
        ? 'Al eliminar este bloque se eliminarán todos sus subbloques'
        : ''
    }`
  }

  const renderItem = (
    <div
      id={
        itemData.idQualtrics !== undefined && itemData.idQualtrics.length > 0
          ? itemData.idQualtrics
          : ''
      }
      className={`section-item ${
        backgroundWhite ? 'section-item__default' : ''
      } ${style !== 'default' ? sectionItemBorder : ''} 
        ${
          isTemplate &&
          itemData.subBlocks !== undefined &&
          itemData.subBlocks.length > 0
            ? 'section-item__with-sub-bloque'
            : ''
        }`}
    >
      <MDBRow className="no-lateral-padding icon-position h-100">
        <MDBCol className="grid-item-text no-padding" size="9">
          <div
            className={
              fullSizeBloq
                ? `title-item ${textColor}`
                : `title-item half ${textColor}`
            }
          >
            <Markup content={itemData.title} />
          </div>
          {fullSizeBloq ? (
            <div className="description">
              <Markup content={itemData.description} />
            </div>
          ) : (
            ''
          )}
        </MDBCol>
        <MDBCol className="grid-item-icon no-padding" size="3">
          <div
            className={
              fullSizeBloq
                ? `item-icon ${backgroundIconColor} `
                : `item-icon ${backgroundIconColor} half-icon`
            }
          >
            {renderIcon}
          </div>
        </MDBCol>
      </MDBRow>
      {isTemplate && (
        <>
          <span
            className="edit"
            onClick={() => {
              setDataModal({
                itemData,
                backgroundWhite,
                style,
                section
              })
              setOpenModal && setOpenModal(true)
            }}
          >
            <RiPencilLine className="icon-edit" />
          </span>
          {/* ==========boton agregar subBLoques=============  */}
          {/* {!backgroundWhite && (
            <span
              className="add-item"
              onClick={() => {
                setDataModal({
                  bloqId: itemData.id,
                  backgroundWhite,
                  style,
                  isTemplate,
                  section
                })
                setOpenSubModal && setOpenSubModal(true)
              }}
            >
              <HiPlus className="icon-add" />
            </span>
          )} */}

          <span
            className={`delete ${backgroundWhite ? 'delete__secundary' : ''}
            ${!fullSizeBloq &&'delete_half'}`}
            onClick={() => setOpenDelModal(true)}
          >
            <FiMinus className="icon-delete" />
          </span>
        </>
      )}
    </div>
  )

  return (
    <React.Fragment>
      {itemData.idQualtrics !== undefined &&
        itemData.idQualtrics.length === 0 &&
        renderItem}
      {itemData.idQualtrics !== undefined &&
        itemData.idQualtrics.length > 0 &&
        renderItem}
      {openDelModal && (
        <CommonModal
          setOpenModal={setOpenDelModal}
          handleConfirmModal={handleConfirmDeleteBlock}
          options={options}
        />
      )}
    </React.Fragment>
  )
}

export default SectionItem
