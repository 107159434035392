import React, { useCallback, useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { IInitialState } from '@store/index'
import HomeTemplate from './elements/home-template'
import BloqsSection from './elements/bloqs-section'
import { toggleActivateMall } from '@features/mall/mall-thunks'
import { ScheduleThunks } from '@features/schedules'
import Layout from '@commons/layout/layout'
import InputSwitch from '@commons/inputSwitch/inputSwitch'
import Loading from '@commons/loading/Loading'
import { Menu } from '@commons/menu'
import { setAlertModal } from '@commons/common-actions'
import CommonModal from '@commons/commonModal/commonModal'
import Logo from '@assets/images/arauco-plus.svg'
import { Link, useHistory } from 'react-router-dom'

const SectionsPage: React.FC = ({ match }: any) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { mall, settings } = useSelector((state: IInitialState) => state)
  const [toggle, setToggle] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [langSelected, setLangSelected] = useState('es')
  const [fetchingPage, setFetchingPage] = useState(false)

  const mallId = mall.info.mallId ? mall.info.mallId : match.params.id
  const scheduleId = match.params.scheduleId ? match.params.scheduleId : ''
  const lang = match.params.lang ? match.params.lang : 'es'

  const fetchGetSchedules = useCallback(async () => {
    await dispatch(ScheduleThunks.getMallSchedule(mallId))
    // await dispatch(ScheduleThunks.getMallScheduleStatus(mallId))
  }, [dispatch])

  useEffect(() => {
    fetchGetSchedules()
  }, [fetchGetSchedules])

  useEffect(() => {
    setLangSelected(lang)
  }, [lang])

  useEffect(() => {
    setToggle(mall.info.active)
  }, [mall.info.active])

  const handleActivateMall = () => {
    const isTrue = settings.options.status.find(
      (item) => item.finished === false
    )
    if (isTrue) {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: '',
            title:
              'No puedes activar el Mall porque no está completa toda la información '
          }
        })
      )
    } else {
      setConfirmModal(true)
    }
  }

  const handleConfirmModal = () => {
    if (!toggle) {
      const data = {
        mallId: mall.info.mallId,
        isActive: true
      }
      dispatch(toggleActivateMall(data))
    } else {
      const data = {
        mallId: mall.info.mallId,
        isActive: false
      }
      dispatch(toggleActivateMall(data))
    }
    setToggle((toggle) => !toggle)
    setConfirmModal(false)
  }

  const handleRedirect = (code: string) => {
    history.push(`/editMall/${mallId}/sections/${scheduleId}/${code}`)
  }

  const optionsModal = {
    title: toggle ? 'inactivar' : 'activar',
    subtitle: toggle ? ' este Mall' : ' este Mall',
    motifText: ''
  }

  const initialLang = [
    { code: 'es', name: 'ESP' },
    { code: 'en', name: 'ING' },
    { code: 'pt', name: 'POR' }
  ]

  return (
    <Layout>
      <div>
        {fetchingPage ? (
          <Loading />
        ) : (
          <DndProvider backend={HTML5Backend}>
            <MDBRow className="mt-5 mx-0">
              <MDBCol size="3" className="mx-0 px-0">
                <div className="container-logo">
                  <img src={Logo} className="mall-logo" alt="logo" />
                </div>
                <Menu />
              </MDBCol>
              <MDBCol size="9">
                <div className="container-name-mall">
                  <MDBRow className="h-100 text-center" between>
                    <h2>
                      Mall{' '}
                      <span className="newMall_mallName">{mall.info.name}</span>{' '}
                    </h2>
                    <InputSwitch
                      id="activeMall"
                      name="activeMallSwitch"
                      checked={toggle}
                      onChange={handleActivateMall}
                      optionLabels={['Activo', 'Inactivo']}
                      disabled={false}
                    />
                  </MDBRow>
                </div>

                <MDBRow>
                  {scheduleId === 'isDefault' &&
                    initialLang.map((value) => (
                      <MDBCol
                        size="1"
                        className={`tabLang ${
                          langSelected === value.code && 'activeTab'
                        }`}
                        onClick={() => handleRedirect(value.code)}
                      >
                        {value.name}
                      </MDBCol>
                    ))}
                </MDBRow>
                <div className="newMall_background">
                  <MDBRow>
                    <MDBCol className="no-lateral-padding" size="6">
                      <HomeTemplate
                        mall={mall}
                        mallId={mallId}
                        scheduleId={scheduleId}
                      />
                    </MDBCol>
                    <MDBCol
                      className="newMall_Preview no-lateral-padding"
                      size="6"
                    >
                      <BloqsSection
                        setFetchingPage={setFetchingPage}
                        scheduleId={scheduleId}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="w-100 templateHome">
                    <MDBCol className="col-letter-section">
                      <div className="letter-section">
                        <div className="letter letter__A">A</div> Sección header
                        y buscador
                      </div>
                      <div className="letter-section">
                        <div className="letter letter__B">B</div> Sección
                        directorio principal
                      </div>
                      <div className="letter-section">
                        <div className="letter letter__C">C</div> Sección
                        directorio principal
                      </div>
                    </MDBCol>
                    <MDBCol className="col-letter-section">
                      <div className="letter-section">
                        <div className="letter letter__D">D</div> Sección
                        directorio principal
                      </div>
                      <div className="letter-section">
                        <div className="letter letter__E">E</div> Sección
                        directorio principal
                      </div>
                      <div className="letter-section">
                        <div className="letter letter__F">F</div> Sección
                        directorio principal
                      </div>
                    </MDBCol>
                    <MDBCol className="col-letter-section">
                      <div className="letter-section">
                        <div className="letter letter__G">G</div> Sección
                        directorio secundaria
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
            </MDBRow>
          </DndProvider>
        )}
      </div>
      {confirmModal && (
        <CommonModal
          setOpenModal={setConfirmModal}
          handleConfirmModal={handleConfirmModal}
          options={optionsModal}
        />
      )}
    </Layout>
  )
}

export default SectionsPage
