import React from 'react'
import { Markup } from 'interweave'
import './alertItem.scss'

interface IMyProps {
  block: any
}

const AlertItem: React.FC<IMyProps> = (props) => {
  const { block } = props

  return (
    <div className="alert-bloq">
      <div
        key={block.id}
        className={`alert-container ${
          block.backgroundUrl.length > 0 ? '' : 'default-background'
        }`}
        style={
          block.backgroundUrl.length > 0
            ? { background: `url('${block.backgroundUrl}') no-repeat` }
            : undefined
        }
      >
        <div className="alert-text">
          <div className="alert-font">
            <Markup content={block.description} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertItem
