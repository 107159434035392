import React, { useCallback, useEffect, useState } from 'react'
import { IInitialState } from '@store/index'
import { useDispatch, useSelector } from 'react-redux'
import { MallActions, MallThunks } from '@features/mall'
import { MDBRow } from 'mdbreact'
import InputSwitch from '@commons/inputSwitch/inputSwitch'
import { setAlertModal } from '@commons/common-actions'
import AlertModal from '@commons/alertModal/alertModal'
import { toggleActivateMall } from '@features/mall/mall-thunks'
import CommonModal from '@commons/commonModal/commonModal'

export default function MallActive({ id }: any) {
  const { mall, settings } = useSelector((state: IInitialState) => state)
  const dispatch = useDispatch()

  const [fetchingPage, setFetchingPage] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  const fetchGetMall = useCallback(async () => {
    await dispatch(MallThunks.getMall(id, setFetchingPage))
  }, [dispatch])

  useEffect(() => {
    fetchGetMall()
  }, [fetchGetMall])

  useEffect(() => {
    setToggle(mall.info.active)
  }, [mall.info.active])

  const handleActivateMall = () => {
    const isTrue = settings.options.status.find(
      (item) => item.finished === false
    )
    if (isTrue) {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: '',
            title:
              'No puedes activar el Mall porque no está completa toda la información '
          }
        })
      )
    } else {
      setConfirmModal(true)
    }
  }

  const handleConfirmModal = () => {
    const dataInfo = mall.info
    if (!toggle) {
      const data = {
        mallId: id,
        isActive: true
      }
      dataInfo.active = true
      dispatch(MallActions.setMallInfo(dataInfo))
      dispatch(toggleActivateMall(data))
    } else {
      const data = {
        mallId: id,
        isActive: false
      }
      dataInfo.active = false
      dispatch(MallActions.setMallInfo(dataInfo))
      dispatch(toggleActivateMall(data))
    }
    setToggle((toggle) => !toggle)
    setConfirmModal(false)
  }

  const optionsModal = {
    title: toggle ? 'inactivar' : 'activar',
    subtitle: toggle ? ' este Mall' : ' este Mall',
    motifText: ''
  }

  return (
    <div className="container-name-mall">
      <MDBRow className="h-100 text-center" between>
        <h2>
          Mall <span className="newMall_mallName">{mall.info.name}</span>{' '}
        </h2>
        <InputSwitch
          id="activeMall"
          name="activeMallSwitch"
          checked={toggle}
          onChange={handleActivateMall}
          optionLabels={['Activo', 'Inactivo']}
          disabled={false}
        />
      </MDBRow>
      {settings.alert?.status && <AlertModal />}
      {confirmModal && (
        <CommonModal
          setOpenModal={setConfirmModal}
          handleConfirmModal={handleConfirmModal}
          options={optionsModal}
        />
      )}
    </div>
  )
}
