import { UPDATE_MENU_SELECTED } from './menu-actions'

export interface IMenuState {
  selected: string
}

type Actions = { type: typeof UPDATE_MENU_SELECTED; payload: string }

export function MenuReducer(state: IMenuState, action: Actions): IMenuState {
  state = state ? state : { selected: '/' }

  switch (action.type) {
    case UPDATE_MENU_SELECTED:
      return { selected: action.payload }
    default:
      return state
  }
}
