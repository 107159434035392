import React, { useCallback, useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { MallThunks } from '@features/mall'
import Loading from '@commons/loading/Loading'
import Logo from '@assets/images/arauco-plus.svg'
import { VscMenu } from 'react-icons/vsc'
import { AiOutlineSearch } from 'react-icons/ai'
import { IInitialState } from '@store/index'
import Section from '@commons/home/section'
import CarouselSection from '@commons/home/carouselSection'
import AlertSection from '@commons/home/alertSection'
import { ScheduleThunks } from '..'

interface IMyProps {
  paramsId?: number
  defaultTime: boolean
  index: any
  langSelected: string
}
const ScheduleHomePreview: React.FC<IMyProps> = (props) => {
  const { defaultTime, index, langSelected } = props
  const { info, home } = useSelector((state: IInitialState) => state.mall)
  const [fetching, setFetching] = useState(false)
  const dispatch = useDispatch()

  let sections: any = []
  if (home && home.length > 0 && home[0]) {
    sections = home[index]?.config?.sections
  }

  const fetchGetMallHome = useCallback(async () => {
    setFetching(true)
    if (info) {
      let mallId = info.mallId
      if (props.paramsId) {
        mallId = info.mallId ? info.mallId : props.paramsId
      }
      if (defaultTime) {
        await dispatch(
          MallThunks.getDefaultHomeMall(mallId, defaultTime, langSelected)
        )
      } else {
        await dispatch(
          MallThunks.getDefaultHomeMall(mallId, false, langSelected)
        )
      }
    }
    setFetching(false)
  }, [dispatch, info, langSelected])

  useEffect(() => {
    fetchGetMallHome()
  }, [fetchGetMallHome, langSelected])

  useEffect(() => {
    dispatch(ScheduleThunks.getMallScheduleStatus(info.mallId, langSelected))
  }, [langSelected])

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <MDBRow className="home-preview_Background_schedule">
          <MDBCol size="12" className="home-preview">
            <div className="home-header">
              <div className="logo-mobile">
                <div className="mall-selected">
                  {langSelected === 'es' && 'Estás en:'}
                  {langSelected === 'en' && 'You are at:'}
                  {langSelected === 'pt' && 'Você  está no:'}
                  &nbsp;
                  <span className="mall-name">{info ? info.name : ''}</span>
                </div>
                <img className="logo-img" src={Logo} alt="parque arauco" />
              </div>
              <div className="icon-login">
                <div className="demoIconPlus">
                  <i className="iconPlus-user-01" />
                </div>
              </div>
              <div className="menu">
                <VscMenu className="menu-icon" />
              </div>
              <h3 className="title-header">
                {langSelected === 'es' && '¿Qué estas buscando?'}
                {langSelected === 'en' && 'What are you looking for? '}
                {langSelected === 'pt' && 'O que você está buscando?'}
              </h3>
              <div className="container-input">
                <div className="contenedor-buscador">
                  <MDBRow>
                    <MDBCol size="1" className="column">
                      <AiOutlineSearch className="icon-search" />
                    </MDBCol>
                    <MDBCol size="11">
                      {' '}
                      {langSelected === 'es' && '¿Qué estas buscando?'}
                      {langSelected === 'en' && "women's Clothing "}
                      {langSelected === 'pt' && 'Roupa de Mulher'}
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </div>
            <div className="home-body">
              {sections?.map((section: any) => {
                switch (section.type) {
                  case 'directory':
                    return (
                      <Section
                        key={`section${section.id}`}
                        sectionData={section}
                        mall={info}
                      />
                    )
                  case 'alert':
                    return <AlertSection section={section} />
                  case 'carousel':
                    return <CarouselSection section={section} />
                  default:
                    return null
                }
              })}
            </div>
          </MDBCol>
        </MDBRow>
      )}
    </>
  )
}

export default ScheduleHomePreview
