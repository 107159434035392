const country = process.env.REACT_APP_COUNTRY || 'Chile'

let channel = ['ARAUCO_PLUS']
if (country === 'Colombia') {
  channel = ['PARQUE_PLUS']
}
if (country === 'Peru') {
  channel = ['PERU_PLUS']
}

export let notifi = {
  type: 'Notificación general - on demand',
  title: '',
  description: '',
  logo: '',
  icon: '',
  base64String: '',
  logoType: '',
  startDate: '',
  endDate: '',
  conditions: {
    interests: [],
    hasProfileComplete: false,
    hasLicensePlate: false,
    channels: channel
  }
}
