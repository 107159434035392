import React from 'react'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import HomePage from '@features/home/home-page'
import MallsPage from '@features/malls/malls-page'
import MallPage from '@features/mall/mall-page'
import SectionsPage from '@features/sections/sections-page'
import SchedulePage from '@features/schedules/schedule-page'
import LoginPage from '@features/login/login-page'
import ProtectedRoutes from './auth/protected-route'
import NotificationsPage from '@features/notifications/notifications-page'
import previewNotification from '@features/notifications/elements/preview-notification'

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact component={LoginPage} />
        <ProtectedRoutes>
          <Route path="/" exact component={HomePage} />
          <Route path="/malls" exact component={MallsPage} />
          <Route path={`/editMall/:id`} exact component={MallPage} />
          <Route
            path={`/editMall/:id/sections`}
            exact
            component={SectionsPage}
          />
          <Route
            path={`/editMall/:id/sections/:scheduleId`}
            exact
            component={SectionsPage}
          />
          <Route
            path={`/editMall/:id/sections/:scheduleId/:lang`}
            exact
            component={SectionsPage}
          />
          <Route
            path={`/editMall/:id/schedules`}
            exact
            component={SchedulePage}
          />
          <Route
            path={`/editMall/:id/notifications`}
            exact
            component={NotificationsPage}
          />
          <Route
            path={`/editMall/:id/notifications/preview`}
            exact
            component={previewNotification}
          />
        </ProtectedRoutes>
      </Switch>
    </Router>
  )
}

export default App
