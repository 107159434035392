import React, { useEffect, useRef, useState } from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import { GrLinkUp } from 'react-icons/gr'
import { IConfigFileAttachments } from '@features/sections/sections-interface'
import { FiMinus } from 'react-icons/fi'
import { INotification } from '@features/notifications/notifications-interface'
import { useSelector } from 'react-redux'
import { IInitialState } from '@store/index'

interface IMyProps {
  setData: (value: any) => void
  data: INotification
}

const UploadImage: React.FC<IMyProps> = ({ setData, data }) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const _contentRef = useRef<HTMLDivElement>(null)
  const { notifications } = useSelector((state: IInitialState) => state)
  const [isDragging, setIsDragging] = useState(false)

  const [warningMsg, setWarningMsg] = useState('')
  const [logo64, setLogo64]: any = useState()
  const [logo, setLogo]: any = useState()

  const configFiles: IConfigFileAttachments = JSON.parse(
    process.env.REACT_APP_CONFIG_UPLOAD_IMAGES
      ? process.env.REACT_APP_CONFIG_UPLOAD_IMAGES
      : ''
  )

  let maxSizeFiles = 0
  let extensions = ''
  let formats = ''
  let maxSizeFilesMB = 0
  if (configFiles) {
    maxSizeFiles = configFiles.maxFileSize
    extensions = configFiles.extensions.toString()
    formats = extensions.replace(/\./gi, ' ').toUpperCase()
    maxSizeFilesMB = maxSizeFiles / 1048576
  }

  useEffect(() => {
    if (data.icon) {
      handleDelLogo()
    }
  }, [data.icon])

  useEffect(() => {
    const { base64String, logo } = notifications.notification
    if (base64String) {
      setLogo(logo)
      setLogo64(base64String)
    }
  }, [notifications.notification.base64String])

  const validateFile = (fileUp: File): boolean => {
    let valid = true
    if (configFiles) {
      if (fileUp) {
        const filename = fileUp.name
        if (!configFiles.mimetypes.includes(fileUp.type)) {
          setWarningMsg(
            `El documento '${filename}' no tiene un formato permitido: ${formats}`
          )
          valid = false
        }
        if (fileUp.size > maxSizeFiles) {
          setWarningMsg(
            `El documento '${filename}' excede el tamaño máximo de ${maxSizeFilesMB}MB`
          )
          valid = false
        }
      }
    }
    return valid
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true)
      setWarningMsg('')
    }
  }
  const handleDragOut = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    setIsDragging(false)
  }
  const dragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
  }

  const fileDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    setIsDragging(false)
    const files = e.dataTransfer.files
    if (files && files.length > 0 && validateFile(files[0])) {
      e.dataTransfer.clearData()
      updateLogo(files[0])
    }
  }

  const handleUpdateLogo = (e: any) => {
    const file = e.target.files[0]
    if (file && validateFile(file)) {
      updateLogo(file)
    }
  }

  const updateLogo = (file: File) => {
    // setShowInput(false)
    const logo: string = URL.createObjectURL(file)

    setLogo(logo)
    const reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
      const base64String: any = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '')
      setLogo64(base64String)
      setData({ ...data, logo, base64String, logoType: file.type })
    }
  }

  const handleDelLogo = () => {
    setLogo('')
    setData({ ...data, logo: '', base64String: '', logoType: '' })
  }

  return (
    <MDBRow className={`updateImage_box updateImage_box_notification`}>
      <MDBCol
        className={`updateImage_insidebox updateImage_insidebox_notification ${
          logo ? 'updateImage_insidebox__noborder' : ''
        } ${isDragging ? 'updateImage_insidebox__dragging' : ''}`}
      >
        {logo && (
          <div
            className="updateImage_delete_notification"
            onClick={() => handleDelLogo()}
          >
            <FiMinus className="icon-delete" />
          </div>
        )}
        {logo ? (
          <img
            className="updateImage_image updateImage_image_notification"
            src={logo}
            alt="Logo"
          />
        ) : (
          <div
            ref={_contentRef}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragOut}
            onDragOver={dragOver}
            onDrop={fileDrop}
          >
            <div className="icon-upload">
              <GrLinkUp className="upload" />
            </div>
            <div
              className={`text-upload ${
                isDragging ? 'text-upload__dragging' : ''
              }`}
            >
              {isDragging
                ? 'Suelta el logo dentro del recuadro'
                : 'Arrastra el logo aquí para subirlo'}
            </div>
            <div className="text-upload-bottom">
              <div className="horizontal-line" />
              <div className="text">o</div>
              <div className="horizontal-line" />
            </div>
          </div>
        )}
        {!logo && (
          <>
            <label
              htmlFor="inputButtonLogo"
              className={
                data.icon ? 'updateImage_greybutton' : 'updateImage_bluebutton'
              }
            >
              Selecciona un archivo
            </label>

            <input
              type="file"
              ref={inputFileRef}
              accept={extensions}
              className="updateImage_input"
              id="inputButtonLogo"
              onChange={handleUpdateLogo}
              disabled={data.icon ? true : false}
            />
          </>
        )}
      </MDBCol>
    </MDBRow>
  )
}

export default UploadImage
