import { IAction } from '@commons/common-interfaces'
import { IBaseTemplateHome } from '@features/sections/sections-interface'

import {
  IFaq,
  IMallAddress,
  IMallInfo,
  ISocialNetwork
} from './mall-interfaces'
const SET_MALL = 'SET_MALL'
const SET_MALL_ACTIVE = 'SET_MALL_ACTIVE'
const SET_HOME_MALL = 'SET_HOME_MALL'
const UPDATE_MALL_ADDRESS = 'UPDATE_MALL_ADDRESS'
const UPDATE_MALL_IMAGE = 'UPDATE_MALL_IMAGE'
const UPDATE_MALL_LOGO = 'UPDATE_MALL_LOGO'
const SET_SOCIAL_NETWORKS = 'SET_SOCIAL_NETWORKS'
const GET_MALL_FAQS = 'GET_MALL_FAQS'

const setMallInfo = (MallInfo: IMallInfo): IAction => ({
  type: SET_MALL,
  payload: MallInfo
})

const setMallActive = (MallInfo: IMallInfo): IAction => ({
  type: SET_MALL_ACTIVE,
  payload: MallInfo
})

const setHomeMall = (homeMall: IBaseTemplateHome): IAction => ({
  type: SET_HOME_MALL,
  payload: homeMall
})

const setSocialNetworks = (socialNetworks: Array<ISocialNetwork>): IAction => ({
  type: SET_SOCIAL_NETWORKS,
  payload: socialNetworks
})

const updateMallAddress = (AddressInfo: IMallAddress): IAction => ({
  type: UPDATE_MALL_ADDRESS,
  payload: AddressInfo
})

const updateImage = (image: string): IAction => ({
  type: UPDATE_MALL_IMAGE,
  payload: image
})

const updateLogo = (logo: string): IAction => ({
  type: UPDATE_MALL_LOGO,
  payload: logo
})

const getMallFaqs = (data: Array<IFaq>): IAction => ({
  type: GET_MALL_FAQS,
  payload: data
})

export {
  SET_MALL,
  SET_MALL_ACTIVE,
  SET_HOME_MALL,
  UPDATE_MALL_ADDRESS,
  UPDATE_MALL_LOGO,
  UPDATE_MALL_IMAGE,
  SET_SOCIAL_NETWORKS,
  GET_MALL_FAQS,
  setMallInfo,
  setMallActive,
  setHomeMall,
  updateMallAddress,
  updateLogo,
  updateImage,
  setSocialNetworks,
  getMallFaqs
}
