import React, { CSSProperties } from 'react'
import { ISection } from '@features/sections/sections-interface'
import { useDrag } from 'react-dnd'
import { ItemTypes } from './itemTypes'
import { useDispatch, useSelector } from 'react-redux'
import { IInitialState } from '@store/index'
import { SectionsActions } from '@features/sections'
import LogoCB from '@assets/images/bloque-carrusel-default.png'
import { v4 as uuidv4 } from 'uuid'

const style: CSSProperties = {
  cursor: 'move'
}

export const CarouselDrag: React.FC = () => {
  const { homeEdit } = useSelector((state: IInitialState) => state.sections)
  const acceptedType = ItemTypes.SPECIAL_BLOCK
  const styleBlock = 'bloqs_sectionItem_carousel_container'

  const dispatch = useDispatch()

  const specialSection: ISection = {
    id: uuidv4(),
    type: 'carousel',
    title: '',
    order: 0,
    backgroundWhite: false,
    blocks: [
      {
        id: uuidv4(),
        backgroundUrl: LogoCB,
        link: 'https://www.araucoplus.cl/',
        internalLink: false,
        order: 0
      }
    ]
  }

  const addCarouselToHomeEdit = (
    newSection: ISection,
    nextSection: ISection
  ) => {
    delete nextSection.dropEffect
    if (homeEdit) {
      if (nextSection.id !== undefined && String(nextSection.id).length > 0) {
        const index = homeEdit.config.sections.findIndex(
          (sectionAux: ISection) =>
            String(sectionAux.id) === String(nextSection.id)
        )
        if (index >= 0) {
          newSection.order = index
          homeEdit.config.sections.splice(
            index,
            0,
            JSON.parse(JSON.stringify(newSection))
          )
        } else {
          newSection.order = homeEdit.config.sections.length - 1
          homeEdit.config.sections.push(newSection)
        }
      } else if (
        nextSection.title !== undefined &&
        String(nextSection.title).length > 0
      ) {
        const index = homeEdit.config.sections.findIndex(
          (sectionAux: ISection) =>
            String(sectionAux.title) === String(nextSection.title)
        )
        newSection.order = index
        homeEdit.config.sections.splice(
          index,
          0,
          JSON.parse(JSON.stringify(newSection))
        )
      }
      dispatch(
        SectionsActions.setHomeEdit(JSON.parse(JSON.stringify(homeEdit)))
      )
    }
  }

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: acceptedType,
      item: specialSection,
      end: (item, monitor) => {
        const sectionResult = monitor.getDropResult<ISection>()
        if (item && sectionResult) {
          addCarouselToHomeEdit(item, JSON.parse(JSON.stringify(sectionResult)))
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId()
      })
    }),
    [specialSection, addCarouselToHomeEdit]
  )

  const opacity = isDragging ? 0.4 : 1
  return (
    <div
      ref={drag}
      role="menuitem"
      className={`col ${styleBlock}`}
      style={{ ...style, opacity }}
    >
      <img src={LogoCB} alt="imagen" className="bloq_carrousel_preview" />
      {/* <span>BLOQUE DE CARRUSEL</span> */}
    </div>
  )
}
