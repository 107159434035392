import { IPromise } from '@commons/common-interfaces'
import RequestService from '../../core/request-service'

export class HomeService extends RequestService {
  static getMalls(): Promise<IPromise> {
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Authorization: `Bearer ${getKey}`
      }
    }
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/mall`

    return super.get(endpoint, headers)
  }

  static getBaseTemplateHome(): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/schedule/template`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Authorization: `Bearer ${getKey}`
      }
    }

    return super.get(endpoint, headers)
  }

  static getMallBloqs(): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/section/template`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`
      }
    }

    return super.get(endpoint, headers)
  }

  static getNewMalls(): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/mall/missingOnes`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`
      }
    }

    return super.get(endpoint, headers)
  }

  static createNewMalls(id: number): Promise<IPromise> {
    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/home/mall`
    let headers: Headers | string[][] | Record<string, string> | undefined = {}
    let getKey = localStorage.getItem('backoffice_user_access') || null
    if (getKey) {
      getKey = JSON.parse(getKey)
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getKey}`
      }
    }
    const params = { mallId: id }

    return super.post(endpoint, params, headers)
  }
}
