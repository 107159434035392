import { IAction } from '@commons/common-interfaces'
import { SET_MALL_SCHEDULE } from './schedule-actions'
import { ISchedules } from './schedule-interface'

const initialState: ISchedules = {
  times: []
}

export const ScheduleReducer = (state: any, action: IAction): any => {
  state = state ? state : initialState

  switch (action.type) {
    case SET_MALL_SCHEDULE:
      return {
        ...state,
        times: action.payload
      }

    default:
      return state
  }
}
