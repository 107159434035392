import React, { useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import { useDispatch } from 'react-redux'
import { MallActions, MallThunks } from '@features/mall'
import Loading from '@commons/loading/Loading'
import { IoMdClose } from 'react-icons/io'
import { setAlertModal } from '@commons/common-actions'

interface IMyProps {
  setSelectView: any
  mall: any
  setOptions: any
  options: any
  selectView: any
}

const AddressPage: React.FC<IMyProps> = (props) => {
  const dispatch = useDispatch()
  const [directionValue, setDirectionValue]: any = useState({ direction: '' })
  const [coordinatesValue, setCoordinateValue]: any = useState({
    latitude: '',
    longitude: ''
  })
  const [fetching, setFetching] = useState(false)
  const { direction, coordinates, active } = props.mall.info

  const handleChangeDirection = (e: any) => {
    setDirectionValue({ ...directionValue, [e.target.name]: e.target.value })
  }
  const handleChangeCoordinates = (e: any) => {
    setCoordinateValue({ ...coordinatesValue, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const value = {
      direction: directionValue.direction,
      longitude: coordinatesValue.longitude,
      latitude: coordinatesValue.latitude
    }

    if (active) {
      if (
        value.direction === '' ||
        value.longitude === '' ||
        value.latitude === ''
      ) {
        dispatch(
          setAlertModal({
            status: true,
            data: {
              msg: '',
              title:
                'Mientras el Mall este activo, debes mostrar la dirección del Mall'
            }
          })
        )
        return
      }
    }
    setFetching(true)
    dispatch(MallActions.updateMallAddress(value))
    dispatch(MallThunks.updateMallInfo())
    const resp = await setActive()
    props.setOptions([...resp])
    setFetching(false)
    props.setSelectView('DEFAULT_VIEW')
  }

  const setActive = () => {
    const position = props.options.findIndex(
      (value: any) => value.id === props.selectView
    )
    let newArray = props.options
    for (let i = 0; i < props.options.length; i++) {
      const element = props.options[i]
      if (element.id === props.selectView) {
        newArray[position].finished = true
      }
    }

    return newArray
  }

  useEffect(() => {
    if (direction) {
      setDirectionValue({ direction: direction })
    }
    if (!direction) {
      setDirectionValue({ direction: 'Ingresa dirección del mall' })
    }
    if (coordinates) {
      setCoordinateValue({
        latitude: coordinates.latitude,
        longitude: coordinates.longitude
      })
    }
    if (!coordinates) {
      setCoordinateValue({
        latitude: 'Latitud',
        longitude: 'Longitud'
      })
    }
  }, [direction, coordinates])

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <MDBRow className="h-100 address-preview_Background">
          <MDBCol size="12" className="no-lateral-padding">
            <MDBRow className="justify-content-between tittle_Preview">
              <MDBCol size="10" className="tittle_Preview_name">
                <p>Dirección</p>
              </MDBCol>
              <MDBCol size="2">
                <div
                  className="btn-close"
                  onClick={() => props.setSelectView('DEFAULT_VIEW')}
                >
                  <IoMdClose size={32} className="icon-close" />
                </div>
              </MDBCol>
            </MDBRow>
            <form onSubmit={handleSubmit}>
              <MDBRow className="input_box">
                <MDBCol size="12">
                  {direction ? (
                    <input
                      onChange={handleChangeDirection}
                      type="text"
                      value={directionValue.direction}
                      name="direction"
                    />
                  ) : (
                    <input
                      onChange={handleChangeDirection}
                      type="text"
                      placeholder={directionValue.direction}
                      name="direction"
                    />
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="input_box">
                <MDBCol size="12" className="input_box_title p-3">
                  <h6>Ingresa las coordenadas</h6>
                </MDBCol>
                <MDBCol size="12">
                  {coordinates ? (
                    <input
                      className="address_input_coordinates"
                      onChange={handleChangeCoordinates}
                      type="text"
                      value={coordinatesValue.latitude}
                      name="latitude"
                    />
                  ) : (
                    <input
                      className="address_input_coordinates"
                      onChange={handleChangeCoordinates}
                      type="text"
                      placeholder={coordinatesValue.latitude}
                      name="latitude"
                    />
                  )}
                </MDBCol>
                <MDBCol size="12">
                  {coordinates ? (
                    <input
                      className="address_input_coordinates"
                      onChange={handleChangeCoordinates}
                      type="text"
                      value={coordinatesValue.longitude}
                      name="longitude"
                    />
                  ) : (
                    <input
                      className="address_input_coordinates"
                      onChange={handleChangeCoordinates}
                      type="text"
                      placeholder={coordinatesValue.longitude}
                      name="longitude"
                    />
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="justify-content-between mt-5">
                <MDBCol size="6">
                  <button
                    className="address_closeButton"
                    onClick={() => props.setSelectView('DEFAULT_VIEW')}
                  >
                    Cancelar
                  </button>
                </MDBCol>
                <MDBCol size="6">
                  <button className="address_confirmButton" type="submit">
                    Guardar
                  </button>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
        </MDBRow>
      )}
    </>
  )
}

export default AddressPage
