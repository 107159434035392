import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { IInitialState } from '@store/index'
import { MallService } from '@features/mall'
import { NotificationsService } from './notifications-service'
import { IConditions } from './notifications-interface'

type AppThunk = ThunkAction<void, IInitialState, unknown, Action<string>>

export const RefreshToken = (value: any): AppThunk => async (
  dispatch,
  getState
) => {
  try {
  } catch (e) {
    console.log(e)
  }
}
export const saveIcon = async (base64: string, type: string, id: any) => {
  const value = {
    mallId: id,
    contentType: type,
    folderName: 'notification',
    fileBase64: base64
  }
  try {
    const result = await MallService.MallService.uploadImage(value)
    return result
  } catch (e) {
    console.log(e)
  }
}

export const sendNotification = async (
  title: string,
  description: string,
  icon: any,
  type: string,
  startDate: string,
  endDate: string,
  conditions: IConditions,
  mallId: string | number
) => {
  const value = {
    title,
    description,
    icon,
    type,
    startDate,
    endDate,
    conditions,
    mallId
  }
  try {
    const result = await NotificationsService.SendNotification(value)
    return result
  } catch (e) {
    console.log(e)
  }
}

export const getListNotification = async (id: string) => {
  try {
    const result = await NotificationsService.getListNotification(id)
    return result
  } catch (e) {
    console.log(e)
  }
}
