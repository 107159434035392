import React from "react";

import "./customSwitch.scss";

interface IMyProps {
  id: string,
  name: string,
  checked: boolean,
  onChange: (e: any) => void,
  optionLabels: [string, string],
  disabled?: boolean,
  small?: boolean
}

const CustomSwitch: React.FC<IMyProps> = ({
  id,
  name,
  checked,
  onChange,
  optionLabels,
  small,
  disabled,
}) => {
  return (
    <div className={"toggle-switch" + (small ? " small-switch" : "")}>
      <input
        type='checkbox'
        name={name}
        className='toggle-switch-checkbox'
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id ? (
        <label className='toggle-switch-label' htmlFor={id}>
          <span
            className={
              disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : "toggle-switch-inner"
            }
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
            tabIndex={-1}
          />
          <span
            className={
              disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
            tabIndex={-1}
          />
        </label>
      ) : null}
    </div>
  );
};

export default CustomSwitch;
