import React, { useCallback, useEffect, useState } from 'react'
import Layout from '@commons/layout/layout'
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MallActions, MallThunks } from './index'
import { IInitialState } from '@store/index'
import { MdCheckCircle } from 'react-icons/md'
import UpdateImage from './updateImage/updateImage'
import { toggleActivateMall } from './mall-thunks'
import SocialNetworkPage from './socialNetwork/social-network-page'
import FaqPage from './faq/faq-page'
import HomePreview from './homePreview/home-preview-page'
import NewHomePreview from './homePreview/new-home-preview-page'
import AddressPage from './address/address-page'
import Loading from '@commons/loading/Loading'
import InputSwitch from '@commons/inputSwitch/inputSwitch'
import AlertModal from '@commons/alertModal/alertModal'
import Logo from '@assets/images/arauco-plus.svg'
import { Menu } from '@commons/menu'
import { setAlertModal } from '@commons/common-actions'
import CommonModal from '@commons/commonModal/commonModal'

const MallPage: React.FC = ({ match }: any) => {
  const { mall, settings } = useSelector((state: IInitialState) => state)
  const mallId = match.params.id
  const [toggle, setToggle] = useState(false)
  const [selectView, setSelectView] = useState('DEFAULT_VIEW')
  const [fetching, setFetching] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const fetchGetMall = useCallback(async () => {
    await dispatch(MallThunks.getMall(mallId, setFetching))
  }, [dispatch, mallId])

  const fetchGetSocialNetwork = useCallback(() => {
    dispatch(MallThunks.getSocialNetworks(mallId, setFetching))
  }, [dispatch, mallId])
  const fetchGetMallFaqs = useCallback(async () => {
    setFetching(true)
    await dispatch(MallThunks.getMallFaqs(parseInt(mallId)))
    setFetching(false)
  }, [dispatch, mallId])

  useEffect(() => {
    fetchGetMall()
  }, [fetchGetMall, mall.info.mallId])

  useEffect(() => {
    fetchGetSocialNetwork()
  }, [fetchGetSocialNetwork])

  useEffect(() => {
    fetchGetMallFaqs()
  }, [fetchGetMallFaqs])

  useEffect(() => {
    setToggle(mall.info.active)
  }, [mall.info.active])

  const handleActivateMall = () => {
    const isTrue = settings.options.status.find(
      (item) => item.finished === false
    )
    if (isTrue) {
      dispatch(
        setAlertModal({
          status: true,
          data: {
            msg: '',
            title:
              'No puedes activar el Mall porque no está completa toda la información '
          }
        })
      )
    } else {
      setConfirmModal(true)
    }
  }

  const handleConfirmModal = () => {
    const statusAlert = settings.alert?.status
    const dataInfo = mall.info
    if (!toggle) {
      const data = {
        mallId: mallId,
        isActive: true
      }
      dataInfo.active = false
      if (statusAlert === true) {
        dataInfo.active = true
      }
      dispatch(MallActions.setMallInfo(dataInfo))
      dispatch(toggleActivateMall(data))
      setToggle(true)
    } else {
      const data = {
        mallId: mallId,
        isActive: false
      }
      dataInfo.active = false
      dispatch(MallActions.setMallInfo(dataInfo))
      dispatch(toggleActivateMall(data))
      setToggle(false)
    }
    setConfirmModal(false)
  }

  const selectViewBtn = (id: string) => {
    if (id === 'SECTIONS_HOME') {
      history.push(`/editMall/${mallId}/sections/isDefault`)
    } else {
      setSelectView(id)
    }
  }
  const [options, setOptions] = useState([
    { title: 'Imágenes', id: 'IMAGE_UPDATE', finished: false },
    { title: 'Dirección', id: 'ADDRESS', finished: false },
    // {title: "Mapas",id:"MAPS"},
    { title: 'Redes Sociales', id: 'SOCIAL_MEDIA', finished: false },
    { title: 'Preguntas Frecuentes', id: 'FAQ', finished: false },
    { title: 'Home Arauco+', id: 'SECTIONS_HOME', finished: false }
  ])

  useEffect(() => {}, [options])

  const optionsModal = {
    title: toggle ? 'inactivar' : 'activar',
    subtitle: toggle ? ' este Mall' : ' este Mall',
    motifText: ''
  }

  return (
    <Layout>
      {fetching ? (
        <Loading />
      ) : (
        <MDBRow className="mt-5 mx-0">
          <MDBCol size="3" className="mx-0 px-0">
            <div className="container-logo">
              <img src={Logo} className="mall-logo" alt="logo" />
            </div>
            <Menu />
          </MDBCol>
          <MDBCol size="9">
            <div className="container-name-mall">
              <MDBRow className="row-name-mall" between>
                <h2>
                  Mall{' '}
                  <span className="newMall_mallName">
                    {mall.info ? mall.info.name : ''}
                  </span>{' '}
                </h2>
                <InputSwitch
                  id="activeMall"
                  name="activeMallSwitch"
                  checked={toggle}
                  onChange={handleActivateMall}
                  optionLabels={['Activo', 'Inactivo']}
                  disabled={false}
                />
              </MDBRow>
            </div>

            <div className="newMall_background">
              <h2 className="newMall_GeneralInfo">Información General</h2>

              <MDBRow className="h-100 no-lateral-padding mx-0">
                <MDBCol className="no-lateral-padding" xs="12" md="6">
                  <MDBRow className="no-lateral-padding mx-0">
                    <MDBCol
                      size="12"
                      className="newMall_InfoRow no-lateral-padding"
                    >
                      <div>
                        <span className="newMall_Title">Código:</span>
                        <span className="newMall_Text">
                          {mall.info ? mall.info.code : ''}
                        </span>
                      </div>
                    </MDBCol>
                    <MDBCol
                      size="12"
                      className="newMall_InfoRow no-lateral-padding"
                    >
                      <div>
                        <span className="newMall_Title">Nombre:</span>
                        <span className="newMall_Text">
                          {mall.info ? mall.info.name : ''}
                        </span>
                      </div>
                    </MDBCol>
                    {settings.options.status.map((value, i) => (
                      <MDBCol
                        key={value.id}
                        size="12"
                        className="newMall_InfoRow newMall_Options no-lateral-padding"
                      >
                        <div
                          className=""
                          onClick={() => selectViewBtn(value.id)}
                        >
                          <span className="newMall_finished">
                            {value.finished ? (
                              <MdCheckCircle className="newMall_checked" />
                            ) : (
                              <MdCheckCircle className="newMall_unchecked" />
                            )}
                          </span>
                          <span
                            className={
                              selectView === settings.options.status[i].id
                                ? 'newMall_activeRow active'
                                : 'newMall_activeRow'
                            }
                          >
                            <span className="newMall_Title newMall_sections">
                              {' '}
                              {value.title}:
                            </span>
                            <span className="newMall_Text">
                              {' '}
                              <MDBIcon icon="caret-right" />{' '}
                            </span>
                          </span>
                        </div>
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBCol>
                <MDBCol
                  className="newMall_Preview no-lateral-padding"
                  xs="12"
                  md="6"
                >
                  {selectView === 'DEFAULT_VIEW' && (
                    // <HomePreview defaultTime={true} />
                    <NewHomePreview defaultTime={true} />
                  )}
                  {selectView === 'ADDRESS' && (
                    <AddressPage
                      setSelectView={setSelectView}
                      mall={mall}
                      options={options}
                      setOptions={setOptions}
                      selectView={selectView}
                    />
                  )}
                  {selectView === 'IMAGE_UPDATE' && (
                    <UpdateImage
                      setSelectView={setSelectView}
                      options={options}
                      setOptions={setOptions}
                      selectView={selectView}
                    />
                  )}
                  {/* {selectView === 'MAPS' && <h2>Text 2</h2>} */}
                  {selectView === 'SOCIAL_MEDIA' && (
                    <SocialNetworkPage
                      setSelectView={setSelectView}
                      match={match}
                      options={options}
                      setOptions={setOptions}
                      selectView={selectView}
                    />
                  )}
                  {selectView === 'FAQ' && (
                    <FaqPage
                      setSelectView={setSelectView}
                      mall={mall}
                      options={options}
                      setOptions={setOptions}
                      selectView={selectView}
                    />
                  )}
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
      )}
      {confirmModal && (
        <CommonModal
          setOpenModal={setConfirmModal}
          handleConfirmModal={handleConfirmModal}
          options={optionsModal}
        />
      )}
      {settings.alert?.status && <AlertModal />}
    </Layout>
  )
}

export default MallPage
