import { IAction } from '@commons/common-interfaces'

const UPDATE_MENU_SELECTED = 'UPDATE_MENU_SELECTED'

function updateMenuSelected(sel: string): IAction {
  return {
    type: UPDATE_MENU_SELECTED,
    payload: sel
  }
}

export { UPDATE_MENU_SELECTED, updateMenuSelected }
